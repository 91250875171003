<div *ngIf="displayModel">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{headerTitle}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel();bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <!--Systems-->
            <div *ngIf="systemDependency != undefined && systemDependency.System != undefined">
                <div>
                    <h5 class="text-center">System(s)</h5>
                    <div>
                        {{systemDependency.projectName}} ({{systemDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="systemDependency.System.length != undefined">
                        <div *ngFor="let dependency of systemDependency.System">
                                <li>                                        
                                    {{dependency.SystemName}}
                                </li>
                        </div>
                    </div>
                    <div *ngIf="systemDependency.System.length == undefined">     
                            <li>                                                   
                                {{systemDependency.System.SystemName}}      
                            </li>
                     </div>
                </div>
            </div>           
            <!--Topics-->
            <div *ngIf="topicDependency != undefined && topicDependency.Topic != undefined">
                <div>
                    <h5 class="text-center">Topic(s)</h5>
                    <div>
                        {{topicDependency.projectName}} ({{topicDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="topicDependency.Topic.length != undefined">
                        <div *ngFor="let dependency of topicDependency.Topic">
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount>0 && dependency.topicPath != '' ">
                                {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}]
                            </li>
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount==0 && dependency.topicPath != '' ">
                                {{dependency.topicName}} [{{dependency.topicPath}}]
                            </li>
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount>0 && dependency.topicPath == '' ">
                                {{dependency.topicName}} ({{dependency.referenceCount}})
                            </li>
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount==0 && dependency.topicPath == '' ">
                                {{dependency.topicName}}
                            </li>

                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount>0 && dependency.topicPath != '' ">
                                {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount==0 && dependency.topicPath != '' ">
                                {{dependency.topicName}} [{{dependency.topicPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount>0 && dependency.topicPath == '' ">
                                {{dependency.topicName}} ({{dependency.referenceCount}}) - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount==0 && dependency.topicPath == '' ">
                                {{dependency.topicName}} - {{dependency.checkedOutUser}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="topicDependency.Topic.length == undefined">     
                            <li  *ngIf="topicDependency.Topic.checkedOutUser == '' && topicDependency.Topic.referenceCount>0 && topicDependency.Topic.topicPath != '' ">                    
                                {{topicDependency.Topic.topicName}} ({{topicDependency.Topic.referenceCount}}) [{{topicDependency.Topic.topicPath}}]
                            </li>
                            <li  *ngIf="topicDependency.Topic.checkedOutUser == '' && topicDependency.Topic.referenceCount==0 && topicDependency.Topic.topicPath != '' ">                    
                                {{topicDependency.Topic.topicName}} [{{topicDependency.Topic.topicPath}}]
                            </li>
                            <li  *ngIf="topicDependency.Topic.checkedOutUser == '' && topicDependency.Topic.referenceCount>0 && topicDependency.Topic.topicPath == '' ">                    
                                {{topicDependency.Topic.topicName}} ({{topicDependency.Topic.referenceCount}})
                            </li>
                            <li  *ngIf="topicDependency.Topic.checkedOutUser == '' && topicDependency.Topic.referenceCount==0 && topicDependency.Topic.topicPath == '' ">                    
                                {{topicDependency.Topic.topicName}}
                            </li>

                            <li  *ngIf="topicDependency.Topic.checkedOutUser != '' && topicDependency.Topic.referenceCount>0 && topicDependency.Topic.topicPath != '' ">                    
                                {{topicDependency.Topic.topicName}} ({{topicDependency.Topic.referenceCount}}) [{{topicDependency.Topic.topicPath}}] - {{topicDependency.Topic.checkedOutUser}}
                            </li>
                            <li  *ngIf="topicDependency.Topic.checkedOutUser != '' && topicDependency.Topic.referenceCount==0 && topicDependency.Topic.topicPath != '' ">                    
                                {{topicDependency.Topic.topicName}} [{{topicDependency.Topic.topicPath}}] - {{topicDependency.Topic.checkedOutUser}}
                            </li>
                            <li  *ngIf="topicDependency.Topic.checkedOutUser != '' && topicDependency.Topic.referenceCount>0 && topicDependency.Topic.topicPath == '' ">                    
                                {{topicDependency.Topic.topicName}} ({{topicDependency.Topic.referenceCount}}) - {{topicDependency.Topic.checkedOutUser}}
                            </li>
                            <li  *ngIf="topicDependency.Topic.checkedOutUser != '' && topicDependency.Topic.referenceCount==0 && topicDependency.Topic.topicPath == '' ">                    
                                {{topicDependency.Topic.topicName}} - {{topicDependency.Topic.checkedOutUser}}
                            </li>
                     </div>
                </div>
            </div>

            <div *ngIf="topicDependency != undefined && topicDependency.Topic == undefined">
                <div>
                    <h5 class="text-center">Topic(s)</h5>
                    <div *ngFor="let td of topicDependency">
                        <ul>
                            {{td.projectName}} ({{td.dependencyCount}})
                            <br/>
                            <div *ngIf="td.Topic.length != undefined">
                                <div *ngFor="let topic of td.Topic">
                                     <li *ngIf="topic.checkedOutUser == '' && topic.referenceCount>0 && topic.topicPath != '' ">
                                         {{topic.topicName}} ({{topic.referenceCount}}) [{{topic.topicPath}}]
                                     </li>
                                     <li *ngIf="topic.checkedOutUser == '' && topic.referenceCount==0 && topic.topicPath != '' ">
                                         {{topic.topicName}} [{{topic.topicPath}}]
                                     </li>
                                     <li *ngIf="topic.checkedOutUser == '' && topic.referenceCount>0 && topic.topicPath == '' ">
                                         {{topic.topicName}} ({{topic.referenceCount}})
                                     </li>
                                     <li *ngIf="topic.checkedOutUser == '' && topic.referenceCount==0 && topic.topicPath == '' ">
                                         {{topic.topicName}}
                                     </li>

                                     <li *ngIf="topic.checkedOutUser != '' && topic.referenceCount>0 && topic.topicPath != '' ">
                                        {{topic.topicName}} ({{topic.referenceCount}}) [{{topic.topicPath}}] - {{topic.checkedOutUser}}
                                    </li>
                                     <li *ngIf="topic.checkedOutUser != '' && topic.referenceCount==0 && topic.topicPath != '' ">
                                        {{topic.topicName}} [{{topic.topicPath}}] - {{topic.checkedOutUser}}
                                    </li>
                                     <li *ngIf="topic.checkedOutUser != '' && topic.referenceCount>0 && topic.topicPath == '' ">
                                        {{topic.topicName}} ({{topic.referenceCount}}) - {{topic.checkedOutUser}}
                                    </li>
                                     <li *ngIf="topic.checkedOutUser != '' && topic.referenceCount==0 && topic.topicPath == '' ">
                                        {{topic.topicName}} - {{topic.checkedOutUser}}
                                    </li>
                                </div>
                            </div>
                            <div *ngIf="td.Topic.length == undefined">
                                <li *ngIf="td.Topic.checkedOutUser == '' && td.Topic.referenceCount>0 && td.Topic.topicPath != '' ">
                                    {{td.Topic.topicName}} ({{td.Topic.referenceCount}}) [{{td.Topic.topicPath}}]
                                </li>
                                <li *ngIf="td.Topic.checkedOutUser == '' && td.Topic.referenceCount==0 && td.Topic.topicPath != '' ">
                                    {{td.Topic.topicName}} [{{td.Topic.topicPath}}]
                                </li>
                                <li *ngIf="td.Topic.checkedOutUser == '' && td.Topic.referenceCount>0 && td.Topic.topicPath == '' ">
                                    {{td.Topic.topicName}} ({{td.Topic.referenceCount}})
                                </li>
                                <li *ngIf="td.Topic.checkedOutUser == '' && td.Topic.referenceCount==0 && td.Topic.topicPath == '' ">
                                    {{td.Topic.topicName}}
                                </li>

                                <li *ngIf="td.Topic.checkedOutUser != '' && td.Topic.referenceCount>0 && td.Topic.topicPath != '' ">
                                    {{td.Topic.topicName}} ({{td.Topic.referenceCount}}) [{{td.Topic.topicPath}}] - {{td.Topic.checkedOutUser}}
                                </li>
                                <li *ngIf="td.Topic.checkedOutUser != '' && td.Topic.referenceCount==0 && td.Topic.topicPath != '' ">
                                    {{td.Topic.topicName}} [{{td.Topic.topicPath}}] - {{td.Topic.checkedOutUser}}
                                </li>
                                <li *ngIf="td.Topic.checkedOutUser != '' && td.Topic.referenceCount>0 && td.Topic.topicPath == '' ">
                                    {{td.Topic.topicName}} ({{td.Topic.referenceCount}}) - {{td.Topic.checkedOutUser}}
                                </li>
                                <li *ngIf="td.Topic.checkedOutUser != '' && td.Topic.referenceCount==0 && td.Topic.topicPath == '' ">
                                    {{td.Topic.topicName}} - {{td.Topic.checkedOutUser}}
                                </li>
                              </div> 
                        </ul>
                    </div>
                </div>
            </div>
            
            <!--Procedures-->
            <div *ngIf="procDependency != undefined && procDependency.Procedure != undefined ">
                <div>
                    <h5 class="text-center">Procedure(s)</h5>
                    <div>
                        {{procDependency.projectName}} ({{procDependency.dependencyCount}})
                    </div>
                    <br />
                    <div *ngIf="procDependency.Procedure.length != undefined">
                        <div *ngFor="let dependency of procDependency.Procedure">
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount>0 && dependency.procPath != '' ">
                                {{dependency.procName}} ({{dependency.referenceCount}}) [{{dependency.procPath}}]
                            </li>
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount==0 && dependency.procPath != '' ">
                                {{dependency.procName}} [{{dependency.procPath}}]
                            </li>
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount>0 && dependency.procPath == '' ">
                                {{dependency.procName}} ({{dependency.referenceCount}})
                            </li>
                            <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount==0 && dependency.procPath == '' ">
                                {{dependency.procName}}
                            </li>
                            
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount>0 && dependency.procPath != '' ">
                                {{dependency.procName}} ({{dependency.referenceCount}}) [{{dependency.procPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount==0 && dependency.procPath != '' ">
                                {{dependency.procName}} [{{dependency.procPath}}] - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount>0 && dependency.procPath == '' ">
                                {{dependency.procName}} ({{dependency.referenceCount}}) - {{dependency.checkedOutUser}}
                            </li>
                            <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount==0 && dependency.procPath == '' ">
                                {{dependency.procName}} - {{dependency.checkedOutUser}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="procDependency.Procedure.length == undefined">     
                            <li *ngIf="procDependency.Procedure.checkedOutUser == '' && procDependency.Procedure.referenceCount>0 && procDependency.Procedure.procPath != '' ">                    
                                {{procDependency.Procedure.procName}} ({{procDependency.Procedure.referenceCount}}) [{{procDependency.Procedure.procPath}}]
                            </li>
                            <li *ngIf="procDependency.Procedure.checkedOutUser == '' && procDependency.Procedure.referenceCount==0 && procDependency.Procedure.procPath != '' ">                    
                                {{procDependency.Procedure.procName}} [{{procDependency.Procedure.procPath}}]
                            </li>
                            <li *ngIf="procDependency.Procedure.checkedOutUser == '' && procDependency.Procedure.referenceCount>0 && procDependency.Procedure.procPath == '' ">                    
                                {{procDependency.Procedure.procName}} ({{procDependency.Procedure.referenceCount}})
                            </li>
                            <li *ngIf="procDependency.Procedure.checkedOutUser == '' && procDependency.Procedure.referenceCount==0 && procDependency.Procedure.procPath == '' ">                    
                                {{procDependency.Procedure.procName}}
                            </li>

                            <li *ngIf="procDependency.Procedure.checkedOutUser != '' && procDependency.Procedure.referenceCount>0 && procDependency.Procedure.procPath != '' ">                    
                                {{procDependency.Procedure.procName}} ({{procDependency.Procedure.referenceCount}}) [{{procDependency.Procedure.procPath}}] - {{procDependency.Procedure.checkedOutUser}}
                            </li>
                            <li *ngIf="procDependency.Procedure.checkedOutUser != '' && procDependency.Procedure.referenceCount==0 && procDependency.Procedure.procPath != '' ">                    
                                {{procDependency.Procedure.procName}} [{{procDependency.Procedure.procPath}}] - {{procDependency.Procedure.checkedOutUser}}
                            </li>
                            <li *ngIf="procDependency.Procedure.checkedOutUser != '' && procDependency.Procedure.referenceCount>0 && procDependency.Procedure.procPath == '' ">                    
                                {{procDependency.Procedure.procName}} ({{procDependency.Procedure.referenceCount}}) - {{procDependency.Procedure.checkedOutUser}}
                            </li>
                            <li *ngIf="procDependency.Procedure.checkedOutUser != '' && procDependency.Procedure.referenceCount==0 && procDependency.Procedure.procPath == '' ">                    
                                {{procDependency.Procedure.procName}} - {{procDependency.Procedure.checkedOutUser}}
                            </li>
                     </div>
                </div>
            </div>
            <div *ngIf="procDependency != undefined && procDependency.Procedure == undefined ">
                <div>
                    <h5 class="text-center">Procedure(s)</h5>
                    <div *ngFor="let pd of procDependency">
                        <ul>
                            {{pd.projectName}} ({{pd.dependencyCount}})
                            <br/>
                            <div *ngIf="pd.Procedure.length != undefined">
                                <div *ngFor="let procedure of pd.Procedure">
                                    <li *ngIf="procedure.checkedOutUser == '' && procedure.referenceCount>0 && procedure.procPath != '' ">
                                        {{procedure.procName}} ({{procedure.referenceCount}}) [{{procedure.procPath}}]
                                    </li>
                                    <li *ngIf="procedure.checkedOutUser == '' && procedure.referenceCount==0 && procedure.procPath != '' ">
                                        {{procedure.procName}} [{{procedure.procPath}}]
                                    </li>
                                    <li *ngIf="procedure.checkedOutUser == '' && procedure.referenceCount>0 && procedure.procPath == '' ">
                                        {{procedure.procName}} ({{procedure.referenceCount}})
                                    </li>
                                    <li *ngIf="procedure.checkedOutUser == '' && procedure.referenceCount==0 && procedure.procPath == '' ">
                                        {{procedure.procName}}
                                    </li>

                                    <li *ngIf="procedure.checkedOutUser != '' && procedure.referenceCount>0 && procedure.procPath != '' ">
                                        {{procedure.procName}} ({{procedure.referenceCount}}) [{{procedure.procPath}}] - {{procedure.checkedOutUser}}
                                    </li>
                                    <li *ngIf="procedure.checkedOutUser != '' && procedure.referenceCount==0 && procedure.procPath != '' ">
                                        {{procedure.procName}} [{{procedure.procPath}}] - {{procedure.checkedOutUser}}
                                    </li>
                                    <li *ngIf="procedure.checkedOutUser != '' && procedure.referenceCount>0 && procedure.procPath == '' ">
                                        {{procedure.procName}} ({{procedure.referenceCount}}) - {{procedure.checkedOutUser}}
                                    </li>
                                    <li *ngIf="procedure.checkedOutUser != '' && procedure.referenceCount==0 && procedure.procPath == '' ">
                                        {{procedure.procName}} - {{procedure.checkedOutUser}}
                                    </li>
                                </div>
                            </div>
                            <div *ngIf="pd.Procedure.length == undefined">
                                <li *ngIf="pd.checkedOutUser == '' && pd.referenceCount>0 && pd.procPath !='' ">
                                    {{pd.procName}} ({{pd.referenceCount}}) [{{pd.procPath}}]
                                </li>
                                <li *ngIf="pd.checkedOutUser == '' && pd.referenceCount==0 && pd.procPath !='' ">
                                    {{pd.procName}} [{{pd.procPath}}]
                                </li>
                                <li *ngIf="pd.checkedOutUser == '' && pd.referenceCount>0 && pd.procPath =='' ">
                                    {{pd.procName}} ({{pd.referenceCount}})
                                </li>
                                <li *ngIf="pd.checkedOutUser == '' && pd.referenceCount==0 && pd.procPath =='' ">
                                    {{pd.procName}}
                                </li>

                                <li *ngIf="pd.checkedOutUser != '' && pd.referenceCount>0 && pd.procPath !='' ">
                                    {{pd.procName}} ({{pd.referenceCount}}) [{{pd.procPath}}] - {{pd.checkedOutUser}}
                                </li>
                                <li *ngIf="pd.checkedOutUser != '' && pd.referenceCount==0 && pd.procPath !='' ">
                                    {{pd.procName}} [{{pd.procPath}}] - {{pd.checkedOutUser}}
                                </li>
                                <li *ngIf="pd.checkedOutUser != '' && pd.referenceCount>0 && pd.procPath =='' ">
                                    {{pd.procName}} ({{pd.referenceCount}}) - {{pd.checkedOutUser}}
                                </li>
                                <li *ngIf="pd.checkedOutUser != '' && pd.referenceCount==0 && pd.procPath =='' ">
                                    {{pd.procName}} - {{pd.checkedOutUser}}
                                </li>
                            </div> 
                        </ul>
                    </div>
                </div>
            </div>
            <!--ICE Course-->
            <div *ngIf="iceCourseDependency != undefined && iceCourseDependency.ICECourse != undefined">
                <div>
                    <h5 class="text-center">ICE Course(s)</h5>
                    <div>
                        {{iceCourseDependency.projectName}} ({{iceCourseDependency.ICECourse.length != undefined? iceCourseDependency.dependencyCount: 1}})
                    </div>
                    <br />
                    <div *ngIf="iceCourseDependency.ICECourse.length != undefined">
                        <div *ngFor="let dependency of iceCourseDependency.ICECourse">
                                <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount>0 && dependency.iceCoursePath != '' ">                                        
                                    {{dependency.iceCourseName}} ({{dependency.referenceCount}}) [{{dependency.iceCoursePath}}]
                                </li>
                                <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount==0 && dependency.iceCoursePath != '' ">                                        
                                    {{dependency.iceCourseName}} [{{dependency.iceCoursePath}}]
                                </li>
                                <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount>0 && dependency.iceCoursePath == '' ">                                        
                                    {{dependency.iceCourseName}} ({{dependency.referenceCount}})
                                </li>
                                <li *ngIf="dependency.checkedOutUser == '' && dependency.referenceCount==0 && dependency.iceCoursePath == '' ">                                        
                                    {{dependency.iceCourseName}}
                                </li>

                                <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount>0 && dependency.iceCoursePath != '' ">                                        
                                    {{dependency.iceCourseName}} ({{dependency.referenceCount}}) [{{dependency.iceCoursePath}}] - {{dependency.checkedOutUser}}
                                </li>
                                <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount==0 && dependency.iceCoursePath != '' ">                                        
                                    {{dependency.iceCourseName}} [{{dependency.iceCoursePath}}] - {{dependency.checkedOutUser}}
                                </li>
                                <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount>0 && dependency.iceCoursePath == '' ">                                        
                                    {{dependency.iceCourseName}} ({{dependency.referenceCount}}) - {{dependency.checkedOutUser}}
                                </li>
                                <li *ngIf="dependency.checkedOutUser != '' && dependency.referenceCount==0 && dependency.iceCoursePath == '' ">                                        
                                    {{dependency.iceCourseName}} - {{dependency.checkedOutUser}}
                                </li>
                        </div>
                    </div>
                    <div *ngIf="iceCourseDependency.ICECourse.length == undefined">     
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser == '' && iceCourseDependency.ICECourse.referenceCount>0 && iceCourseDependency.ICECourse.iceCoursePath != '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} ({{iceCourseDependency.ICECourse.referenceCount}}) [{{iceCourseDependency.ICECourse.iceCoursePath}}]                                         
                            </li>
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser == '' && iceCourseDependency.ICECourse.referenceCount==0 && iceCourseDependency.ICECourse.iceCoursePath != '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} [{{iceCourseDependency.ICECourse.iceCoursePath}}]                                         
                            </li>
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser == '' && iceCourseDependency.ICECourse.referenceCount>0 && iceCourseDependency.ICECourse.iceCoursePath == '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} ({{iceCourseDependency.ICECourse.referenceCount}})                                      
                            </li>
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser == '' && iceCourseDependency.ICECourse.referenceCount==0 && iceCourseDependency.ICECourse.iceCoursePath == '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}}                                       
                            </li>

                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser != '' && iceCourseDependency.ICECourse.referenceCount>0 && iceCourseDependency.ICECourse.iceCoursePath != '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} ({{iceCourseDependency.ICECourse.referenceCount}}) [{{iceCourseDependency.ICECourse.iceCoursePath}}] - {{iceCourseDependency.ICECourse.checkedOutUser}}                                             
                            </li>
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser != '' && iceCourseDependency.ICECourse.referenceCount==0 && iceCourseDependency.ICECourse.iceCoursePath != '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} [{{iceCourseDependency.ICECourse.iceCoursePath}}] - {{iceCourseDependency.ICECourse.checkedOutUser}}                                             
                            </li>
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser != '' && iceCourseDependency.ICECourse.referenceCount>0 && iceCourseDependency.ICECourse.iceCoursePath == '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} ({{iceCourseDependency.ICECourse.referenceCount}}) - {{iceCourseDependency.ICECourse.checkedOutUser}}                                             
                            </li>
                            <li *ngIf="iceCourseDependency.ICECourse.checkedOutUser != '' && iceCourseDependency.ICECourse.referenceCount==0 && iceCourseDependency.ICECourse.iceCoursePath == '' ">                                                   
                                {{iceCourseDependency.ICECourse.iceCourseName}} - {{iceCourseDependency.ICECourse.checkedOutUser}}                                             
                            </li>
                     </div>
                </div>
            </div>

        <!--ICE Topic-->
        <div *ngIf="iceTopicDependency != undefined && iceTopicDependency.ICETopic != undefined">
            <div>
                <h5 class="text-center">ICE Topic Menu(s)</h5>
                <div>
                    {{iceTopicDependency.projectName}} ({{iceTopicDependency.ICETopic.length != undefined? iceTopicDependency.dependencyCount: 1}})
                </div>
                <br />
                    <div *ngIf="iceTopicDependency.ICETopic.length != undefined">
                        <div *ngFor="let dependency of iceTopicDependency.ICETopic">
                            <li *ngIf="dependency.checkedOutUser == ''">
                                {{dependency.iceTopicName}} ({{dependency.referenceCount}}) [{{dependency.iceTopicPath}}]
                            </li>
                            <li *ngIf="dependency.checkedOutUser != ''">
                                {{dependency.iceTopicName}} ({{dependency.referenceCount}}) [{{dependency.iceTopicPath}}] - {{dependency.checkedOutUser}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="iceTopicDependency.ICETopic.length == undefined">     
                            <li *ngIf="iceTopicDependency.ICETopic.checkedOutUser == ''">                    
                                {{iceTopicDependency.ICETopic.iceTopicName}} ({{iceTopicDependency.ICETopic.referenceCount}}) [{{iceTopicDependency.ICETopic.iceTopicPath}}]                                            
                            </li>
                            <li *ngIf="iceTopicDependency.ICETopic.checkedOutUser != ''">                    
                                {{iceTopicDependency.ICETopic.iceTopicName}} ({{iceTopicDependency.ICETopic.referenceCount}}) [{{iceTopicDependency.ICETopic.iceTopicPath}}] - {{iceTopicDependency.ICETopic.checkedOutUser}}                                             
                            </li>
                     </div>
            </div>
        </div>

        <!--ICE Topic Content-->
        <div *ngIf="iceTopicContentDependency != undefined && iceTopicContentDependency.ICETopicContent != undefined ">
            <div>
                <h5 class="text-center">Bullet(s)</h5>
                <div>
                    {{iceTopicContentDependency.projectName}} ({{iceTopicContentDependency.ICETopicContent.length != undefined? iceTopicContentDependency.dependencyCount: 1}})
                </div>
                <br/>
                <div *ngIf="iceTopicContentDependency.ICETopicContent.length != undefined">
                    <div *ngFor="let dependency of iceTopicContentDependency.ICETopicContent">
                        <li *ngIf="dependency.checkedOutUser == ''">
                            {{dependency.descriptionHTML}} ({{dependency.referenceCount}}) [{{dependency.iceTopicContentPath}}]
                        </li>
                        <li *ngIf="dependency.checkedOutUser != ''">
                            {{dependency.descriptionHTML}} ({{dependency.referenceCount}}) [{{dependency.iceTopicContentPath}}] - {{dependency.checkedOutUser}}
                        </li>
                    </div>
                </div>
                <div *ngIf="iceTopicContentDependency.ICETopicContent.length == undefined">     
                        <li *ngIf="iceTopicContentDependency.ICETopicContent.checkedOutUser == ''">                    
                           {{iceTopicContentDependency.ICETopicContent.descriptionHTML}} ({{iceTopicContentDependency.ICETopicContent.referenceCount}}) [{{iceTopicContentDependency.ICETopicContent.iceTopicContentPath}}]                                           
                        </li>
                        <li *ngIf="iceTopicContentDependency.ICETopicContent.checkedOutUser != ''">                    
                            {{iceTopicContentDependency.ICETopicContent.descriptionHTML}} ({{iceTopicContentDependency.ICETopicContent.referenceCount}}) [{{iceTopicContentDependency.ICETopicContent.iceTopicContentPath}}] - {{iceTopicContentDependency.ICETopicContent.checkedOutUser}}                                             
                         </li>
                 </div>
            </div>
        </div>
        <!--Tags-->
        <div *ngIf="tagDependency != undefined && tagDependency.Tag != undefined ">
            <div>
                <h5 class="text-center">Tag(s)</h5>
                <div>
                    {{tagDependency.projectName}} ({{tagDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="tagDependency.Tag.length != undefined">
                    <div *ngFor="let dependency of tagDependency.Tag">
                        <li>
                            {{dependency.tagNo}} - {{dependency.description}}
                        </li>
                    </div>
                </div>
                <div *ngIf="tagDependency.Tag.length == undefined">
                    <li>
                        {{tagDependency.Tag.tagNo}} - {{tagDependency.Tag.description}}
                    </li>
                </div>
            </div>
        </div>
        <!--Assets-->
        <div *ngIf="assetDependency != undefined && assetDependency.Asset != undefined">
            <div>
                <h5 class="text-center">Asset(s)</h5>
                <div>
                    {{assetDependency.projectName}} ({{assetDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="assetDependency.Asset.length != undefined">
                    <div *ngFor="let dependency of assetDependency.Asset">
                            <li>                                        
                                {{dependency.dependencyName}}
                            </li>
                    </div>
                </div>
                <div *ngIf="assetDependency.Asset.length == undefined">     
                        <li>                                                   
                            {{assetDependency.Asset.dependencyName}}      
                        </li>
                 </div>
            </div>
        </div>
        <!--Asset Parents-->
        <div *ngIf="assetParentDependency != undefined">
            <div>
                <h5 class="text-center">Asset Parent(s)</h5>
                <div>
                    {{assetParentDependency.projectName}} ({{assetParentDependency.Topic != undefined? assetParentDependency.Topic.length: 0}})
                </div>
                <br />
                <div *ngFor="let dependency of assetParentDependency.Topic">
                    <li *ngIf="dependency.checkedOutUser == ''">
                        {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}]
                    </li>
                    <li *ngIf="dependency.checkedOutUser != ''">
                        {{dependency.topicName}} ({{dependency.referenceCount}}) [{{dependency.topicPath}}]
                            - {{dependency.checkedOutUser}}
                    </li>
                </div>
            </div>
        </div>

        <!--Asset Attributes-->
        <div *ngIf="assetAttrDependency != undefined">
            <div>
                <h5 class="text-center">Asset Attribute(s)</h5>
                <div>
                    {{assetAttrDependency.projectName}} ({{assetAttrDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="assetAttrDependency.AssetAttr.length != undefined">
                    <div *ngFor="let dependency of assetAttrDependency.AssetAttr">
                        <li>
                            {{dependency.dependencyName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="assetAttrDependency.AssetAttr.length == undefined">
                    <li>
                        {{assetAttrDependency.AssetAttr.dependencyName}}
                    </li>
                </div>
            </div>
        </div>
        
        <!--MCC-->
        <div *ngIf="mccDependency != undefined">
            <div>
                <h5 class="text-center">Mechanical Completion Certificate(s)</h5>
                <div>
                    {{mccDependency.projectName}} ({{mccDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="mccDependency.MCC.length != undefined">
                    <div *ngFor="let dependency of mccDependency.MCC">
                        <li>
                            {{dependency.MCCName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="mccDependency.MCC.length == undefined">
                    <li>
                        {{mccDependency.MCC.MCCName}}
                    </li>
                </div>
            </div>
        </div>
          <!--SCC-->
        <div *ngIf="sccDependency != undefined">
            <div>
                <h5 class="text-center">Static Completion Certificate(s)</h5>
                <div>
                    {{sccDependency.projectName}} ({{sccDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="sccDependency.SCC.length != undefined">
                    <div *ngFor="let dependency of sccDependency.SCC">
                        <li>
                            {{dependency.SCCName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="sccDependency.SCC.length == undefined">
                    <li>
                        {{sccDependency.SCC.SCCName}}
                    </li>
                </div>
            </div>
        </div>
        <!--SHC-->
        <div *ngIf="shcDependency != undefined">
            <div>
                <h5 class="text-center">System Handover Certificate(s)</h5>
                <div>
                    {{shcDependency.projectName}} ({{shcDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="shcDependency.SHC.length != undefined">
                    <div *ngFor="let dependency of shcDependency.SHC">
                        <li>
                            {{dependency.SHCName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="shcDependency.SHC.length == undefined">
                    <li>
                        {{shcDependency.SHC.SHCName}}
                    </li>
                </div>
            </div>
        </div>
        <!--CCC-->
        <div *ngIf="cccDependency != undefined">
            <div>
                <h5 class="text-center">Comm Completion Certificate(s)</h5>
                <div>
                    {{cccDependency.projectName}} ({{cccDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="cccDependency.CCC.length != undefined">
                    <div *ngFor="let dependency of cccDependency.CCC">
                        <li>
                            {{dependency.CCCName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="cccDependency.CCC.length == undefined">
                    <li>
                        {{cccDependency.CCC.CCCName}}
                    </li>
                </div>
            </div>
        </div>
        <!--STN-->
        <div *ngIf="stnDependency != undefined">
            <div>
                <h5 class="text-center">System Turnover Certificate(s)</h5>
                <div>
                    {{stnDependency.projectName}} ({{stnDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="stnDependency.STN.length != undefined">
                    <div *ngFor="let dependency of stnDependency.STN">
                        <li>
                            {{dependency.STNName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="stnDependency.STN.length == undefined">
                    <li>
                        {{stnDependency.STN.STNName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Punch list-->
        <div *ngIf="punchListDependency != undefined">
            <div>
                <h5 class="text-center">Punch List(s)</h5>
                <div>
                    {{punchListDependency.projectName}} ({{punchListDependency.dependencyCount != undefined? punchListDependency.dependencyCount: 0}})
                </div>
                <br />
                <div *ngIf="punchListDependency.Punchlist.length != undefined ">
                    <div *ngFor="let dependency of punchListDependency.Punchlist">
                        <li>
                            {{dependency.punchlistDescrip}}
                        </li>
                    </div>
                </div>
                <div *ngIf="punchListDependency.Punchlist.length == undefined ">
                    <li>
                        {{punchListDependency.Punchlist.punchlistDescrip}}
                    </li>
                </div>
            </div>
        </div>
        <!--Topic Map-->
        <div *ngIf="topicMapDependency != undefined && topicMapDependency.TopicMap != undefined">
            <div>
                <h5 class="text-center">Topic Map(s)</h5>
                <div>
                    {{topicMapDependency.projectName}} ({{topicMapDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="topicMapDependency.TopicMap.length != undefined ">
                    <div *ngFor="let dependency of topicMapDependency.TopicMap">
                        <li>
                            {{dependency.topicMapName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="topicMapDependency.TopicMap.length == undefined">
                    <li>
                        {{topicMapDependency.TopicMap.topicMapName}}
                    </li>
                </div>
            </div>
        </div>
        <div *ngIf="topicMapDependency != undefined && topicMapDependency.TopicMap == undefined">
            <div>
                <h5 class="text-center">Topic Map(s)</h5>
                <div *ngFor="let tmd of topicMapDependency">
                    <ul>
                        {{tmd.projectName}} ({{tmd.dependencyCount}})
                        <br/>
                        <div *ngIf="tmd.TopicMap.length != undefined">
                            <div *ngFor="let topicMap of tmd.TopicMap">
                                <li>{{topicMap.topicMapName}}</li>
                            </div>
                        </div>
                        <div *ngIf="tmd.TopicMap.length == undefined">
                            <li>{{tmd.TopicMap.topicMapName}}</li>
                        </div> 
                    </ul>
                </div>
            </div>
        </div>
         <!--Image-->
        <div *ngIf="imageDependency != undefined">
            <div>
                <h5 class="text-center">Image(s)</h5>
                <div *ngIf="imageDependency.length != undefined">
                    <div *ngFor="let project of imageDependency">
                        <ul>
                            {{project.projectName}} ({{project.dependencyCount}})
                            <br/>
                            <div *ngIf="project.Image.length != undefined">
                                <div *ngFor="let dependency of project.Image">
                                    <li>
                                        {{dependency.ImageName}}
                                    </li>
                                </div>
                            </div>
                            <div *ngIf="project.Image.length == undefined">
                                <li>
                                    {{project.Image.ImageName}}
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div *ngIf="imageDependency.length == undefined">
                    <div>
                        {{imageDependency.projectName}} ({{imageDependency.dependencyCount}})
                    </div>
                    <br/>
                    <div *ngIf="imageDependency.Image.length != undefined">
                        <div *ngFor="let dependency of imageDependency.Image">
                            <li>
                                {{dependency.ImageName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="imageDependency.Image.length == undefined">
                        <li>
                            {{imageDependency.Image.ImageName}}
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <!--Folders-->
        <div *ngIf="folderDependency != undefined">
            <div>
                <h5 class="text-center">Folder(s)</h5>
                <div *ngIf="folderDependency.Folder.length != undefined">
                    <div *ngFor="let dependency of folderDependency.Folder">
                        <li>
                            {{dependency.folderName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="folderDependency.Folder.length == undefined">
                    <li>
                        {{folderDependency.Folder.folderName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Contents-->
        <div *ngIf="contentDependency != undefined && contentDependency.ContentItem != undefined">
            <div>
                <h5 class="text-center">Content Item(s)</h5>
                <div>
                    {{contentDependency.projectName}} ({{contentDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="contentDependency.ContentItem.length != undefined">
                    <div *ngFor="let dependency of contentDependency.ContentItem">
                        <li>
                            {{dependency.ContentName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="contentDependency.ContentItem.length == undefined">
                    <li>
                        {{contentDependency.ContentItem.ContentName}}
                    </li>
                </div>
            </div>
        </div>
        <div *ngIf="contentDependency != undefined && contentDependency.ContentItem == undefined">
            <div>
                <h5 class="text-center">Content Item(s)</h5>
                <div *ngFor="let cd of contentDependency">
                    <ul>
                        {{cd.projectName}} ({{cd.dependencyCount}})
                        <div *ngIf="cd.ContentItem.length != undefined">
                            <div *ngFor="let contentItem of cd.ContentItem">
                                <li>{{contentItem.ContentName}}</li>
                            </div>
                        </div>
                        <div *ngIf="cd.ContentItem.length == undefined">
                            <li>{{cd.ContentItem.ContentName}}</li> 
                        </div> 
                    </ul>
                </div>        
            </div>
        </div>
        <!--Documents-->
        <div *ngIf="documentDependency != undefined">
            <div>
                <h5 class="text-center">Document(s)</h5>
                <div>
                    {{documentDependency.projectName}} ({{documentDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="documentDependency.Document.length != undefined">
                    <div *ngFor="let dependency of documentDependency.Document">
                        <li>
                            {{dependency.DocumentName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="documentDependency.Document.length == undefined">
                    <li>
                        {{documentDependency.Document.DocumentName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Projects-->
        <div *ngIf="projectDependency != undefined">
            <div>
                <h5 class="text-center">Project(s)</h5>
                <div *ngIf="projectDependency.Project.length != undefined">
                    <div *ngFor="let dependency of projectDependency.Project">
                        <li>
                            {{dependency.projectName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="projectDependency.Project.length == undefined">
                    <li>
                        {{projectDependency.Project.projectName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Components-->
        <div *ngIf="componentDependency != undefined">
            <div>
                <h5 class="text-center">Component(s)</h5>
                <div>
                    {{componentDependency.projectName}} ({{componentDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="componentDependency.Component.length != undefined">
                    <div *ngFor="let dependency of componentDependency.Component">
                        <li>
                            {{dependency.ComponentName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="componentDependency.Component.length == undefined">
                    <li>
                        {{componentDependency.Component.ComponentName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Statements-->
        <div *ngIf="statementDependency != undefined">
            <div>
                <h5 class="text-center">Statement(s)</h5>
                <div>
                    {{statementDependency.projectName}} ({{statementDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="statementDependency.Statement.length != undefined">
                    <div *ngFor="let dependency of statementDependency.Statement">
                        <li>
                            {{dependency.StatementName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="statementDependency.Statement.length == undefined">
                    <li>
                        {{statementDependency.Statement.StatementName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Checksheets-->
        <div *ngIf="checksheetDependency != undefined">
            <div>
                <h5 class="text-center">Checksheet(s)</h5>
                <div>
                    {{checksheetDependency.projectName}} ({{checksheetDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="checksheetDependency.CheckSheet.length != undefined">
                    <div *ngFor="let dependency of checksheetDependency.CheckSheet">
                        <li>
                            {{dependency.checkSheetName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="checksheetDependency.CheckSheet.length == undefined">
                    <li>
                        {{checksheetDependency.CheckSheet.checkSheetName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Users-->
        <div *ngIf="userDependency != undefined ">
            <div>
                <h5 class="text-center">User(s)</h5>
                <div *ngIf="hasUserDependency">
                    <div *ngIf="userDependency.User.length != undefined">
                        <div *ngFor="let dependency of userDependency.User">
                            <li>
                                {{dependency.dependencyName}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="userDependency.User.length == undefined">
                        <li>
                            {{userDependency.User.dependencyName}}
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <!--ICE TOPIC COUNT-->
        <div *ngIf="iceTopicCountDependency != undefined">
            <div *ngIf="topicDependencies == 1 || topicCountDependencies ==1">
                <h5 class="text-center">ICE Topic Menu Conflicts</h5>
                <div class="card p-0 alert alert-warning" role="alert">
                    <ul>
                        <li class="m-1">
                            <label>Topic Menu Dependencies : {{ iceTopicCountDependency }}</label>
                        </li>
                        <li class="m-1">
                            <label>Bullet Dependencies : {{ iceTopicContentCountDependency }}</label>
                        </li>
                    </ul>
                    <!-- <div *ngIf=>

                    </div> -->
                </div>
            </div>
        </div>
        <!--ICE Learning Check-->
        <div *ngIf="iceLearningCheckDependency != undefined && iceLearningCheckDependency.ICELearningCheck != undefined ">
            <div>
                <h5 class="text-center">ICE Learning Check(s)</h5>
                <div>
                    {{iceLearningCheckDependency.projectName}} ({{iceLearningCheckDependency.ICELearningCheck.length != undefined? iceLearningCheckDependency.dependencyCount: 1}})
                </div>
                <br/>
                <div *ngIf="iceLearningCheckDependency.ICELearningCheck.length != undefined">
                    <div *ngFor="let dependency of iceLearningCheckDependency.ICELearningCheck">
                        <li *ngIf="dependency.checkedOutUser == ''">
                            {{dependency.iceLearningCheckDescription}} ({{dependency.referenceCount}}) [{{dependency.iceLearningCheckPath}}]
                        </li>
                        <li *ngIf="dependency.checkedOutUser != ''">
                            {{dependency.iceLearningCheckDescription}} ({{dependency.referenceCount}}) [{{dependency.iceLearningCheckPath}}] - {{dependency.checkedOutUser}}
                        </li>
                    </div>
                </div>
                <div *ngIf="iceLearningCheckDependency.ICELearningCheck.length == undefined">     
                        <li *ngIf="iceLearningCheckDependency.ICELearningCheck.checkedOutUser == ''">                    
                           {{iceLearningCheckDependency.ICELearningCheck.iceLearningCheckDescription}} ({{iceLearningCheckDependency.ICELearningCheck.referenceCount}}) [{{iceLearningCheckDependency.ICELearningCheck.iceLearningCheckPath}}]                                           
                        </li>
                        <li *ngIf="iceLearningCheckDependency.ICELearningCheck.checkedOutUser != ''">                    
                            {{iceLearningCheckDependency.ICELearningCheck.iceLearningCheckDescription}} ({{iceLearningCheckDependency.ICELearningCheck.referenceCount}}) [{{iceLearningCheckDependency.ICELearningCheck.iceLearningCheckPath}}] - {{iceLearningCheckDependency.ICELearningCheck.checkedOutUser}}                                             
                         </li>
                 </div>
            </div>
        </div>
        <!--ICE Game-->
        <div *ngIf="iceGameDependency != undefined && iceGameDependency.ICEGame != undefined ">
            <div>
                <h5 class="text-center">ICE Game(s)</h5>
                <div>
                    {{iceGameDependency.projectName}} ({{iceGameDependency.ICEGame.length != undefined? iceGameDependency.dependencyCount: 1}})
                </div>
                <br/>
                <div *ngIf="iceGameDependency.ICEGame.length != undefined">
                    <div *ngFor="let dependency of iceGameDependency.ICEGame">
                        <li *ngIf="dependency.checkedOutUser == ''">
                            {{dependency.iceGameDescription}} ({{dependency.referenceCount}}) [{{dependency.iceGamePath}}]
                        </li>
                        <li *ngIf="dependency.checkedOutUser != ''">
                            {{dependency.iceGameDescription}} ({{dependency.referenceCount}}) [{{dependency.iceGamePath}}] - {{dependency.checkedOutUser}}
                        </li>
                    </div>
                </div>
                <div *ngIf="iceGameDependency.ICEGame.length == undefined">     
                        <li *ngIf="iceGameDependency.ICEGame.checkedOutUser == ''">                    
                           {{iceGameDependency.ICEGame.iceGameDescription}} ({{iceGameDependency.ICEGame.referenceCount}}) [{{iceGameDependency.ICEGame.iceGamePath}}]                                           
                        </li>
                        <li *ngIf="iceGameDependency.ICEGame.checkedOutUser != ''">                    
                            {{iceGameDependency.ICEGame.iceGameDescription}} ({{iceGameDependency.ICEGame.referenceCount}}) [{{iceGameDependency.ICEGame.iceGamePath}}] - {{iceGameDependency.ICEGame.checkedOutUser}}                                             
                         </li>
                 </div>
            </div>
        </div>
        <!--ICE Course Output-->
        <div *ngIf="iceCourseOutput != undefined">
            <div>
                <h5 class="text-center">ICE Course Output</h5>
                <div class="card p-0 alert alert-warning" role="alert">
                    <ul>
                        <li class="m-1">
                            <label>{{ iceCourseOutput }}</label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--Tag Attributes-->
        <div *ngIf="tagAttributeDependency != undefined">
                <div>
                    <h5 class="text-center">Tag Attribute(s)</h5>
                    <div>
                        {{tagAttributeDependency.projectName}} ({{tagAttributeDependency.dependencyCount}})
                    </div>
                    <br/>
                    <div *ngIf="tagAttributeDependency.TagAttribute.length != undefined">
                        <div *ngFor="let dependency of tagAttributeDependency.TagAttribute">
                            <li>
                                {{dependency.dependencyName}} - {{dependency.tagNo}}
                            </li>
                        </div>
                    </div>
                    <div *ngIf="tagAttributeDependency.TagAttribute.length == undefined">
                        <li>
                            {{tagAttributeDependency.TagAttribute.dependencyName}} - {{tagAttributeDependency.TagAttribute.tagNo}}
                        </li>
                    </div>
                </div>
            </div>
        <!--IRNs-->
        <div *ngIf="irnDependency != undefined">
            <div>
                <h5 class="text-center">IRN(s)</h5>
                <div>
                    {{irnDependency.projectName}} ({{irnDependency.dependencyCount}})
                </div>
                <br/>
                <div *ngIf="irnDependency.IRN.length != undefined">
                    <div *ngFor="let dependency of irnDependency.IRN">
                        <li>
                            {{dependency.dependencyName}}
                        </li>
                    </div>
                </div>
                <div *ngIf="irnDependency.IRN.length == undefined">
                    <li>
                        {{irnDependency.IRN.dependencyName}}
                    </li>
                </div>
            </div>
        </div>
        <!--Checks-->
        <div *ngIf="checkDependency != undefined && checkDependency.Check != undefined">
            <div>
                <h5 class="text-center">Check(s)</h5>
                <div>
                    {{checkDependency.projectName}} ({{checkDependency.dependencyCount}})
                </div>
                <br />
                <div *ngIf="checkDependency.Check.length != undefined">
                    <div *ngFor="let dependency of checkDependency.Check">
                            <li>                                        
                                {{dependency.dependencyName}}
                            </li>
                    </div>
                </div>
                <div *ngIf="checkDependency.Check.length == undefined">     
                        <li>                                                   
                            {{checkDependency.Check.dependencyName}}      
                        </li>
                 </div>
            </div>
        </div>
         <!--Document types - load document type dependencies(sub types) for delete and disable-->
         <div *ngIf="topicTypeDependency != undefined && topicTypeDependency.TopicType != undefined">
            <div>
                <h5 class="text-center">Document Type(s)</h5>
                <ul style="padding-left: 40px;">
                <div *ngIf="topicTypeDependency.TopicType.length != undefined">
                    <div *ngFor="let dependency of topicTypeDependency.TopicType">
                            <li>                                        
                                {{dependency.topicTypeName}}
                            </li>
                    </div>
                </div>
                <div *ngIf="topicTypeDependency.TopicType.length == undefined">     
                        <li>                                                   
                            {{topicTypeDependency.TopicType.topicTypeName}}      
                        </li>
                 </div>
                </ul>
            </div>
        </div>
        <!--Document Types-->
        <div *ngIf="documentTypeDependency != undefined">
            <div>
                <h5 *ngIf="!this.noDependecies" class="text-center">Document Type(s)</h5>
                <br/>
                <div *ngIf="documentTypeDependency.length != undefined">
                    <div *ngFor="let dependency of documentTypeDependency">
                        <li>
                            {{dependency.topicType}}
                        </li>
                    </div>
                </div>
                <div *ngIf="documentTypeDependency.length == undefined">
                    <li>
                        {{documentTypeDependency.topicType}}
                    </li>
                </div>
            </div>
        </div>
        <div *ngIf="delFlag && (systemDependency != undefined || topicDependency != undefined || procDependency != undefined || iceCourseDependency != undefined
        || iceTopicDependency != undefined || iceTopicContentDependency != undefined || tagDependency != undefined || assetAttrDependency != undefined || 
        assetParentDependency != undefined || assetDependency != undefined || mccDependency != undefined || sccDependency != undefined || shcDependency != undefined
        || cccDependency != undefined || stnDependency != undefined || punchListDependency != undefined || topicMapDependency != undefined || imageDependency != undefined
        || folderDependency != undefined || contentDependency != undefined || documentDependency != undefined || projectDependency != undefined || 
        componentDependency != undefined || statementDependency != undefined || checksheetDependency != undefined || userDependency != undefined 
        || tagAttributeDependency != undefined || irnDependency != undefined || checkDependency != undefined || (topicTypeDependency != undefined && topicTypeDependency.TopicType != undefined) )" class="pl-4">
			<label><input type="checkbox" [(ngModel)]="checkboxVal" (change)="onCheckboxClick($event)">{{'DISABLE' | translate}}</label>
            </div>

            <div *ngIf="noDependecies">
                <h5 class="text-center">
                    <label>{{ 'DEPENDENCIES_MODAL.NO_ANY_DEPENDENCIES' | translate }}</label>
                </h5>
            </div>
        </div>
        <div class="modal-footer">
            <button *ngIf="cancelFlag" type="button" class="btn btn-secondary btn-sm" (click)="cancel();bsModalRef.hide()">{{ 'CANCEL' |
                translate}}</button>
            <button type="button" class="btn btn-warning" (click)="bsModalRef.hide();confirm(headerTitle,checkboxVal,delFlag);">{{
                'OK' | translate}}</button>
        </div>
    </div>
<!-- <div *ngIf="displayModel">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{headerTitle}} </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div *ngIf="delFlag || cancelFlag">
                <div *ngFor="let content of contentHeader; let i = index">
                    <h5 class="text-center" [innerHTML]="content">
                        {{content}}
                    </h5>
                    <br>
                    <div *ngFor="let item of multi[i]; let j = index">
                        {{item}}
                        <br *ngIf="item != ''"/>
                        <div *ngFor="let dependency of dependencyNameList[i][j]">
                            <div *ngIf="content == 'ICECourse(s)' || content == 'ICETopic(s)'">
                                <li [innerHTML]="dependency.displayName"></li>                                   
                            </div> -->
                            <!-- <li *ngIf="content == 'ICECourse(s)' || content == 'ICETopic(s)'" [innerHTML]="dependency.displayName"></li>
                            <div *ngIf="content != 'ICECourse(s)' && content != 'ICETopic(s)'">
                                    <li>
                                            {{dependency.displayName}}
                                    </li>                            
                            </div>
						</div>
					</div>
				</div>
				<div *ngIf= "deleteConfirm">
					<h5 class="text-center">
						{{ 'DEPENDENCIES_MODAL.DELETE_STATEMENT' | translate }}
					</h5>
				</div>
            </div>
            <div *ngIf="!delFlag && !cancelFlag">
                <h5 class="text-center">
                    {{ 'DEPENDENCIES_MODAL.DELETE_MESSAGE' | translate }}
                </h5>
            </div>
        </div>
    </div>
    <div *ngIf="delFlag && dependencyNameList.length != 0 && itemType != 'Folder'" class="pl-4">
        <label><input type="checkbox" [(ngModel)]="checkboxVal"> {{ 'DISABLE' | translate}}</label>
	</div>
	<div *ngIf="dependencyNameList.length == 0" class="pl-4">
			<h5 class="text-center">
		<label>{{ 'DEPENDENCIES_MODAL.NO_ANY_DEPENDENCIES' | translate }}</label>
		</h5>
    </div>
    <div class="modal-footer">
        <button *ngIf="cancelFlag" type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ 'CANCEL' |
            translate}}</button>
        <button type="button" class="btn btn-warning" (click)="bsModalRef.hide();confirm(headerTitle,checkboxVal,delFlag);">{{
            'OK' | translate}}</button>
    </div>
</div> --> 