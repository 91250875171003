import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, forwardRef, TemplateRef, NgModule, Output, EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FilesService } from '../home/files/files.service';
import { WsType } from '../util/ws-type';
import { WsResponse } from '../util/ws-response.model';
import { AlertService } from '../util/alert/alert.service';
import { LoadingService } from '../util/loading/loading.service';
import { SharedService } from '../util/shared.service';
import { AgGridAngular } from '@ag-grid-community/angular';
import { TranslateService } from '@ngx-translate/core';
import { StatementsService } from '../reusables/statements/statements.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { WebEditorService } from './webeditor.service';
import { FilesDataService } from '../home/files/filesdata/filesdata.service';
import { Constants, UI_LAYOUT, LocalStorageConst, SessionStorageConst } from '../util/constants';
import * as $ from 'jquery';
import { LocalStorage } from './../util/localstorage.service';
import { Module, AllModules } from "@ag-grid-enterprise/all-modules";

declare const CKEDITOR;
var editor, tag_editor;
declare var require: any;

@Component({
    selector: 'app-ckeditor',
    templateUrl: './webeditor.component.html',
    styleUrls: ['./webeditor.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WebeditorComponent),
        multi: true
    }],

})
export class WebeditorComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @ViewChild('editor', {static: true}) editor: ElementRef;
    @ViewChild('save_warn_for_tables') save_warn_for_tables: TemplateRef<any>;
    @ViewChild('save_warn_for_lists_save') save_warn_for_lists_save: TemplateRef<any>;
    @ViewChild('save_warn_for_lists_checkin') save_warn_for_lists_checkin: TemplateRef<any>;
    @ViewChild('checkin_warn_for_tables') checkin_warn_for_tables: TemplateRef<any>;
    @ViewChild('checkin_comments') checkin_comments: TemplateRef<any>;
    @ViewChild('warn_for_tables') warn_for_tables: TemplateRef<any>;
    @ViewChild('warn_for_lists') warn_for_lists: TemplateRef<any>;
    @ViewChild('undo_checkout_warn') undo_checkout_warn: TemplateRef<any>;
    @Input("configData") configData: any;
    @Output() valueChange = new EventEmitter();
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('conflict_checker') conflict_checker: TemplateRef<any>;

    //private callback: WsCallback;
    //[htmlString]
    wait = false;
    instance: any;
    private tagEditor: any;
    public selection: any;
    public ranges: any;
    public categoryName: string;
    modalRef: BsModalRef;
    public cache: any;
    public name: any;
    punchlistsSelectedCatogory: any = "ALL";
    checksheetSelectedType: any = "A";
    vendorSelectedType: any[] = [];
    vendorId: any;
    public fileData: any;
    selectedCount: any = 0;
    bsModalRef: BsModalRef;
    tableSaveWarningmModalRef: BsModalRef;
    listSaveWarningModalRef: BsModalRef;
    tableCheckinWarningModalRef: BsModalRef;
    listCheckinWarningModalRef: BsModalRef;
    tableWarnModalRef: BsModalRef;
    listWarnModalRef: BsModalRef;
    undocheckoutWarningModalRef: BsModalRef;
    config = {
        on: {
            widgetDefinition: function (evt) {
                if ((evt.data.name == 'image')
                    || (evt.data.name == 'topicncwrs')) {
                    evt.data.draggable = false;
                }
            },
        },
        sharedSpaces: {
            top: 'topMenu_' + SharedService.getTabId(),
        },
        tabID: SharedService.getTabId(),
        autoParagraph: false,
        basicEntities: true,
        startupFocus: true,
        //entities: true,
        height: SharedService.setTopicEditorHeight(),
        removePlugins: 'symbol,elementspath,resize'

    };
    private _value = '';
    private gridApi;
    public getRowNodeId;
    private gridColumnApi;
    public multipleSelections = false;

    public systemId: string = "b2b3a20d-c243-4992-9d94-bd6d5bf41233,56461fcd-c7f7-4a3c-b1c7-9b38bbb5538b,";
    public csType: string = "A";
    public showEditorLayoutPanel: boolean = false
    private insertSubscription: any;
    private timerSubscription: any;
    private buttonData: any = null;
    public isSelected: boolean = false;
    private fileId: string;
    private tempSaveKey: string;
    private fileType: string;
    public showCategory: boolean = true;
    public showTypeChecksheets: boolean = false;
    public showTypeChecksheetStatus: boolean = false;
    public showTypeChecksheetSummary: boolean = false;
    public showVendor: boolean = false;
    private htmlString: String;
    public filteredData: Array<any> = [];
    private html: string;
    private stmHtml: string;
    private htmlData: string;
    private width: any;
    private sccData: any;
    private data: any;
    private tableWidth: any;
    private dataForTable: any[] = [];
    private tagContent: string = '';
    private titleEditor;
    public speCharList: Array<any> = [];
    public rowData: any[] = [];
    public systemIds: Array<any> = [];
    public columnDefs;
    public modules: Module[] = AllModules;
    private rowSelection;
    public vendorList: Array<any> = [];
    public categoryNameList: Array<any> = [];
    public selectedCategoryName: string = " ";
    public isCheckedIn: boolean = false;
    public selectedRowsString = "";
    public selectedWidget = ""
    //selected commissionning button item used to change the class of the button
    public selectedComItem: string = 'punchlist';
    public tabId: String = "";
    public checkinDivId: String = "";
    public checkoutDivId: String = "";
    public saveDivId: String = "";
    public undoCheckoutDivId: String = "";
    public addFractionDIvId: String = "";
    public addSpecialCharDivId: String = "";
    private guidWinT: String;
    private isFirefox: boolean = false;
    private isIpad: boolean = false;
    private editorFocused: boolean = false;
    private userId: String = null;
    private checkoutUserId: String = null;
    public isCheckOutByOthers = false;
    public isLoadedEditor = false;
    public showPunchlistStatus: boolean = true;
    public punchlistSelectedStatus: any = "Open"
    private editorData: String = "";
    private titleEditorData: String = "";
    private editorInstanceReady = false;
    private titleEditorInstanceReady = false;

    public checkIpad() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            this.isIpad = true;
            return;
        }
    
        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent("TouchEvent");
                this.isIpad = true;
                return;
            } catch (e) {}
        }
    
        this.isIpad = false;
    }

    public categoryList: Array<any> = [
        { 'id': "All", 'text': "ALL" },
        { 'id': "0", 'text': "A" },
        { 'id': "1", 'text': "B" },
        { 'id': "2", 'text': "C" },
        { 'id': "3", 'text': "D" }
    ]

    public typeList: Array<any> = [
        { 'id': "0", 'text': "A" },
        { 'id': "1", 'text': "B" },
    ]

    public punchlistStausList: Array<any> = [
        { 'id': "Open", 'text': "Open" },
        { 'id': "All", 'text': "All" },        
        { 'id': "Closed", 'text': "Closed"}
    ]


    get value(): any { return this._value; }
    @Input() set value(v) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    public divHeight: number = (window.innerHeight);
    @HostListener('window:resize', ['$event'])
    onresize(event?) {
        this.divHeight = (window.innerHeight)
        this.config.height=SharedService.setTopicEditorHeight();
        this.instance.resize(this.instance.config.width, this.config.height, true, false);
    }

    private inlineConfig = {
        autoParagraph: false,
        readOnly: false,
        removePlugins: 'toolbar, pastefromword, tableselection, uploadwidget, clipboard, pastetext, widget, uploadimage, emptytopicheader, topicheader, liststyle, magicline, image2, topicncwrs, symbol'
    };

    private titleConfig = {
        sharedSpaces: {
            top: 'topMenu_' + SharedService.getTabId(),
        },
        autoParagraph: false,
        readOnly: false,
        basicEntities: true,
        tabID: SharedService.getTabId(),
        removePlugins: 'liststyle, magicline,specialchar, table, topicncwrs, tabletools, tableselection, tableresize, commissioning, list, indent, indentblock, indentlist,zoom, wsc, symbol',
        fontSize_defaultLabel: '30px'
    };

    public columnCCDefs = [
        { headerName: '', field: 'cbox', width: 50, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
        { headerName: 'Type', field: 'type', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
        { headerName: 'Last Value', field: 'lastVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "lastVal" },
        { headerName: 'New Value', field: 'newVal', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, tooltipField: "newVal" },
        { headerName: 'Id', field: 'id', hide: true },
        { headerName: 'Name', field: 'name', hide: true },
        { headerName: 'LastUpdateTimeStamp', field: 'lastUpdateTimeStamp', hide: true },
        { headerName: 'isDeleted', field: 'isDeleted', hide: true }
    ]

    public defaultColDef = {
        resizable: true,
        sortable: true,
    };

    constructor(private filesService: FilesService, private alertService: AlertService,
        private loadingService: LoadingService, private sharedService: SharedService,
        private statementsService: StatementsService,
        private modalService: BsModalService, private webEditorService: WebEditorService,
        private filesDataService: FilesDataService, private translate: TranslateService, private elementRef: ElementRef) {

        this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
        this.checkIpad();
        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        this.fileId = sharedService.getFileId();
        this.fileType = sharedService.getFileType();
        this.tempSaveKey = this.fileId + '_topicTempContent';
        filesService.setCallback(this);
        webEditorService.setCallback(this);
        filesDataService.setCallback(this);
        this.tabId = 'topMenu_' + SharedService.getTabId();
        this.checkinDivId = 'webeditor_checkin_' + SharedService.getTabId();
        this.checkoutDivId = 'webeditor_checkout_' + SharedService.getTabId();
        this.saveDivId = 'webeditor_save_' + SharedService.getTabId();
        this.undoCheckoutDivId = 'webeditor_undocheckout_' + SharedService.getTabId();
        this.addFractionDIvId = 'webeditor_fraction_' + SharedService.getTabId();
        this.addSpecialCharDivId = 'webeditor_special_char_' + SharedService.getTabId();
        this.rowSelection = "multiple";
        this.checkoutUserId = sharedService.getCheckoutUserId();
    }


    ngOnInit() {
        this.speCharList = [
            { data: { symbol: this.getSymbolFromDecimal(169), decVal: 169, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(8482), decVal: 8482, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(174), decVal: 174, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(176), decVal: 176, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(163), decVal: 163, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(8364), decVal: 8364, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(10016), decVal: 10016, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(9746), decVal: 9746, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(9744), decVal: 9744, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(167), decVal: 167, type: "speChar" } },
            { data: { symbol: this.getSymbolFromDecimal(937), decVal: 937, type: "speChar" } }
        ];

        //this.config.removePlugins = 'save';

        if (this.configData != null && this.configData != undefined) {
            this.config = this.configData;
		}

        if (this.sharedService.getIsCheckedIn() == true) {
            this.isCheckedIn = true;
            this.inlineConfig.readOnly = true;
            this.titleConfig.readOnly = true;
        } else {
            this.isCheckedIn = false;
            this.inlineConfig.readOnly = false;
            this.titleConfig.readOnly = false;
        }


        const loggedUserId = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_USER));
        if (this.checkoutUserId != null) {
            if (loggedUserId.userId != this.checkoutUserId) {
                this.isCheckOutByOthers = true;
            } else {
                this.isCheckOutByOthers = false;
                //this.isCheckedIn = false;
            }
        } else {
            this.isCheckOutByOthers = false;
        }


        this.instance = CKEDITOR.replace(this.editor.nativeElement, this.config);
        this.sharedService.setEditor(this.instance);

        if (this.configData != null && this.configData != undefined) {
            this.instance.setData(localStorage.getItem("selectedContent"));
        }

        var self = this;
        if (this.configData == null || this.configData == undefined) {
            setTimeout(() => {
                let titleEl = this.elementRef.nativeElement.querySelector('.title_editor');
                this.titleEditor = CKEDITOR.inline(titleEl, this.titleConfig);

                this.setEditor();

                this.titleEditor.on('key', function (e) {
                    self.editorKeyDown(e);
                });
                this.titleEditor.on('instanceReady', function (evt){
                    console.log("=========== title instanceReady ============ ");
                    if (self.isCheckedIn == true) {
                        console.log("checkin true");
                        if (self.isCheckOutByOthers) {
                            console.log("CheckOutByOthers true");
                            this.getCommand('checkout').disable();
                        } else {
                            console.log("CheckOutByOthers false");
                            this.getCommand('checkout').enable();
                        }
                    } else {
                        console.log("checkin false");
                        this.getCommand('checkout').disable();
                    }

                    if(this.getData() == null || this.getData() == ""){
                        if(self.titleEditorData != null && self.titleEditorData != ""){
                            console.log("Set Data should be called titleEditorData");
                            this.setData(self.titleEditorData);
                        }
                    }
                    self.titleEditorInstanceReady = true;
                });

				this.titleEditor.on('focus', function (evt) {
					if(self.isFirefox){
						let wintitles = self.titleEditor.container.getElementsByTag("wintitle");
						for(let i=0; i<wintitles.$.length; i++){
							wintitles.$[i].removeAttribute("contenteditable");
						}
					}
                    if(self.isIpad){
                        setTimeout(() => {
                            let sx = document.body.scrollLeft, sy = document.body.scrollTop;
                            window.scrollTo(sx, sy);                            
                        }, 50);
                    }
				});
            }, 0);
        }

        if (this.configData == null || this.configData == undefined) {
            this.instance.on('change', () => {
                if (this.isLoadedEditor) {
                    this.sharedService.setViewOnlyStatus(this.fileId, false);
                }
            });

            this.instance.on('doubleclick', function (e) {
                var nodetest =e.data.element.$;
                if (editor != undefined && editor != null) {
                    self.selection = editor.getSelection();
                    if (self.selection != null) {
                        self.ranges = self.selection.getRanges();
                    }
                    if (self.ranges != null) {
                        let range = self.ranges[0];
                        if (range != null) {
                            range = range.clone();
                            var startNode = range.startContainer.$;
                            var ancestor = startNode.parentNode;
                            if (ancestor != null && ancestor.nodeName == 'PH') {
                                let attr = ancestor.attributes;
                                if (attr.length != 0) {
                                    self.getRestrictionValue(ancestor);
                                    self.fireEvent("navbarSupportedContent", "click");
                                }
                            }else if(nodetest!= null && nodetest.nodeName == 'PH'){
                                SharedService.setselectedNode(nodetest);
                                let attr = nodetest.attributes;
                                if (attr.length != 0) {
                                    self.getRestrictionValue(nodetest);
                                    self.fireEvent("navbarSupportedContent", "click");
                                }
                            }
                        }
                    }
                }
            });
        }

        CKEDITOR.on('dialogDefinition', function (ev) {
            ev.data.definition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
        });

        if (this.configData == null || this.configData == undefined) {
            this.instance.on('key', function (e) {
                self.editorKeyDown(e);
            });
        }

        this.instance.on('instanceReady', function (evt) {
            console.log("=========== instanceReady ============");
            if (this.getCommand('checkout') !== undefined) {
                if (self.isCheckedIn == true) {
                    console.log("checkin is true");
                    if (self.isCheckOutByOthers) {
                        console.log("checkout by others true");
                        this.getCommand('checkout').disable();
                    } else {
                        console.log("checkout by others false");
                        this.getCommand('checkout').enable();
                    }
                } else {
                    console.log("checkin is false");
                    this.getCommand('checkout').disable();
                }
            }
            this.dataProcessor.htmlFilter.addRules({
                elements: {
                    ul: function (el) {
                        if (!el.attributes.style) {
                            el.attributes['style'] = 'list-style-type:disc';
                        }
                        if (!el.attributes.ckeditorlist) {
                            el.attributes['ckeditorlist'] = 'true';
                        }
                    },
                    ol: function (el) {
                        if (!el.attributes.style) {
                            el.attributes['style'] = 'list-style-type:decimal';
                        }
                        if (!el.attributes.ckeditorlist) {
                            el.attributes['ckeditorlist'] = 'true';
                        }
                    }
                }
            });
            if (evt.editor.contextMenu) {
                evt.editor.removeMenuItem('image');
            }

            if(this.getData() == null || this.getData() == ""){
                if(self.editorData != null && self.editorData != ""){
                    this.setData(self.editorData);
                }
            }
            self.editorInstanceReady = true;
        });

        if (this.configData == null || this.configData == undefined) {
            this.loadingService.showLoading(
                true, false, "Topic" + " " + this.translate.instant('LOADING'), 0);

            this.filesService.getEditorXMLByTopicId(this.fileId, this.fileType);

            // if (localStorage.getItem(this.tempSaveKey) == undefined) {
            //     this.filesService.getEditorXMLByTopicId(this.fileId, this.fileType);
            // } else {
            //     setTimeout(() => {
            //         this.loadData(localStorage.getItem(this.tempSaveKey));
            //     }, 2000);
            // }

            // let timer = Observable.timer(2000, 60000);
            // this.timerSubscription = timer.subscribe(t => this.saveTemporary());
        }


        CKEDITOR.on('dialogDefinition', function (ev) {
            let dialogName = ev.data.name;
            let dialogDefinition = ev.data.definition;
            if (dialogName == 'table') {

                // Get the advanced tab reference
                let infoTab2 = dialogDefinition.getContents('advanced');

                // Remove the 'Advanced' tab completely
                dialogDefinition.removeContents('advanced');
                let info = dialogDefinition.getContents('info');

                info.get('txtWidth')['default'] = '100%';       // Set default width to 100%
                info.get('txtBorder')['default'] = '1';         // Set default border to 1
                info.get('txtCellSpace')['default'] = '0';      // Set default cell spacing to 1

                info.remove('txtHeight');   // remove height option
                info.remove('txtCellPad');  // remove cell padding option
                info.remove('cmbAlign');    // reomove table align option


                let def = ev.data.definition;

                def.onShow = function () {
                    let cellSpacing = this.getContentElement('info', 'txtCellSpace');
                    cellSpacing.disable();
                    let tableBorder = this.getContentElement('info', 'txtBorder');
                    tableBorder.disable();
                }
            } else if (dialogName == 'tableProperties') {
                // Get the advanced tab reference
                let infoTab3 = dialogDefinition.getContents('advanced');

                // Remove the 'Advanced' tab completely
                dialogDefinition.removeContents('advanced');
                let propInfo = dialogDefinition.getContents('info');

                propInfo.get('txtWidth')['default'] = '100%';       // Set default width to 100%
                propInfo.get('txtBorder')['default'] = '1';         // Set default border to 1
                propInfo.get('txtCellSpace')['default'] = '0';      // Set default cell spacing to 1

                propInfo.remove('txtHeight');   // remove height option
                propInfo.remove('txtCellPad');  // remove cell padding option
                propInfo.remove('cmbAlign');    // reomove table align option

                // let def1 = ev.data.definition;

                // def1.onShow = function () {
                //     let cellSpacing1 = this.getContentElement('info', 'txtCellSpace');
                //     cellSpacing1.disable();
                //     let tableBorder1 = this.getContentElement('info', 'txtBorder');
                //     tableBorder1.disable();
                // }

            }
            else if (dialogName == 'cellProperties') {

              let cellInfo = dialogDefinition.getContents('info');

                //Remove unwanted cell attributes.
                //cellInfo.remove("widthType");
                cellInfo.remove("htmlHeightType");
                cellInfo.remove("height");
                //cellInfo.remove("width");
                cellInfo.remove("hiddenSpacer");
                cellInfo.remove("cellType");
                cellInfo.remove("rowSpan");
                cellInfo.remove("colSpan");
                cellInfo.remove("wordWrap");

            } else if (dialogName == 'image2') {
                let infoTab = dialogDefinition.getContents('info');
                infoTab.remove('width'); //Remove Element Width From Tab Info
                infoTab.remove('height'); //Remove Element Height From Tab Info
                infoTab.remove('lock'); //Remove Element lock From Tab Info
                infoTab.remove('alt'); //Remove Element altenative text From Tab Info
                infoTab.remove('hasCaption')

                let def = ev.data.definition;

                def.onShow = function () {
                    let src = this.getContentElement('info', 'src');
                    src.disable();
                }
            }
        });

        this.instance.on('key', function(e) {
            if(e.data.keyCode==9){
                let selection = this.getSelection();
                if (selection != null) {
                    let element = selection.getStartElement()
                    if(element != null){
                        for(let parent of element.getParents()){
                            if(parent.getName() == "table"){ //do not indent if cursor is in a table cell
                                return;
                            }
                        }
                    }
                }
                editor.execCommand('indent');
                e.cancel();
            }
            else if(e.data.keyCode==CKEDITOR.SHIFT + 9){
                let selection = this.getSelection();
                if (selection != null) {
                    let element = selection.getStartElement()
                    if(element != null){
                        for(let parent of element.getParents()){
                            if(parent.getName() == "table"){ //do not outdent if cursor is in a table cell
                                return;
                            }
                        }
                    }
                }
                editor.execCommand('outdent');
                e.cancel();
            }
        });
        this.instance.on('contentDom', function () {
            let editable = this.editable();
            editable.attachListener(editable, 'mouseup', function (e) {
                setTimeout(() => {
                    if(self.editorFocused && self.isIpad){
                        self.editorFocused=false;
                        let sx = document.body.scrollLeft, sy = document.body.scrollTop;
                        let keyboardHeight = 310;
                        let toolbarOffsetTop = 0;
                        if(window.innerHeight<window.innerWidth){
                            keyboardHeight = 250;
                            toolbarOffsetTop = 115;
                        }   
                        self.divHeight = (window.innerHeight)-keyboardHeight;
                        self.config.height=SharedService.setTopicEditorHeight()-keyboardHeight;
                        self.instance.resize(self.instance.config.width, self.config.height, true, false);
                        self.instance.getSelection().getStartElement().scrollIntoView(false);
                        setTimeout(() => {
                            window.scrollTo({
                                top: sy+toolbarOffsetTop,
                                left: sy,
                                behavior: "smooth"
                            });
                        }, 100);
                    }
                }, 100);
            });
        });

        this.instance.on('focus', function (e) {
            console.log('[instance.on] (focus) ');
            self.editorFocused=true;
            this.cache = this.getData();
            editor = e.editor;
        });

        this.instance.on('blur', function (e) {
            console.log('[instance.on] (blur) ', e);
            self.editorFocused=false;
            if (this.getSelection() != null) {
                this.range = this.getSelection().getRanges()[0];
            }
            if(self.isIpad){
                self.divHeight = (window.innerHeight)
                self.config.height=SharedService.setTopicEditorHeight();
                self.instance.resize(self.instance.config.width, self.config.height, true, false);
            }
        });

        if (this.configData == null || this.configData == undefined) {
            this.instance.on('paste', function (evt) {
                evt.stop();
                var data: string = evt.data.dataValue;
             
                let selection = this.getSelection();
                let element = selection.getStartElement()
                for(let parent of element.getParents()){
                    if(parent.getName() == "table"){ 

                        data = data.replace(/<table.*?>/gi, '');
                        data = data.replace(/<tbody.*?>/gi, '');
                        data = data.replace(/<tr.*?>/gi, '');
                        data = data.replace(/<td.*?>/gi, '\t');                    
                    }
                }               
                data = data.replace(/<br.*?>/gi, '');

                var xmlStr: string = '<xml>' + data + '</xml>'
                var parser = new DOMParser();
                var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
                var tableEle = xmlDoc.getElementsByTagName("table")[0];
                var xrefEle = xmlDoc.getElementsByTagName("xref")[0];
                if (xrefEle !== undefined) {
                    data = data.replace(/refinsid/gi, "ref_ins");
                }
                if (tableEle !== undefined) {
                    if (data.indexOf("</td></tr></thead>") > -1) {
                        data = data.replace(/<\/thead>/gi, "</tbody>");
                        data = data.replace(/<\/th><\/tr><tr style=""><td/gi, "</th></tr></thead><tbody style=\"\"><tr style=\"\"><td");
                        data = data.replace(/<\/th><\/tr><tr><td/gi, "</th></tr></thead><tbody><tr><td");
                    }
                    data = data.replace(/insid="tbl_.{37}/gi, "table_ins");
                    data = data.replace(/insid="Punchlist_.{37}/gi, "punch_ins");
                    data = data.replace(/insid="MCC_.{37}/gi, "mcc_ins");
                    data = data.replace(/insid="SCC_.{37}/gi, "scc_ins");
                    data = data.replace(/insid="MCCheckSheet_.{37}/gi, "mccsheet_ins");
                    data = data.replace(/insid="MCCSStatus_.{37}/gi, "mccstatus_ins");
                    data = data.replace(/insid="MCCSSummary_.{37}/gi, "mccsum_ins");
                    data = data.replace(/insid="STN_.{37}/gi, "stn_ins");
                    data = data.replace(/insid="SCCheckSheet_.{37}/gi, "sccsheet_ins");
                    data = data.replace(/insid="SCCSStatus_.{37}/gi, "sccstatus_ins");
                    data = data.replace(/insid="SCCSSummary_.{37}/gi, "sccsum_ins");
                    data = data.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insID="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/table_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="tbl_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/punch_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="Punchlist_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/mcc_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="MCC_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/scc_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="SCC_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/mccsheet_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="MCCheckSheet_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/mccstatus_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="MCCSStatus_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/mccsum_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="MCCSSummary_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/stn_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="STN_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/sccsheet_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="SCCheckSheet_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/sccstatus_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="SCCSStatus_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    data = data.replace(/sccsum_ins/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insid="SCCSSummary_' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    if (xrefEle !== undefined) {
                        data = data.replace(/ref_ins/gi, "refinsid");
                    }
                } else {

                    data = data.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    if (xrefEle !== undefined) {
                        data = data.replace(/ref_ins/gi, "refinsid");
                    }
                }
                evt.editor.insertHtml(data);
            });
        } else {
            this.instance.on('paste', function (evt) {
                evt.stop();
                var data: string = evt.data.dataValue;
                var xmlStr: string = '<xml>' + data + '</xml>'
                var parser = new DOMParser();
                var xmlDoc = parser.parseFromString(xmlStr, "text/xml");

                data = data.replace(/tagphid=.{38}/gi, function insId() {
                    function s4() {
                        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                    }
                    return 'tagphid="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                });
                evt.editor.insertHtml(data);
            });
            //}
        }

        this.webEditorService.getCategoryList("Component")
        .subscribe(
            data => {
                this.categoryNameList = []
                if (data.payload) {
                    data.payload.forEach(element => {
                        this.categoryNameList.push({ "categoryId": element.categoryId, 
                        "categoryName": element.categoryName,
                        "categoryType": element.categoryType
                    })
                    });
                    
                } else {
                } 
            },
            error => {
                this.alertService.clear()
                this.alertService.error(error.statusDescription)
            });                          
    }

    ngAfterContentInit() {
        if (this.configData == null || this.configData == undefined) {
            this.insertSubscription = SharedService.insertEditorExecute().subscribe(option => this.insertTagEditArea(option));
            SharedService.setTopicSubscriptions(this.insertSubscription);
            if (this.sharedService.getIsCheckedIn() == true) {
                CKEDITOR.config.readOnly = true;
                this.inlineConfig.readOnly = true;
                this.titleConfig.readOnly = true;
                this.isCheckedIn = true;
                this.sharedService.setViewOnlyStatus(this.fileId, true);
            } else {
                if (this.isCheckOutByOthers) {
                    CKEDITOR.config.readOnly = true;
                    this.inlineConfig.readOnly = true;
                    this.titleConfig.readOnly = true;
                    this.isCheckedIn = true;
                    this.sharedService.setViewOnlyStatus(this.fileId, true);
                } else {
                    if (this.isCheckOutByOthers) {
                        CKEDITOR.config.readOnly = true;
                        this.inlineConfig.readOnly = true;
                        this.titleConfig.readOnly = true;
                        this.isCheckedIn = true;
                        this.sharedService.setViewOnlyStatus(this.fileId, true);
                    } else {
                        CKEDITOR.config.readOnly = false;
                        this.inlineConfig.readOnly = false;
                        this.titleConfig.readOnly = false;
                        this.isCheckedIn = false;
                        this.sharedService.setViewOnlyStatus(this.fileId, true);
                    }

                }
            }
        }
    }

    loadConflictData(payload: any) {
        this.rowData = [];
        for (let i = 0; i < payload.length; i++) {
            this.rowData.push({
                type: payload[i]["type"],
                newVal: payload[i]["isDeleted"] == "false" ? payload[i]["newValue"] : "DELETED",
                lastVal: payload[i]["lastValue"],
                id: payload[i]["id"],
                name: payload[i]["name"],
                lastUpdateTimeStamp: payload[i]["lastUpdateTimeStamp"],
                isDeleted: payload[i]["isDeleted"]

            })
        }
        for (let x = 0; x < this.rowData.length; x++) {
            if (this.rowData[x].type == "Statement") {
                this.rowData.push(this.rowData.splice(x, 1)[0]);
            }
        }
    }

    setLoaded() {

    }

    selectedCategory(params) {
        //this.commissioning();
        this.punchlistsSelectedCatogory = params.text;
    }

    selectedPunchlistStatus(params) {
        this.punchlistSelectedStatus = params.text;
    }

    selectedType(params) {
        //this.commissioning();
        this.checksheetSelectedType = params.text;
        //console.log("selectedType");
    }


    private getSymbolFromDecimal(value: number): string {
        if ((33 <= value) && (value < 65536)) {
            return String.fromCharCode(value);
        } else {
            this.alertService.error("Please enter valid Unicode character entity reference. Range is between 33 to 65535", true);
        }

    }

    setTagEditor() {
        $('.toggle-tagedit').click(function (e) {
            var name = $(this)[0].title.split(" ")[3];
            tag_editor = CKEDITOR.instances[name];
        });
    }

    setTDWidth() {
        if (this.instance != undefined) {
            let tdElements = this.instance.document.getElementsByTag('td');
            let i;
            for (i = 0; i < tdElements.count(); i++) {
                let item = tdElements.getItem(i);;
                item.setAttribute('tdwidth', item.getSize("width"));
            }

            //Assign th width
            let thElements = this.instance.document.getElementsByTag('th');
            let j;
            for (j = 0; j < thElements.count(); j++) {
                let tdItem = thElements.getItem(j);
                tdItem.setAttribute('thwidth', tdItem.getSize("width"));
            }

            //Assign table widths
            let tableElements = this.instance.document.getElementsByTag('table');
            let k;
            for (k = 0; k < tableElements.count(); k++) {
                let tableItem = tableElements.getItem(k);
                tableItem.setAttribute('tablewidth', tableItem.getSize("width") - 2);
            }
        }
    }

    setEditor() {
        var self = this;
        $('.toggle-edit').click(function (e) {
            var name = $(this)[0].title.split(" ")[3];
            editor = CKEDITOR.instances[name];

            editor.on('paste', function (evt) {
                evt.stop();
                var data: string = evt.data.dataValue;
                var xmlStr: string = '<xml>' + data + '</xml>'
                var parser = new DOMParser();
                var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
                var tableEle = xmlDoc.getElementsByTagName("table")[0];
                if (tableEle !== undefined) {
                    //this.alertService.error(this.translate.instant('COULDNT_PASTE_TABLES_TO_TOPIC_HEADER'), true);
                    window.alert("Couldn't paste tables to topic header");
                } else {
                    data = data.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                    evt.editor.insertHtml(data);
                }
            });


            editor.on('change', () => {
                self.sharedService.setViewOnlyStatus(self.fileId, false);
            });
            
            editor.on('doubleclick', function (e) {
                if (editor != undefined && editor != null) {
                    self.selection = editor.getSelection();
                    if (self.selection != null) {
                        self.ranges = self.selection.getRanges();
                    }
                    if (self.ranges != null) {
                        let range = self.ranges[0];
                        if (range != null) {
                            range = range.clone();
                            var startNode = range.startContainer.$;
                            var ancestor = startNode.parentNode;
                            if (ancestor != null && ancestor.nodeName == 'PH') {
                                let attr = ancestor.attributes;
                                if (attr.length != 0) {
                                    self.getRestrictionValue(ancestor);
                                    self.fireEvent("navbarSupportedContent", "click");
                                }
                            }
                        }
                    }
                }
            });
        });
    }

    updateValue() {

    }

    onRowSelected(event) {
    }


    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        var slecWidget = ""
        var sleId = ""
        this.selectedRowsString = "";
        this.selectedWidget = "";

        selectedRows.forEach(function (selectedRow, i) {
            slecWidget = selectedRow.widget;
            sleId += selectedRow.id + ',';
        });
        this.selectedWidget = slecWidget;
        this.selectedRowsString = sleId;
    }


    getVendors() {
        this.webEditorService.getVendors()
            .subscribe(
                data => {
                    this.vendorList = []
                    if (data.payload) {
                        data.payload.forEach(element => {
                            this.vendorList.push({ "id": element.vendorId, "text": element.vendorName })
                        });
                    } else {
                    }
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
    }

    selectedVendor(params) {
        this.vendorId = params.id;
        this.vendorSelectedType.push(params.id);
    }

    removedVendor(params) {
        const index = this.vendorSelectedType.indexOf(params.id, 0)
        if (index > -1) {
            this.vendorSelectedType.splice(index, 1);
        }
    }


    private editorKeyDown(e: any) {
        this.tagContent = '';
        if (editor != undefined) {
            this.selection = editor.getSelection();
        }
        if (this.selection != null) {
            this.ranges = this.selection.getRanges();
        }
        if (this.ranges != null) {
            let range = this.ranges[0];
            if (range != null) {
                range = range.clone();
                var startNode = range.startContainer.$;
                var ancestor = startNode.parentNode;
				if(startNode.nodeName == 'EDITAREA'){
					ancestor=startNode;
                }
                if (this.isFirefox) {
                    if (ancestor != null) {
                        if (ancestor.nodeName == 'PH') {
                            if (this.checkPrintableKeys(e)) {
                                let attr = ancestor.attributes;
                                if (attr.length != 0) {
                                   // this.getRestrictionValue(ancestor);
                                   // this.fireEvent("navbarSupportedContent", "click");
                                }
                                let restriction = ancestor.attributes['restriction'].value;
                                let tagPHId = ancestor.attributes['tagPHId'].value;
                                let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
                                let text = ancestor.textContent;
                                this.guidWinT = this.guid().toUpperCase();
                                this.tagContent = '<tags insID="' + this.guid().toUpperCase() +
                                    '" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '">' +
                                    '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
                                    '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                this.setOuterHTML(ancestor, this.tagContent);
                                editor.document.getById(this.guidWinT).focus();
                            }
                        } else if (ancestor.nodeName == 'EDITAREA') {
                            if (this.checkPrintableKeys(e)) {
                                let prevNode = $(ancestor).prev()[0];
                                let nextNode = $(ancestor).next()[0];
                                if (prevNode != undefined && prevNode.nodeName == 'WINTITLE') {
                                    let text = prevNode.textContent;
                                    //prevNode.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
                                } else if (nextNode != undefined && nextNode.nodeName == 'WINTITLE') {
                                    let text = nextNode.textContent;
                                    //nextNode.textContent = ' ' + e.data.domEvent.$.key + text.slice(1, text.length);
                                } else {
                                    this.guidWinT = this.guid().toUpperCase();
                                    this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="'+this.guidWinT+'" id="'+this.guidWinT+'"></wintitle>' +
                                        '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                    ancestor.outerHTML = this.tagContent;
                                    editor.document.getById(this.guidWinT).focus();
                                }
                            }
                        } else if (ancestor.nodeName == 'WINTITLE') {
                            ancestor.setAttribute("contenteditable", "true");
                        } else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'
                            || ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I' ) {
                                if (e.data.keyCode == 8 || e.data.keyCode == 46) {
                                    while(ancestor.parentNode != undefined){
                                        if(ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'){
                                            
                                            if(this.isStatementParent(ancestor)){
                                                this.setOuterHTML(ancestor, "");
                                            }
                                            break;
                                            
                                        }else{
                                            ancestor = ancestor.parentNode;
                                        }
                                    }
                                // if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION') {
                                //     if (this.isStatementParent(ancestor)) {
                                //         this.setOuterHTML(ancestor, "");
                                //     }
                                // } else if (ancestor.nodeName == 'FLD') {
                                //     if (ancestor.parentNode != undefined) {
                                //         var tagsNode = ancestor.parentNode;
                                //         if (this.isStatementParent(tagsNode)) {
                                //             this.setOuterHTML(tagsNode, "");
                                //         }
                                //     }
                                // } else if (ancestor.nodeName == 'TAGDESC') {
                                //     if (ancestor.parentNode.parentNode != undefined) {
                                //         var tagsNode = ancestor.parentNode.parentNode;
                                //         if (this.isStatementParent(tagsNode)) {
                                //             this.setOuterHTML(tagsNode, "");
                                //         }
                                //     }
                                // } else if (ancestor.nodeName == 'TXT') {
                                //     if (ancestor.parentNode.nodeName != 'STATEMENT' || ancestor.parentNode.nodeName != 'PARA' || ancestor.parentNode.nodeName != 'LI') {
                                //         if (ancestor.parentNode.parentNode.parentNode != undefined) {
                                //             var tagsNode = ancestor.parentNode.parentNode.parentNode;
                                //             if (tagsNode.nodeName == "TAG" && this.isStatementParent(tagsNode)) {
                                //                 this.setOuterHTML(tagsNode, "");
                                //             }
                                //         }
                                //     }
                                //}
                            }
                        } else {
                            if (startNode != null && (startNode.nodeName == 'STATEMENT' || startNode.nodeName == 'TAGS' || startNode.nodeName == 'PARA' || startNode.nodeName == 'LI')) {
                                if (this.checkPrintableKeys(e)) {
                                    if (editor.document.getById(this.guidWinT != null)) {
                                        let wintile = editor.document.getById(this.guidWinT).$;
                                        let text = wintile.textContent;
                                        wintile.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
                                    }
                                }
                            }
                        }
                    } else {
                        this.alertService.clear();
                        this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                    }
                } else {
                    if (ancestor != null) {
                        if (ancestor.nodeName == 'PH') {
                            if (this.checkPrintableKeys(e)) {
                                let attr = ancestor.attributes;
                                if (attr.length != 0) {
                                   // this.getRestrictionValue(ancestor);
                                    //this.fireEvent("navbarSupportedContent", "click");
                                }
                                let restriction = ancestor.attributes['restriction'].value;
                                let tagPHId = ancestor.attributes['tagPHId'].value;
                                let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
                                let text = ancestor.textContent;
                                this.guidWinT = this.guid().toUpperCase();
                                this.tagContent = '<tags insID="' + this.guid().toUpperCase() +
                                    '" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '">' +
                                    '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
                                    '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                this.setOuterHTML(ancestor, this.tagContent);
                                editor.document.getById(this.guidWinT).focus();
                            }
                        } else if (ancestor.nodeName == 'EDITAREA') {
                            if (this.checkPrintableKeys(e)) {
                                let prevNode = $(ancestor).prev()[0];
                                let nextNode = $(ancestor).next()[0];
                                if (prevNode != undefined && prevNode.nodeName == 'WINTITLE') {
                                    let text = prevNode.textContent;
                                    //prevNode.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
                                } else if (nextNode != undefined && nextNode.nodeName == 'WINTITLE') {
                                    let text = nextNode.textContent;
                                    //nextNode.textContent = ' ' + e.data.domEvent.$.key + text.slice(1, text.length);
                                } else {
                                    this.guidWinT = this.guid().toUpperCase();
                                    this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
                                        '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                    this.setOuterHTML(ancestor, this.tagContent);
                                    editor.document.getById(this.guidWinT).focus();
                                }
                            }
                        } else if (ancestor.nodeName == 'WINTITLE') {
                            ancestor.setAttribute("contenteditable", "true");
                        } else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'
                                    || ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I' ) {
                            if (e.data.keyCode == 8 || e.data.keyCode == 46) {
                                while(ancestor.parentNode != undefined){
                                    if(ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'){
                                        
                                        if(this.isStatementParent(ancestor)){
                                            this.setOuterHTML(ancestor, "");
                                        }
                                        break;

                                    }else{
                                        ancestor = ancestor.parentNode;
                                    }
                                }
                                // if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION') {
                                //     if (this.isStatementParent(ancestor)) {
                                //         this.setOuterHTML(ancestor, "");
                                //     }
                                // } else if (ancestor.nodeName == 'FLD') {
                                //     if (ancestor.parentNode != undefined) {
                                //         var tagsNode = ancestor.parentNode;
                                //         if (this.isStatementParent(tagsNode)) {
                                //             this.setOuterHTML(tagsNode, "");
                                //         }
                                //     }
                                // } else if (ancestor.nodeName == 'TAGDESC') {
                                //     if (ancestor.parentNode.parentNode != undefined) {
                                //         var tagsNode = ancestor.parentNode.parentNode;
                                //         if (this.isStatementParent(tagsNode)) {
                                //             this.setOuterHTML(tagsNode, "");
                                //         }
                                //     }
                                // } else if (ancestor.nodeName == 'TXT') {
                                //     if (ancestor.parentNode.nodeName != 'STATEMENT' || ancestor.parentNode.nodeName != 'PARA' || ancestor.parentNode.nodeName != 'LI') {
                                //         if (ancestor.parentNode.parentNode.parentNode != undefined) {
                                //             var tagsNode = ancestor.parentNode.parentNode.parentNode;
                                //             if (tagsNode.nodeName == "TAG" && this.isStatementParent(tagsNode)) {
                                //                 this.setOuterHTML(tagsNode, "");
                                //             }
                                //         }
                                //     }
                                // }
                            }
                        } else {
                            if (startNode != null && (startNode.nodeName == 'STATEMENT' || startNode.nodeName == 'TAGS' || startNode.nodeName == 'PARA' || startNode.nodeName == 'LI')) {
                                if (this.checkPrintableKeys(e)) {
                                    if (editor.document.getById(this.guidWinT != null)) {
                                        let wintile = editor.document.getById(this.guidWinT).$;
                                        let text = wintile.textContent;
                                        wintile.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
                                    }
                                }
                            }
                        }
                    } else {
                        this.alertService.clear();
                        this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                    }
                }
            }
        }
    }

    private setOuterHTML(node: any, content: string) {
        if (node.parentNode != undefined) {
            node.outerHTML = content;
        } else {
            this.alertService.clear();
            this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
        }
    }

    private checkPrintableKeys(e: any): boolean {
        let printable: boolean = false;
        let keycode = e.data.domEvent.$.keyCode;
        printable = keycode == 32 ||
            (keycode > 47 && keycode < 58) ||
            (keycode > 64 && keycode < 91) ||
            (keycode > 95 && keycode < 112) ||
            (keycode > 185 && keycode < 193) ||
            (keycode > 218 && keycode < 223);
        return printable;
    }

    private getRestrictionValue(item: any) {
        var res = item.attributes['restriction']
        let compCategoryId = "";
        if (res != undefined) {
            var restriction = item.attributes['restriction'].value;
            if (restriction == "doc") {
                restriction = "documents";
            } else if (restriction == "com") {
                restriction = "components";
                //let compCategoryId = "";
                if (item.attributes['catid'] != undefined) {
                    compCategoryId = item.attributes['catid'].value;
                }
                if (compCategoryId != null && compCategoryId != "") {                    
                    for (let i = 0; i < this.categoryNameList.length; i++) {                        
                        if (this.categoryNameList[i].categoryId == compCategoryId){
                            this.categoryName = this.categoryNameList[i].categoryName;
                            break;
                        }                         
                    }                                      
                }        
                else {}      
            } else if (restriction == "tag") {
                restriction = "tags";
            } else if (restriction == "all") {
                restriction = "tags";
            } else if (restriction == "sys") {
                restriction = "systems";
            }
            SharedService.setStatementHtmlString(restriction);
            SharedService.openReusablepanel(restriction);  
            setTimeout(() => {                
                if (compCategoryId != null && compCategoryId != "") {                              
                    SharedService.searchComponentEventEmit(this.categoryName);   
                } 
                else if ((compCategoryId == null || compCategoryId == "") && restriction == "components"){
                    SharedService.searchComponentEventEmit("");  
                }   
        }, 3000); 
        setTimeout(() => {
            $('input[name=symbiosis_search]').focus();
        }, 500); 
        
        }
    }

     /**
     * Load html to CKEditor after conflictckecker update.
     * @param payload  
     */
    loadDataConflictChecker(payload: any) {
        if (payload != null && payload != "") {
            console.log("valid payload");
            var parser = new DOMParser();
            var topic = parser.parseFromString(payload, "text/xml");
            var topicTitle = topic.getElementsByTagName("header")[0];
            var topicContent = topic.getElementsByTagName("xml")[0];
            var serializedTopicContent = new XMLSerializer().serializeToString(topicContent);
            console.log("create serializedTopicContent");
            var serializedTopictitle = new XMLSerializer().serializeToString(topicTitle);
            console.log("create serializedTopictitle");
            if (serializedTopicContent != null) {
                console.log("serializedTopicContent is not null");
                var str = '<xml id="' + this.fileId + '">';
                var removeXML = serializedTopicContent.substring(
                    serializedTopicContent.lastIndexOf(str) + str.length,
                    serializedTopicContent.lastIndexOf('</xml>')
                );
                console.log("create removeXML");
                let addEditAreaXML = removeXML.replace(/<editarea[\s]+([^>]+)\/>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                let addEditAreaXMLConflict = addEditAreaXML.replace(/<editarea[\s]+([^>]+)>[\s]+\<\/editarea>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                addEditAreaXMLConflict = addEditAreaXMLConflict.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                        "<wintitle contenteditable='true'$2</wintitle>");
                console.log("create addEditAreaXML");
                var removeComXML = addEditAreaXMLConflict.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                removeComXML = removeComXML.replace(/<\/(tags|xref|statement)><\/p>/g, "</$1>&#8203</p>");
                console.log("create removeComXML");
                if (this.instance.getCommand('checkout') !== undefined) {
                    console.log("checkout is not undefined");
                    setTimeout(() => {
                        if (this.isCheckedIn == true) {
                            console.log("checkin is true");
                            if (this.isCheckOutByOthers) {
                                console.log("checkout by others true");
                                this.instance.getCommand('checkout').disable();
                            } else {
                                console.log("checkout by others false");
                                this.instance.getCommand('checkout').enable();
                            }
                        } else {
                            console.log("checkin is false");
                            this.instance.getCommand('checkout').disable();
                        }
                        console.log(removeComXML);
                        this.instance.setData(removeComXML);
                        this.editorData = removeComXML;
                        console.log("set removeComXML");

                    }, 750);
                    if (serializedTopictitle != null) {
                        console.log(" serializedTopictitle is not null");
                        var removeHeader = serializedTopictitle.substring(
                            serializedTopictitle.lastIndexOf("<header>") + 8,
                            serializedTopictitle.lastIndexOf('</header>')
                        );
                        console.log("create removeHeader");
                        let addEditAreaHeaderXML = removeHeader.replace(/<editarea[\s]+([^>]+)\/>/g,
                            "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                        addEditAreaHeaderXML = addEditAreaHeaderXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                                "<wintitle contenteditable='true'$2</wintitle>");
                        console.log("create addEditAreaHeaderXML");
                        let removeComTitle = addEditAreaHeaderXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                        console.log("create removeComTitle");
                        if (this.titleEditor !== undefined) {
                            console.log(" titleEditor is not undefined");
                            setTimeout(() => {
                                if (this.isCheckedIn == true) {
                                    console.log("checkin is true");
                                    if (this.isCheckOutByOthers) {
                                        console.log("checkout by others true");
                                        this.titleEditor.getCommand('checkout').disable();
                                    } else {
                                        console.log("checkout by others false");
                                        this.titleEditor.getCommand('checkout').enable();
                                    }
                                } else {
                                    console.log("checkin is false");
                                    this.titleEditor.getCommand('checkout').disable();
                                }
                                this.titleEditor.setData(removeComTitle);
                                console.log("set removeComTitle");
                                // this.loadingService.hideLoading();
                            }, 750);
                        } else {
                            console.log(" titleEditor is  undefined");
                            setTimeout(() => {
                                if (this.isCheckedIn == true) {
                                    console.log("checkin is true");
                                    if (this.isCheckOutByOthers) {
                                        console.log("checkout by others true");
                                        this.titleEditor.getCommand('checkout').disable();
                                    } else {
                                        console.log("checkout by others false");
                                        this.titleEditor.getCommand('checkout').enable();
                                    }
                                } else {
                                    console.log("checkin is false");
                                    this.titleEditor.getCommand('checkout').disable();
                                }
                                this.titleEditor.setData(removeComTitle);
                                console.log("set removeComTitle");
                            }, 500);
                        }
                    }
                    setTimeout(() => {
                        console.log("hideLoading icon");
                        this.loadingService.hideLoading();
                        this.isLoadedEditor = true;
                    }, 4000);
                } else {
                    console.log("checkout is undefined");
                    setTimeout(() => {
                        if (this.isCheckedIn == true) {
                            console.log("checkin is true");
                            if (this.isCheckOutByOthers) {
                                console.log("checkout by others true");
                                this.instance.getCommand('checkout').disable();
                            } else {
                                console.log("checkout by others false");
                                this.instance.getCommand('checkout').enable();
                            }
                        } else {
                            console.log("checkin is false");
                            this.instance.getCommand('checkout').disable();
                        }
                        console.log(removeComXML);
                        this.instance.setData(removeComXML);
                        this.editorData = removeComXML;
                        console.log("set removeComXML");
                        setTimeout(() => {
                            this.isLoadedEditor = true;
                            console.log("isLoadedEditor is true");
                        }, 2000);

                        if (serializedTopictitle != null) {
                            console.log("serializedTopictitle is not null");
                            var removeHeader = serializedTopictitle.substring(
                                serializedTopictitle.lastIndexOf("<header>") + 8,
                                serializedTopictitle.lastIndexOf('</header>')
                            );
                            console.log("create removeHeader");
                            let addEditAreaHeaderXML = removeHeader.replace(/<editarea[\s]+([^>]+)\/>/g,
                                "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                            addEditAreaHeaderXML = addEditAreaHeaderXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                                "<wintitle contenteditable='true'$2</wintitle>");
                            console.log("create addEditAreaHeaderXML");
                            let removeComTitle = addEditAreaHeaderXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                            console.log("create removeComTitle");
                            if (this.titleEditor !== undefined) {
                                console.log("titleEditor is not undefined");
                                setTimeout(() => {
                                    if (this.isCheckedIn == true) {
                                        console.log("checkin true");
                                        if (this.isCheckOutByOthers) {
                                            console.log("CheckOutByOthers true");
                                            this.titleEditor.getCommand('checkout').disable();
                                        } else {
                                            console.log("CheckOutByOthers false");
                                            this.titleEditor.getCommand('checkout').enable();
                                        }
                                    } else {
                                        console.log("checkin false");
                                        this.titleEditor.getCommand('checkout').disable();
                                    }
                                    this.titleEditor.setData(removeComTitle);
                                    console.log("set removeComTitle");
                                    // this.loadingService.hideLoading();
                                }, 750);
                            } else {
                                console.log("titleEditor is  undefined");
                                setTimeout(() => {
                                    if (this.isCheckedIn == true) {
                                        console.log("checkin true");
                                        if (this.isCheckOutByOthers) {
                                            console.log("CheckOutByOthers true");
                                            this.titleEditor.getCommand('checkout').disable();
                                        } else {
                                            console.log("CheckOutByOthers false");
                                            this.titleEditor.getCommand('checkout').enable();
                                        }
                                    } else {
                                        console.log("checkin false");
                                        this.titleEditor.getCommand('checkout').disable();
                                    }
                                    this.titleEditor.setData(removeComTitle);
                                    console.log("set removeComTitle");
                                    // setTimeout(() => {
                                    //     this.loadingService.hideLoading();
                                    // }, 2000);
                                }, 2000);
                            }
                        }

                    }, 7000);

                    setTimeout(() => {
                        console.log("hideLoading icon");
                        this.loadingService.hideLoading();
                    }, 7000);

                }
            }else{
                console.log("serializedTopicContent is  null");
            }

        } else {
            console.log("payload is not valid");
            this.alertService.error(this.translate.instant('COULDNT_LOAD') + this.fileType, true);
            this.sharedService.activeTabCloseRequest();
            this.loadingService.hideLoading();
        }

    }


    /**
     * Load html to CKEditor.
     * @param payload  
     */
    loadData(payload: any) {
        if (payload != null && payload != "") {
            console.log("valid payload");
            var parser = new DOMParser();
            var topic = parser.parseFromString(payload, "text/xml");
            var topicTitle = topic.getElementsByTagName("header")[0];
            var topicContent = topic.getElementsByTagName("xml")[0];
            var serializedTopicContent = new XMLSerializer().serializeToString(topicContent);
            console.log("create serializedTopicContent");
            var serializedTopictitle = new XMLSerializer().serializeToString(topicTitle);
            console.log("create serializedTopictitle");
            if (serializedTopicContent != null) {
                console.log("serializedTopicContent is not null");
                var str = '<xml id="' + this.fileId + '">';
                var removeXML = serializedTopicContent.substring(
                    serializedTopicContent.lastIndexOf(str) + str.length,
                    serializedTopicContent.lastIndexOf('</xml>')
                );
                console.log("create removeXML");
                let addEditAreaXML = removeXML.replace(/<editarea[\s]+([^>]+)\/>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                addEditAreaXML = addEditAreaXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                    "<wintitle contenteditable='true'$2</wintitle>");
                console.log("create addEditAreaXML");
                var removeComXML = addEditAreaXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                removeComXML = removeComXML.replace(/<\/(tags|xref|statement)><\/p>/g, "</$1>&#8203</p>");
                console.log("create removeComXML");
                if (this.instance.getCommand('checkout') !== undefined) {
                    console.log("checkout is not undefined");
                    setTimeout(() => {
                        // if (this.isCheckedIn == true) {
                        //     console.log("checkin is true");
                        //     if (this.isCheckOutByOthers) {
                        //         console.log("checkout by others true");
                        //         this.instance.getCommand('checkout').disable();
                        //     } else {
                        //         console.log("checkout by others false");
                        //         this.instance.getCommand('checkout').enable();
                        //     }
                        // } else {
                        //     console.log("checkin is false");
                        //     this.instance.getCommand('checkout').disable();
                        // }
                        console.log(removeComXML);
                        this.instance.setData(removeComXML);
                        this.editorData = removeComXML;
                        console.log("set removeComXML");

                    }, 750);
                    if (serializedTopictitle != null) {
                        console.log(" serializedTopictitle is not null");
                        var removeHeader = serializedTopictitle.substring(
                            serializedTopictitle.lastIndexOf("<header>") + 8,
                            serializedTopictitle.lastIndexOf('</header>')
                        );
                        console.log("create removeHeader");
                        let addEditAreaHeaderXML = removeHeader.replace(/<editarea[\s]+([^>]+)\/>/g,
                            "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                        addEditAreaHeaderXML = addEditAreaHeaderXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                            "<wintitle contenteditable='true'$2</wintitle>");
                        console.log("create addEditAreaHeaderXML");
                        let removeComTitle = addEditAreaHeaderXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                        console.log("create removeComTitle");
                        if (this.titleEditor !== undefined) {
                            console.log(" titleEditor is not undefined");
                            setTimeout(() => {
                                // if (this.isCheckedIn == true) {
                                //     console.log("checkin is true");
                                //     if (this.isCheckOutByOthers) {
                                //         console.log("checkout by others true");
                                //         this.titleEditor.getCommand('checkout').disable();
                                //     } else {
                                //         console.log("checkout by others false");
                                //         this.titleEditor.getCommand('checkout').enable();
                                //     }
                                // } else {
                                //     console.log("checkin is false");
                                //     this.titleEditor.getCommand('checkout').disable();
                                // }
                                this.titleEditor.setData(removeComTitle);
                                this.titleEditorData = removeComTitle;
                                console.log("set removeComTitle");
                                // this.loadingService.hideLoading();
                            }, 750);
                        } else {
                            console.log(" titleEditor is  undefined");
                            setTimeout(() => {
                                // if (this.isCheckedIn == true) {
                                //     console.log("checkin is true");
                                //     if (this.isCheckOutByOthers) {
                                //         console.log("checkout by others true");
                                //         this.titleEditor.getCommand('checkout').disable();
                                //     } else {
                                //         console.log("checkout by others false");
                                //         this.titleEditor.getCommand('checkout').enable();
                                //     }
                                // } else {
                                //     console.log("checkin is false");
                                //     this.titleEditor.getCommand('checkout').disable();
                                // }
                                this.titleEditor.setData(removeComTitle);
                                this.titleEditorData = removeComTitle;
                                console.log("set removeComTitle");
                            }, 500);
                        }
                    }
                    setTimeout(() => {
                        console.log("hideLoading icon");
                        this.loadingService.hideLoading();
                        this.isLoadedEditor = true;
                    }, 4000);
                } else {
                    console.log("checkout is undefined");
                    setTimeout(() => {
                        // if (this.isCheckedIn == true) {
                        //     console.log("checkin is true");
                        //     if (this.isCheckOutByOthers) {
                        //         console.log("checkout by others true");
                        //         this.instance.getCommand('checkout').disable();
                        //     } else {
                        //         console.log("checkout by others false");
                        //         this.instance.getCommand('checkout').enable();
                        //     }
                        // } else {
                        //     console.log("checkin is false");
                        //     this.instance.getCommand('checkout').disable();
                        // }
                        console.log(removeComXML);
                        if(this.editorInstanceReady){
                            this.instance.setData(removeComXML);
                        }
                        this.editorData = removeComXML;
                        console.log("set removeComXML");
                        setTimeout(() => {
                            this.isLoadedEditor = true;
                            console.log("isLoadedEditor is true");
                        }, 2000);

                        if (serializedTopictitle != null) {
                            console.log("serializedTopictitle is not null");
                            var removeHeader = serializedTopictitle.substring(
                                serializedTopictitle.lastIndexOf("<header>") + 8,
                                serializedTopictitle.lastIndexOf('</header>')
                            );
                            console.log("create removeHeader");
                            let addEditAreaHeaderXML = removeHeader.replace(/<editarea[\s]+([^>]+)\/>/g,
                                "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                            addEditAreaHeaderXML = addEditAreaHeaderXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                                "<wintitle contenteditable='true'$2</wintitle>");
                            console.log("create addEditAreaHeaderXML");
                            let removeComTitle = addEditAreaHeaderXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                            console.log("create removeComTitle");
                            if (this.titleEditor !== undefined) {
                                console.log("titleEditor is not undefined");
                                setTimeout(() => {
                                    // if (this.isCheckedIn == true) {
                                    //     console.log("checkin true");
                                    //     if (this.isCheckOutByOthers) {
                                    //         console.log("CheckOutByOthers true");
                                    //         this.titleEditor.getCommand('checkout').disable();
                                    //     } else {
                                    //         console.log("CheckOutByOthers false");
                                    //         this.titleEditor.getCommand('checkout').enable();
                                    //     }
                                    // } else {
                                    //     console.log("checkin false");
                                    //     this.titleEditor.getCommand('checkout').disable();
                                    // }
                                    if(this.titleEditorInstanceReady){
                                        this.titleEditor.setData(removeComTitle);
                                    }
                                    this.titleEditorData = removeComTitle;
                                    console.log("set removeComTitle");
                                    // this.loadingService.hideLoading();
                                }, 750);
                            } else {
                                console.log("titleEditor is  undefined");
                                setTimeout(() => {
                                    // if (this.isCheckedIn == true) {
                                    //     console.log("checkin true");
                                    //     if (this.isCheckOutByOthers) {
                                    //         console.log("CheckOutByOthers true");
                                    //         this.titleEditor.getCommand('checkout').disable();
                                    //     } else {
                                    //         console.log("CheckOutByOthers false");
                                    //         this.titleEditor.getCommand('checkout').enable();
                                    //     }
                                    // } else {
                                    //     console.log("checkin false");
                                    //     this.titleEditor.getCommand('checkout').disable();
                                    // }
                                    if(this.titleEditorInstanceReady){
                                        this.titleEditor.setData(removeComTitle);
                                    }
                                    this.titleEditorData = removeComTitle;
                                    console.log("set removeComTitle");
                                    // setTimeout(() => {
                                    //     this.loadingService.hideLoading();
                                    // }, 2000);
                                }, 2000);
                            }
                        }

                    }, 7000);

                    setTimeout(() => {
                        console.log("hideLoading icon");
                        this.loadingService.hideLoading();
                    }, 7000);

                }
            }else{
                console.log("serializedTopicContent is  null");
            }

        } else {
            console.log("payload is not valid");
            this.alertService.error(this.translate.instant('COULDNT_LOAD') + this.fileType, true);
            this.sharedService.activeTabCloseRequest();
            this.loadingService.hideLoading();
        }

    }

    /**
     * Success function for editor view.
     * @param data 
     * @param serviceType 
     */
    onSuccess(data: WsResponse, serviceType: WsType): void {
        if (data != null && serviceType == WsType.FILE_EDITOR_VIEW) {
            this.userId = data.payload.checkoutUserId;
            this.loadData(data.payload.htmlContent);
            // if (!this.isCheckedIn) {
            //     //localStorage.setItem(this.tempSaveKey, data.payload.htmlContent);
            //     this.loadData(data.payload.htmlContent);
            // } else {
            //     this.loadData(data.payload.htmlContent);
            // }
        } else if (data == null && serviceType == WsType.FILE_EDITOR_VIEW) {
            this.alertService.error(this.translate.instant('COULDNT_LOAD') + this.fileType, true);
            this.loadingService.hideLoading();
            // } else if (data != null && serviceType == WsType.CHECKIN_DOCUMENT_EDITOR) {
            //     this.instance.setReadOnly("true");
            //     this.titleEditor.setReadOnly("true");
            //     localStorage.removeItem(this.tempSaveKey);
            //     // $("#webeditor_checkin").prop('disabled', true);
            //     // $("#webeditor_save").prop('disabled', true);
            //     // $("#webeditor_undocheckout").prop('disabled', true);
            //     this.alertService.success(this.fileType + " " + this.translate.instant('WEB_EDITOR.COMMITTED SUCCESSFULLY'), true);
            // } else if (data == null && serviceType == WsType.CHECKIN_DOCUMENT_EDITOR) {
            //     this.alertService.error(this.translate.instant('WEB_EDITOR.CHECKIN_ERROR') + this.fileType, true);
        } else if (data != null && serviceType == WsType.SAVE_DOCUMENT_EDITOR) {
            this.alertService.success("Topic " + this.translate.instant('WEB_EDITOR.SAVED_SUCCESSFULLY'), true);
            this.sharedService.setViewOnlyStatus(this.fileId, true);
            this.loadingService.hideLoading();
        } else if (data == null && serviceType == WsType.SAVE_DOCUMENT_EDITOR) {
            this.alertService.error(this.translate.instant('WEB_EDITOR.SAVE_ERROR') + this.fileType, true);
        } else if (data != null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
            this.instance.setReadOnly(true);
            this.titleEditor.setReadOnly(true);
            $("#webeditor_checkin").prop('disabled', true);
            $("#webeditor_save").prop('disabled', true);
            $("#webeditor_undocheckout").prop('disabled', true);
            this.loadingService.hideLoading();
        } else if (data == null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
            this.alertService.error(this.fileType + this.translate.instant('WEB_EDITOR.UNDO_CHECK_OUT_ERROR'), true);
            this.loadingService.hideLoading();
        }
    }
    /**
     * On fail function for editor view.
     * @param data 
     * @param serviceType
     */
    onFail(data: WsResponse, serviceType: WsType): void {
        this.loadingService.hideLoading();
        if (data != null && serviceType == WsType.FILE_EDITOR_VIEW) {
            this.alertService.error(data.statusDescription, true);
        } else if (data == null && serviceType == WsType.FILE_EDITOR_VIEW) {
            this.alertService.error(this.translate.instant('COULDNT_LOAD') + this.fileType, true);
        } else if (data != null && serviceType == WsType.CHECKIN_DOCUMENT_EDITOR) {
            this.alertService.error(data.statusDescription, true);
        } else if (data == null && serviceType == WsType.CHECKIN_DOCUMENT_EDITOR) {

            this.alertService.error(this.translate.instant('WEB_EDITOR.CHECKIN_ERROR') + this.fileType, true);;
        } else if (data != null && serviceType == WsType.SAVE_DOCUMENT_EDITOR) {
            if (data.statusCode == '543') {
                this.loadConflictData(data.payload)
                this.openModalCC(this.conflict_checker);
            } else if (data.statusCode == "0") {
                this.alertService.clear();
                this.alertService.error(this.translate.instant('WEB_EDITOR.CONNECTION_LOST'), true);
                this.loadingService.hideLoading();
            } else {
                if(data.statusName == "TimeoutError"){
                    if(!navigator.onLine){
                        this.alertService.clear();
                        this.alertService.error(this.translate.instant('WEB_EDITOR.CONNECTION_LOST'), true);
                        this.loadingService.hideLoading();
                        return;
                    }
                    else{
                        this.alertService.clear();
                        this.alertService.error(this.translate.instant('WEB_EDITOR.SAVE_TIMEOUT'), true);
                        this.loadingService.hideLoading();
                        return;
                    }
                }
                if(data.statusDescription == "Topic not Checked Out"){
                    this.instance.setReadOnly(true);
                    this.titleEditor.setReadOnly(true);
                    this.instance.getCommand('checkout').enable();
                    this.titleEditor.getCommand('checkout').enable();
                }
                this.alertService.error(data.statusDescription, true);
            }
        } else if (data == null && serviceType == WsType.SAVE_DOCUMENT_EDITOR) {
            this.alertService.error(this.translate.instant('WEB_EDITOR.CHECKIN_ERROR') + this.fileType, true);;
        } else if (data != null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
            this.alertService.error(data.statusDescription, true);
        } else if (data == null && serviceType == WsType.CM_SYSTEM_UNDO_CHECKOUT) {
            this.alertService.error(this.fileType + this.translate.instant('WEB_EDITOR.UNDO_CHECK_OUT_ERROR'), true);
        }
    }

    /**
    * Implements ControlValueAccessor
    */
    writeValue(value: any) {
        this._value = value;
        if (this.instance) {
            this.instance.setData(value);
        }
    }
    onChange(_: any) { }
    onTouched() { }
    registerOnChange(fn: any) { this.onChange = fn; }
    registerOnTouched(fn: any) { this.onTouched = fn; }

    ngOnDestroy() {
        if (this.instance) {
            setTimeout(() => {
                this.instance.removeAllListeners();
                CKEDITOR.instances[this.instance.name].destroy();
                this.instance.destroy();
                this.instance = null;
                // if (this.configData == null || this.configData == undefined) {
                //     this.timerSubscription.unsubscribe();
                //     localStorage.removeItem(this.tempSaveKey);
                // }
            });
        }
    }

    private htmlEncode(content: string): string {
        if (content != null) {
            return content.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").
            replace(/"/g, "&quot;").replace(/'/g, "&#39;");
        }        
    }

    private insertTagEditArea(option: any) {
        this.html = '';
        this.tagContent = '';
        if (option != null) {
            if (option.data.type == "Facility" || option.data.type == "FacilityName" || option.data.type == "documentFacility" ||
                option.data.type == "SystemFacility" || option.data.type == "System" || option.data.type == "TagNo" ||
                option.data.type == "TagNoDesc" || option.data.type == "TagDocument" || option.data.type == "Description" ||
                option.data.type == "Component" || option.data.type == "Document" || option.data.type == "systemName" ||
                option.data.type == "systemNo" || option.data.type == "TagFacilityName" || option.data.type == "TagAttributes") {
                if (option.data.reusableXML != undefined) {
                    this.html = option.data.reusableXML.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                } else {
                    if (option.data.type == "Component") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='com' id='" + option.data.id + "' compName='" + this.htmlEncode(option.data.componentName) + "' compValue='" +
                            this.htmlEncode(option.data.componentValue) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.componentValue) + "</com>";
                    } else if (option.data.type == "Document") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='doc' id='" + option.data.id + "' compName='document' compValue='" +
                            this.htmlEncode(option.data.documentName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.documentName) + "</com>";
                    } else if (option.data.type == "systemName") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='sys' id='" + option.data.systemId + "' compName='SystemName' compValue='" +
                            this.htmlEncode(option.data.systemName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.systemName) + "</com>";
                    } else if (option.data.type == "systemNo") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='sys' id='" + option.data.systemId + "' compName='SystemNo' compValue='" +
                            option.data.systemNo + "' lastUpdateTimeStamp='" + option.data.systemNoLastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + option.data.systemNo + "</com>";
                    } else if (option.data.type == "Facility") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='fac' id='" + option.data.id + "' compName='facility' compValue='" +
                            this.htmlEncode(option.data.facilityName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.facilityName) + "</com>";
                    } else if (option.data.type == "FacilityName") {
                        this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
                    } else if (option.data.type == "documentFacility") {
                        if (option.data.facilityName != undefined) {
							this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.facilityLastUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
						}
                    } else if (option.data.type == "SystemFacility") {
                        if (option.data.facilityName != undefined) {
                            this.html = "<com style='background-color:#FFFF8D;' compType='fac' id='" + option.data.facilityId + "' compName='facility' compValue='" +
                            this.htmlEncode(option.data.facilityName) + "' lastUpdateTimeStamp='" + option.data.facilityLastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.facilityName) + "</com>";
                        }
                    } else if (option.data.type == "System") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='sysName' value='" +
                            this.htmlEncode(option.data.systemName) + "' lastUpdateTimeStamp='" + option.data.tagSysUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.systemName) + "</fld></tag>";
                    } else if (option.data.type == "TagNo") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagNo' value='" + this.htmlEncode(option.data.tagNo) +
                            "' lastUpdateTimeStamp='" + option.data.tagNoUpdateTimeStamp + "'>" +  this.htmlEncode(option.data.tagNo) + "</fld></tag>";
                    } else if (option.data.type == "Description") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagName' value='' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "'><tagdesc><txt>" + this.htmlEncode(option.data.description) + "</txt></tagdesc></fld></tag>";
                    } else if (option.data.type == "TagDocument") {
                        if (option.data.documentName != undefined) {
                            this.html = "<tag style='background-color:#FFFF8D;' compType='doc' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='docName' value='" +
                            this.htmlEncode(option.data.documentName) + "' lastUpdateTimeStamp='" + option.data.tagDocUpdateTimeStamp + "'>" +
                            this.htmlEncode(option.data.documentName) + "</fld></tag>";
                        }                        
                    } else if (option.data.type == "TagNoDesc") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagName' value='' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "'><tagdesc><txt>" + this.htmlEncode(option.data.description) + "</txt></tagdesc></fld></tag>" +
                            "kkkk,kkkk" +
                            "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagNo' value='" + this.htmlEncode(option.data.tagNo) +
                            "' lastUpdateTimeStamp='" + option.data.tagNoUpdateTimeStamp + "'>" + this.htmlEncode(option.data.tagNo) + "</fld></tag>";
                    } else if (option.data.type == "TagFacilityName") {
                        if (option.data.facilityName != undefined) {
                            this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.tagFacilityLastUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
                        }                        
                    } else if (option.data.type == "TagAttributes") {
                        this.html = '<tag style="background-color:#FFFF8D;" contenteditable="false" id="' + option.data.id +'"' + ' insID="' + this.guid().toUpperCase() + '"'+ '>'+ 
                        '<attr specId="' + option.data.specId + '"'+ ' lastUpdateTimeStamp="' + option.data.lastUpdateTimeStamp + 
                        '"'+ ' name="' + this.htmlEncode(option.data.name) + '"'+ ' value="' + this.htmlEncode(option.data.value) + '"'+'>' + this.htmlEncode(option.data.value) +
                        "</attr></tag>";
                    }
                }
                if (editor != null) {
                    this.selection = editor.getSelection();
                    if (this.selection != null) {
                        this.ranges = this.selection.getRanges();
                    }
                    let ancestor;
                    if (this.ranges != null) {
                        let range = this.ranges[0];
                        if (range != null) {
                            range = range.clone();
                            let startNode = range.startContainer;
                            ancestor = startNode.$.parentNode;
							if (startNode.$.nodeName == 'PH' || startNode.$.nodeName == 'EDITAREA') {
								ancestor = startNode.$;
							}
                            while(ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I'){
                                ancestor = ancestor.parentNode;
                            }
                            if(ancestor.nodeName == 'ATTR' && ancestor.parentNode.nodeName == 'TAG'){
                                ancestor = ancestor.parentNode;
                            }
                        }
                    }
                    if (ancestor != null) {
                        if (this.html == '') {
                            if (ancestor.nodeName == 'TXT' && (ancestor.parentNode.nodeName == 'STATEMENT' || ancestor.parentNode.nodeName == 'PARA' || ancestor.parentNode.nodeName == 'LI')) {
                                this.alertService.clear();
                                this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_REUSABLE'), true);
                            } else {
                                this.alertService.clear();
                                this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
                            }
                        } else {
                            if (ancestor.nodeName == 'PH') {
                                if (option.data.type == 'TagNoDesc') {
                                    this.guidWinT = this.guid().toUpperCase();
                                    this.html = this.processTagNoDesc(this.html);
                                }
                                let restriction = ancestor.attributes['restriction'].value;
                                let tagPHId = ancestor.attributes['tagPHId'].value;
                                let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
                                let text = ancestor.textContent;
                                this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
                                    '" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                this.setOuterHTML(ancestor, this.tagContent);
                            } else if (ancestor.nodeName == 'EDITAREA') {
                                if (option.data.type == 'TagNoDesc') {
                                    this.html = this.processTagNoDesc(this.html);
                                }
                                this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>';
                                this.setOuterHTML(ancestor, this.tagContent);
                            } else if (SharedService.nodeSelected() && SharedService.getselectedNode().nodeName == 'PH') {
                                if (option.data.type == 'TagNoDesc') {
                                    this.guidWinT = this.guid().toUpperCase();
                                    this.html = this.processTagNoDesc(this.html);
                                }
                                let selectedNode = SharedService.getselectedNode();
                                let restriction = selectedNode.attributes['restriction'].value;
                                let tagPHId = selectedNode.attributes['tagPHId'].value;
                                let catId = selectedNode.attributes['catId'] != undefined ? selectedNode.attributes['catId'].value : "";
                                let text = selectedNode.textContent;
                                this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
                                    '" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                this.setOuterHTML(selectedNode, this.tagContent);
                                SharedService.resetselectedNode();
                            } else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || 
                            ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT') {
                                if(!this.isStatementParent(ancestor)){
									if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM') {
										if (option.data.type == 'TagNoDesc') {
                                            this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                            this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                            this.setOuterHTML(ancestor.parentNode, this.html);
                                        }
                                        else{
                                            this.setOuterHTML(ancestor, this.html);
                                        }
									} else if (ancestor.nodeName == 'FLD') {
										if (ancestor.parentNode != undefined) {
											var tagsNode = ancestor.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									} else if (ancestor.nodeName == 'TAGDESC') {
										if (ancestor.parentNode.parentNode != undefined) {
											var tagsNode = ancestor.parentNode.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									} else if (ancestor.nodeName == 'TXT') {
										if (ancestor.parentNode.nodeName == 'STATEMENT' || ancestor.parentNode.nodeName == 'PARA' || ancestor.parentNode.nodeName == 'P' || ancestor.parentNode.nodeName == 'LI') {
											this.alertService.clear();
											this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_REUSABLE'), true);
										} else if (ancestor.parentNode.parentNode.parentNode != undefined) {
											var tagsNode = ancestor.parentNode.parentNode.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									}
								}else{
									this.alertService.clear();
									this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
								}
                            } else if (ancestor.nodeName != 'WINTITLE' && editor.getSelection() != null ) {
                                let findstatement  =this.findUpTag(ancestor,'STATEMENT' );
                                if(!findstatement) {
									if(option.data.type == 'TagNoDesc' && this.html.indexOf('</wintitle>')>-1){
										let strArray = this.html.split(/<editarea(?:(?!<tag.*?>).)*>/g);
										strArray.splice(1, 0, ", ");
										for (let i = 0; i < strArray.length; i++) {
											if (i == 0 || i == 2) {
												this.tagContent = "<tags contenteditable='false' insID='" + this.guid().toUpperCase() + "'>" + strArray[i] + "</tags>";
												let sel = editor.getSelection();
												let range;
												if (sel.getRanges()) {
													range = sel.getRanges()[0];
												}
												if (!range) {
													range = editor.createRange();
													range.selectNodeContents(editor.editable());
													sel.selectRanges([range]);
												}
												let fragment = range.extractContents();
												let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
												fragment.appendTo(container);
												editor.insertElement(container);
											} else {
												this.tagContent = strArray[i];
												editor.insertText(", ");
											}
										}
									}
									else if (option.data.type == 'TagNoDesc') {
                                        let strArray = this.html.split("kkkk,kkkk");
                                        strArray.splice(1, 0, ", ");
                                        for (let i = 0; i < strArray.length; i++) {
                                            if (i == 0 || i == 2) {
                                                this.tagContent = "<tags contenteditable='false' insID='" + this.guid().toUpperCase() + "'>" + strArray[i] + "</tags>";
                                                let sel = editor.getSelection();
                                                let range;
                                                if (sel.getRanges()) {
                                                    range = sel.getRanges()[0];
                                                }
                                                if (!range) {
                                                    range = editor.createRange();
                                                    range.selectNodeContents(editor.editable());
                                                    sel.selectRanges([range]);
                                                }
                                                let selectedElm  = sel.getSelectedElement();
                                                if(selectedElm !=null){
                                                    range = editor.createRange();
                                                    range.setStartAfter(selectedElm);
                                                    range.setEndAfter(selectedElm);
                                                    sel.selectRanges([range]);
                                                }
                                                let fragment = range.extractContents();
                                                let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
                                                fragment.appendTo(container);
                                                editor.insertElement(container);
                                            } else {
                                                this.tagContent = strArray[i];
                                                editor.insertText(", ");
                                            }
                                        }
                                    } else {
                                        this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '">' + this.html + '</tags>';
                                        let sel = editor.getSelection();
                                        let range;
                                        if (sel.getRanges()) {
                                            range = sel.getRanges()[0];
                                        }
                                        if (!range) {
                                            range = editor.createRange();
                                            range.selectNodeContents(editor.editable());
                                            sel.selectRanges([range]);
                                        }
                                        let selectedElm  = sel.getSelectedElement();
                                        if(selectedElm !=null){
                                            range = editor.createRange();
                                            range.setStartAfter(selectedElm);
                                            range.setEndAfter(selectedElm);
                                            sel.selectRanges([range]);
                                        }
                                        let fragment = range.extractContents();
                                        let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
                                        if(fragment.textContent=''){
                                            fragment.appendTo(container);
                                        }
                                        editor.insertElement(container);
                                    }
                                }
                            }
                        }
                    } else {
                        this.alertService.clear();
                        this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                    }
                } else {
                    this.alertService.clear();
                    this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
                }
            } else if (option.data.type == "Statement" || option.data.type == "History_Statement" || option.data.type == "crossReference") {
                if (option.data.type == "Statement") {
                    this.convertStatement(option.data);
                } else if (option.data.type == "crossReference") {
                    this.html = "<XRef contenteditable='false' style='background-color:#f4b342;' topicId='" + option.data.refId + "' xrefType='"
                        + option.data.refType + "' refInsId='" + option.data.refInsId + "'>" + option.data.refType + ": " + option.data.refText + "</XRref>";
                } else if (option.data.type == "History_Statement") {
                    this.html = option.data.reusableXML.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    });
                }
                if (editor != null) {
                    this.selection = editor.getSelection();
                    if (this.selection != null) {
                        this.ranges = this.selection.getRanges();
                    }
                    let ancestor;
                    if (this.ranges != null) {
                        let range = this.ranges[0];
                        if (range != null) {
                            range = range.clone();
                            let startNode = range.startContainer;
                            ancestor = startNode.$.parentNode;
                        }
                    } if (ancestor != null) {
                        if (this.html != '') {
                            if (ancestor.nodeName != 'WINTITLE' && editor.getSelection() != null) {
                                let sel = editor.getSelection();
                                let range;
                                if (sel.getRanges()) {
                                    range = sel.getRanges()[0];
                                }
                                if (!range) {
                                    range = editor.createRange();
                                    range.selectNodeContents(editor.editable());
                                    sel.selectRanges([range]);
                                }
                                let selectedElm  = sel.getSelectedElement();
                                if(selectedElm !=null){
                                    range = editor.createRange();
                                    range.setStartAfter(selectedElm);
                                    range.setEndAfter(selectedElm);
                                    sel.selectRanges([range]);
                                }
                                let fragment = range.extractContents();
                                let container = CKEDITOR.dom.element.createFromHtml(this.html, editor.document);
                                if(fragment.textContent=''){
                                   fragment.appendTo(container);
                                }
                                editor.insertElement(container);
                            } else {
                                this.alertService.clear();
                                this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                            }
                        } else {
                            this.alertService.clear();
                            this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
                        }
                    } else {
                        this.alertService.clear();
                        this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                    }
                } else {
                    this.alertService.clear();
                    this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
                }
            } else if (option.data.type == "fraction" || option.data.type == "speChar") {
                if (option.data.type == "speChar") {
                    this.html = "<spChar contenteditable='false' style='background-color:#48f442;' value='" + option.data.decVal + "' insID='" +
                        this.guid().toUpperCase() + "'>" + option.data.symbol + "</spChar>";
                } else {
                    this.html = "<fraction style='background-color:#f4b342;' numerator='" + option.data.numerator + "' denominator=" + option.data.denominator + ">" + option.data.numerator + "/" + option.data.denominator + "</fraction>";
                }
                if (editor != null) {
                    this.selection = editor.getSelection();
                    if (this.selection != null) {
                        this.ranges = this.selection.getRanges();
                    }
                    let ancestor;
                    if (this.ranges != null) {
                        let range = this.ranges[0];
                        if (range != null) {
                            range = range.clone();
                            let startNode = range.startContainer;
                            ancestor = startNode.$.parentNode;
                        }
                    }
                    if (ancestor != null) {
                        if (this.html != '') {
                            if (ancestor.nodeName == 'WINTITLE') {
                                $(ancestor).removeAttr("contenteditable");
                            } else if (ancestor.nodeName == 'PH') {
                                let restriction = ancestor.attributes['restriction'].value;
                                let tagPHId = ancestor.attributes['tagPHId'].value;
                                let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
                                let text = ancestor.textContent;
                                this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
                                    '" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
                                this.setOuterHTML(ancestor, this.tagContent);
                            } else if (ancestor.nodeName == 'EDITAREA') {
                                this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>';
                                this.setOuterHTML(ancestor, this.tagContent);
                            } else if (ancestor.nodeName != 'TAG' || ancestor.nodeName != 'COM' || ancestor.nodeName != 'FLD' || ancestor.nodeName != 'TAGDESC' || ancestor.nodeName != 'TXT' || ancestor.nodeName != 'TAGS' || ancestor.nodeName != 'STATEMENT') {
                                if (option.data.type == "speChar") {
                                    this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '">' + this.html + '</tags>';
                                } else {
                                    this.tagContent = "<fraction contenteditable='false' style='background-color:#f4b342;' numerator='" + option.data.numerator + "' denominator=" + option.data.denominator + ">" + option.data.numerator + "/" + option.data.denominator + "</fraction>";
                                }
                                let sel = editor.getSelection();
                                let range;
                                if (sel.getRanges()) {
                                    range = sel.getRanges()[0];
                                }
                                if (!range) {
                                    range = editor.createRange();
                                    range.selectNodeContents(editor.editable());
                                    sel.selectRanges([range]);
                                }
                                let fragment = range.extractContents();
                                let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
                                fragment.appendTo(container);
                                editor.insertElement(container);
                            } else {
                                this.alertService.clear();
                                this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                            }
                        } else {
                            this.alertService.clear();
                            this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
                        }
                    } else {
                        this.alertService.clear();
                        this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
                    }
                } else {
                    this.alertService.clear();
                    this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
                }
            } else if (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure") {
                var id = option.data.id;
                if (option.data.type == "imageAsFigure") {
                    this.adjustImageWidth(option.data.width);
                    this.html = '<figure class = "image" type = "figure" width ="' + this.width + '" height = "' + option.data.height + '"><img src = "'
                        + this.htmlEncode(option.data.imgsrc) + '" alt ="" id = "' + option.data.id + '" insid = "'
                        + this.guid() + '"actualsrc="' + option.data.path + '" width = "' + this.width + '" height = "auto"/><figcaption>' + this.htmlEncode(option.data.title) + '</figcaption></figure>';
                    
                } else if (option.data.type == "imageAsImage") {
                    this.adjustImageWidth(option.data.width);
                    this.html = '<figure class = "image" type = "image" width ="' + option.data.width + '" height = "' + option.data.height
                        + '"><img src = "' + this.htmlEncode(option.data.imgsrc) + '" alt ="" id = "' + option.data.id + '" insid = "' + this.guid() + '" actualsrc="' + option.data.path + '" width = "'
                        + this.width + '" height = "auto"/></figure>'
                    

                }
                if (editor != null) {
                    let sel = editor.getSelection();
                    let range;
                    if (sel.getRanges()) {
                        range = sel.getRanges()[0];
                    }
                    if (!range) {
                        range = editor.createRange();
                        range.selectNodeContents(editor.editable());
                        sel.selectRanges([range]);
                    }
                    let fragment = range.extractContents();
                    let container = CKEDITOR.dom.element.createFromHtml(this.html, editor.document);
                    fragment.appendTo(container);
                    editor.focusManager.focus(editor.editable());
                    var data: string = this.html;
                    editor.insertHtml(data);
                } else {
                    this.alertService.clear();
                    this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
                }
            } else if (option.data.type == "Commissioning") {

                this.html = option.data.html;
                if (editor != null) {
                   

                    this.selection = editor.getSelection();
                    if (this.selection != null) {
                        this.ranges = this.selection.getRanges();
                    }
                    let ancestor;
                    if (this.ranges != null) {
                        let range = this.ranges[0];
                        if (range != null) {
                            range = range.clone();
                            let startNode = range.startContainer;
                            ancestor = startNode.$.parentNode;
                        }
                    }
                    if (!this.isTableParent(ancestor)) {
                        let sel = editor.getSelection();
                        let range;
                        if (sel.getRanges()) {
                            range = sel.getRanges()[0];
                        }
                        if (!range) {
                            range = editor.createRange();
                            range.selectNodeContents(editor.editable());
                            sel.selectRanges([range]);
                        }
                        let fragment = range.extractContents();
                        let container = CKEDITOR.dom.element.createFromHtml(this.html, editor.document);
                        fragment.appendTo(container);
                        editor.insertElement(container);
                    } else {
                        this.alertService.clear();
                        this.alertService.info(this.translate.instant("WEB_EDITOR.COULDNT_INSERT_COMMISSIONING_TABLES_TO_TITLE"), true);
                     
                    }
                    

                } else {
                    this.alertService.clear();
                    this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
                }
                
            }
            if(option.data.type != "fraction" && option.data.type != "speChar" && option.data.type != "imageAsImage" && option.data.type != "imageAsFigure"){
                SharedService.setHtmlString(this.html);
            }

        } else {
            // not inserting to tag edit area
        }
    }

    

    private processTagNoDesc(html: string): string {
        let tagCombiStr = "";
        tagCombiStr = html.replace(/<\/tag>,/g, '</tag><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle style="background-color:#cfe2f3;" insID="'
            + this.guidWinT + '" id="' + this.guidWinT + '">&nbsp;,&nbsp;</wintitle>' + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>').
            replace(/kkkk,kkkk/g,'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>'
            +'<wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guid() + '" id="' + this.guid() + '">,&nbsp;</wintitle>'
            +'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>');
        return tagCombiStr;
    }


    private isTableParent(tagNode: any): boolean {
        let inStmNode: boolean = false;

        while(tagNode.parentNode != undefined){
			if(tagNode.parentNode.nodeName == "TABLE"){
                inStmNode = true;
                break;
            }
            else{
                tagNode = tagNode.parentNode;
            }
		}
        return inStmNode;
    }

    private isStatementParent(tagNode: any): boolean {
        let inStmNode: boolean = false;

        while(tagNode.parentNode != undefined){
			if(tagNode.parentNode.nodeName == "STATEMENT"){
                inStmNode = true;
                break;
            }
            else{
                tagNode = tagNode.parentNode;
            }
		}
        // if (tagNode.parentNode != undefined) {
        //     let tagsNode = tagNode.parentNode;
        //     if (tagsNode.parentNode != undefined && tagsNode.parentNode.nodeName == 'STATEMENT') {
        //         inStmNode = true;
        //     } else if (tagsNode.parentNode.nodeName == 'P') {
        //         if (tagsNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.nodeName == 'STATEMENT') {
        //             inStmNode = true;
        //         }
        //     } else if (tagsNode.parentNode.nodeName == 'LI' && tagsNode.parentNode.parentNode != undefined) {
        //         if (tagsNode.parentNode.parentNode.nodeName == 'OL' || tagsNode.parentNode.parentNode.nodeName == 'UL') {
        //             if (tagsNode.parentNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT') {
        //                 inStmNode = true;
        //             }
        //         }
                
        //     } else if(tagsNode.parentNode != undefined && tagsNode.parentNode.parentNode != undefined) {
        //         if (tagsNode.parentNode.parentNode.nodeName == 'LI' && tagsNode.parentNode.parentNode.parentNode != undefined) {
        //             if (tagsNode.parentNode.parentNode.parentNode.nodeName == 'OL' || tagsNode.parentNode.parentNode.parentNode.nodeName == 'UL') {
        //                 if (tagsNode.parentNode.parentNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT') {
        //                     inStmNode = true;
        //                 }
        //                 else {
        //                     inStmNode = this.isStatementParent(tagsNode.parentNode.parentNode);
        //                 }
        //             }
        //         }else if (tagsNode.parentNode.nodeName == 'TXT' && tagsNode.parentNode.parentNode.parentNode != undefined){
        //             if(tagsNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT'){
        //                 inStmNode = true;
        //             }
        //         }
        //     }
        // }
        return inStmNode;
    }

    /**
     * Adjust inserted image width according to window width.
     * @param actualImageWidth 
     */
    public adjustImageWidth(actualImageWidth: any) {
        let ui = JSON.parse(sessionStorage.getItem(SessionStorageConst.UI_LAYOUT));
        var layout;
        var percentWidth;
        switch (ui) {
            case "UIL_1":
                layout = Constants.UI_LAYOUT.UIL_1;
                percentWidth = layout.tW;
                break;
            case "UIL_2":
                layout = Constants.UI_LAYOUT.UIL_2;
                percentWidth = layout.tW;
                break;
            case "UIL_3":
                layout = Constants.UI_LAYOUT.UIL_3;
                percentWidth = layout.tW;
                break;
            case "UIL_4":
                layout = Constants.UI_LAYOUT.UIL_4;
                percentWidth = layout.tW;
                break;
        }

        var windowWidth = window.screen.availWidth;
        let divWidth = windowWidth * (percentWidth / 100);
        if (divWidth - 150 > actualImageWidth) {
            this.width = actualImageWidth;
        } else {
            this.width = divWidth - 150;
        }
        if(this.width>300){
            this.width =  300;
        }
    }



    public insertCommissioningTables(event: any) {
        //console.log('area', event);
        let comHtml = ""
        if (this.selectedWidget == null || this.selectedWidget.trim().length <= 0
            || this.selectedRowsString.trim().length <= 0) {
            this.alertService.error("Please select a system");
        } else {
            var systemIds = this.selectedRowsString.substring(0, this.selectedRowsString.length - 1);
            if (this.selectedWidget == "punchlist") {
                if (this.punchlistsSelectedCatogory == "ALL") {
                    this.punchlistsSelectedCatogory = "All";
                }
                this.webEditorService.getPunchlistTableData(this.selectedRowsString, this.punchlistsSelectedCatogory, this.punchlistSelectedStatus).subscribe(
                    data => {
                        comHtml += "<table type='commissioning' class='ckecommissioning' name='CommissionDG_Punchlist' selectedcategory='" 
                        + this.punchlistsSelectedCatogory + "' selectedsystems ='" + systemIds + "' selectedplstatus  ='" + this.punchlistSelectedStatus 
                        + "'  border='" + 1 + "' cellspacing='" + 0 + "' style='width:100%' commType='Punchlist'  insid='Punchlist_" + this.guid();
                        comHtml += "'>";
                        comHtml += "<caption>Punchlist Table</caption>";
                        comHtml += "<thead contenteditable='false'><tr><th style='width:60%'><b>Description</b></th><th style='width:30%'><b>Asset</b></th><th style='width:20%'><b>Date Closed</b></th></tr>"
                        comHtml += "</thead><tbody contenteditable='false'>";
                        if (data.payload != null) {
                            if (data.payload.length <= 0) {
                                comHtml += "<td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            } else {
                                for (let i = 0; i < data.payload.length; i++) {
                                    comHtml += "<tr>"
                                    comHtml += "<td style='width:60%'>" + data.payload[i].punchlistDescription 
                                    + " </td><td style='width:30%'>" + data.payload[i].assetName + " </td><td style='width:20%'>" +
                                        data.payload[i].dateClosedString + "</td></tr>";
                                }
                            }
                        } else {
                            comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                        }
                        comHtml += "</tbody></table><br/>";
                        this.createComTableData(comHtml);
                    },
                    error => {
                        console.log('[getPunchlistTableData] (error) ' + JSON.stringify(error.statusDescription));
                        this.alertService.error(error.statusDescription);
                    }
                );
            } else if (this.selectedWidget == "mcc") {
                this.webEditorService.geMCCTableData(this.selectedRowsString).subscribe(
                    data => {
                        comHtml = "<table type='commissioning' class='ckecommissioning' name='CommissionDG_MCC' border='" + 1 + "' cellspacing='" + 0 + "' selectedsystems ='" + systemIds +
                            "' style='width:100%' commType='MCC' insid='MCC_" + this.guid() + "'>";
                        comHtml += "<caption>Mechanical Completion Certificate Table</caption>";
                        comHtml += "<thead contenteditable='false'><tr><th><b>System Number</b></th><th><b>System Name</b></th><th><b>Vendor</b></th></tr></thead><tbody contenteditable='false'>";
                        if (data != null) {
                            if (data.payload.length <= 0) {
                                comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            } else {
                                for (let i = 0; i < data.payload.length; i++) {
                                    comHtml += "<tr>"
                                    comHtml += "<td>" + data.payload[i].systemNo + " </td><td>" + data.payload[i].systemName + " </td><td>" + data.payload[i].vendorName + " </td></tr>";
                                }
                            }
                        } else {
                            comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                        }
                        comHtml += "</tbody></table><br/>";
                        this.createComTableData(comHtml);
                        this.selectedWidget = null;
                        this.selectedRowsString = null;
                    },
                    error => {
                        console.log('[getmccTableData] (error) ' + JSON.stringify(error.statusDescription));
                        this.alertService.error(error.statusDescription);
                    }
                );
            } else if (this.selectedWidget == "scc") {
                //console.log("DATAAAAA : " + this.selectedWidget)
                var systemIds = this.selectedRowsString.substring(0, this.selectedRowsString.length - 1);
                this.webEditorService.getPrecommissioningData(this.selectedRowsString).subscribe(
                    data => {
                        comHtml = "<table type='commissioning' class='ckecommissioning' name='CommissionDG_SCC' border='" + 1 + "' cellspacing='" + 0 + "' selectedsystems ='" + systemIds +
                            "' style='width:100%' commType='SCC' insid='SCC_" + this.guid() + "'>";
                        comHtml += "<caption>Precommissioning Certificate Table</caption>";
                        comHtml += "<thead contenteditable='false'><tr><th><b>System Number</b></th><th><b>System Name</b></th><th><b>Vendor</b></th></tr>";
                        comHtml += "</thead><tbody contenteditable='false'>";
                        if (data != null) {
                            if (data.payload.length <= 0) {
                                comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            } else {
                                for (let i = 0; i < data.payload.length; i++) {
                                    comHtml += "<tr>"
                                    comHtml += "<td>" + data.payload[i].systemNo + " </td><td>" + data.payload[i].systemName + " </td><td>" + data.payload[i].vendor + " </td></tr>";
                                }
                            }
                        } else {
                            comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                        }
                        comHtml += "</tbody></table>";

                        this.createComTableData(comHtml);
                    },
                    error => {
                        console.log('[getPrecommissioningData] (error) ' + JSON.stringify(error.statusDescription));
                        this.alertService.error(error.statusDescription);
                    }
                );

            } else if (this.selectedWidget == "stnptn") {
                if (this.vendorSelectedType.length > 0) {
                    this.webEditorService.getSTNS(this.selectedRowsString, this.vendorSelectedType.toString() + ",").subscribe(
                        data => {
                            comHtml = "<table type='commissioning' comType='STN' class='ckecommissioning' name='CommissionDG_STN'  border='" + 1 + "' cellspacing='" + 0 + " 'selectedsystems ='" + systemIds +
                                "' selectedvendor='" + this.vendorSelectedType + "' style='width:100%' insid='STN_" + this.guid() + "'>";
                            comHtml += "<caption>System Turn Over Notice Table</caption>";
                            comHtml += "<thead contenteditable='false'><tr><th><b>System Number</b></th><th><b>System Name</b></th><th><b>Type</b></th><th><b>Vendor</b></th></tr>"
                            comHtml += "</thead><tbody contenteditable='false'>";
                            if (data.payload != null) {
                                if (data.payload.length <= 0) {
                                    comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                                } else {
                                    for (let i = 0; i < data.payload.length; i++) {
                                        comHtml += "<tr>"
                                        comHtml += "<td>" + data.payload[i].systemNo + " </td><td>" + data.payload[i].systemName + " </td><td>" +
                                            data.payload[i].stnStatus + "</td><td>" + data.payload[i].vendorName
                                            + " </td></tr>";
                                    }
                                }
                            } else {
                                comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            }
                            comHtml += "</tbody></table><br/>";
                            this.createComTableData(comHtml);
                        },
                        error => {
                            console.log('[getChecksheetStatus] (error) ' + JSON.stringify(error.statusDescription));
                            this.alertService.error(error.statusDescription);
                        }
                    );
                } else {
                    this.alertService.error("Please select a vendor");
                }


            } else if (this.selectedWidget == "checksheet") {
                var caption = "";
                var name = "";
                if (this.checksheetSelectedType == "A") {
                    caption = "Mechanical Completion Check Sheet Table";
                    name = "CommissionDG_MCCheckSheet";
                    comType = "MCCheckSheet";
                } else {
                    caption = "Precommissioning Check Sheet Table";
                    name = "CommissionDG_SCCheckSheet";
                    comType = "SCCheckSheet";
                }
                this.webEditorService.getChecksheetData(this.selectedRowsString, this.checksheetSelectedType).subscribe(
                    data => {
                        comHtml = "<table type='commissioning' class='ckecommissioning' name='" + name + "' border='" + 1 + "' cellspacing='" + 0 + "' selectedsystems ='" + systemIds +
                            "' selctedChecksheet='" + this.checksheetSelectedType + "' comtype='" + comType + "' style='width:100%' insid='" + comType + "_" + this.guid() + "'>";
                        comHtml += "<caption>" + caption + "</caption>";
                        comHtml += "<thead contenteditable='false'><tr><th><b>Check Sheet Code</b></th><th><b>Check Sheet Description</b></th><th><b>vendor</b></th></tr>"
                        comHtml += "</thead><tbody contenteditable='false'>";
                        if (data.payload != null) {
                            if (data.payload.length <= 0) {
                                comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            } else {
                                for (let i = 0; i < data.payload.length; i++) {
                                    comHtml += "<tr>"
                                    comHtml += "<td>" + data.payload[i].checkSheetCode + " </td><td>" + data.payload[i].checkSheetName + " </td><td>" +
                                        data.payload[i].vendorName + "</td></tr>";
                                }
                            }
                        } else {
                            comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                        }
                        comHtml += "</tbody></table><br/>";
                        this.createComTableData(comHtml);
                    },
                    error => {
                        console.log('[getChecksheetData] (error) ' + JSON.stringify(error.statusDescription));
                        this.alertService.error(error.statusDescription);
                    }
                );

            } else if (this.selectedWidget == "checksheetstatus") {
                var caption = "";
                var name = "";
                var comType = ";"
                if (this.checksheetSelectedType == "A") {
                    caption = "Mechanical Completion Incomplete Check Sheet Table";
                    name = "CommissionDG_MCCSStatus";
                    comType = "MCCSStatus";
                } else {
                    caption = "Precommissioning Incomplete Check Sheet Table";
                    name = "CommissionDG_SCCSStatus";
                    comType = "SCCSStatus";
                }
                this.webEditorService.getChecksheetStatus(this.selectedRowsString, this.checksheetSelectedType).subscribe(
                    data => {
                        comHtml = "<table type='commissioning' class='ckecommissioning' name='" + name + "' border='" + 1 + "' cellspacing='" + 0 + " 'selectedsystems ='" + systemIds +
                            "' selctedChecksheet='" + this.checksheetSelectedType + "' style='width:100%' comtype='" + comType + "' insid='" + comType + "_" + this.guid() + "'>";
                        comHtml += "<caption>" + caption + "</caption>";
                        comHtml += "<thead contenteditable='false'><tr><th><b>System Number</b></th><th><b>Check Sheet Code</b></th><th><b>Asset</b></th><th><b>Description</b></th></tr>"
                        comHtml += "</thead><tbody contenteditable='false'>";
                        if (data.payload != null) {
                            if (data.payload.length <= 0) {
                                comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            } else {
                                for (let i = 0; i < data.payload.length; i++) {
                                    comHtml += "<tr>"
                                    comHtml += "<td>" + data.payload[i].systemNo + " </td><td>" + data.payload[i].checkSheetCode + " </td><td>" +
                                        data.payload[i].assetName + "</td><td>" + data.payload[i].checksheetStatus + " </td></tr>";
                                }
                            }
                        } else {
                            comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                        }
                        comHtml += "</tbody></table><br/>";
                        this.createComTableData(comHtml);
                    },
                    error => {
                        console.log('[getChecksheetStatus] (error) ' + JSON.stringify(error.statusDescription));
                        this.alertService.error(error.statusDescription);
                    }
                );

            } else if (this.selectedWidget == "checksheetsummary") {
                var caption = "";
                var name = "";
                var comType = "";
                if (this.checksheetSelectedType == "A") {
                    caption = "Mechanical Completion Check Sheet Summary Table";
                    name = "CommissionDG_MCCSSummary";
                    comType = "MCCSSummary";
                } else {
                    caption = "Precommissioning Check Sheet Summary Table";
                    name = "CommissionDG_SCCSummary";
                    comType = "SCCSSummary";
                }
                this.webEditorService.getChecksheetSummary(this.selectedRowsString, this.checksheetSelectedType).subscribe(
                    data => {
                        comHtml = "<table type='commissioning' class='ckecommissioning'  name='" + name + "' border='" + 1 + "' cellspacing='" + 0 + " 'selectedsystems ='" + systemIds
                            + "'selctedChecksheet='" + this.checksheetSelectedType + "' style='width:100%' comtype='" + comType + "' insid='" + comType + "_" + this.guid() + "'>";
                        comHtml += "<caption>" + caption + "</caption>";
                        comHtml += "<thead contenteditable='false'><tr><th><b>Total Check Sheets</b></th><th><b>Completed Check Sheets</b></th><th><b>Incomplete Check Sheets</b></th></tr>"
                        comHtml += "</thead><tbody contenteditable='false'>";
                        if (data.payload != null) {
                            if (data.payload.length <= 0) {
                                comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                            } else {
                                comHtml += "<tr>"
                                comHtml += "<td>" + data.payload.totalCheckSheets + " </td><td>" + data.payload.completeCheckSheets + " </td><td>" +
                                    data.payload.incompleteCheckSheets + "</td></tr>";
                            }
                        } else {
                            comHtml += "<tr><td>No Data</td><td>No Data</td><td>No Data</td></tr>";
                        }
                        comHtml += "</tbody></table><br/>";
                        this.createComTableData(comHtml);
                    },
                    error => {
                        console.log('[getChecksheetSummary] (error) ' + JSON.stringify(error.statusDescription));
                        this.alertService.error(error.statusDescription);
                    }
                );
            }
        }
    }

    createComTableData(comHtml: any) {
        const commissioningdata = {
            html: comHtml,
            type: "Commissioning"
        };
        const comStr = JSON.stringify(commissioningdata);
        let comEvent = JSON.parse(comStr);
        const comObj = {
            data: comEvent
        };
        const comObjStr = JSON.stringify(comObj);
        let option = JSON.parse(comObjStr);
        this.insertTagEditArea(option);
    }

    public replaceEntities(content: any) {
        if (content != null) {
            content = content.replace(/\s/g, " ");
            content = content.replace(/&amp;/g, "&#38;");
            content = content.replace(/&lt;/g, "&#60;");
            content = content.replace(/&gt;/g, "&#62;");
            content = content.replace(/‐/g, "-");
            content = content.replace(/&nbsp;/g, " ");
            content = content.replace(/<\s*\/\s*thead>(.)\s<*thead[^>]*>/g, "");
            content = content.replace(/\u200B/g,'');
            content = content.replace(/\/(p|div|figure)>\s+<(p|div|figure)/g, "/$1><$2");
        } else {
            content = "";
        }
        return content;

    }

    private isInlineStm(node: any): boolean {
        let inlineStm: boolean = true;
        let length = node.childNodes.length;
        for (let i = 0; i < length; i++) {
            let innerNode = node.childNodes[i];
            if ($(innerNode)[0].nodeName == 'txt' || $(innerNode)[0].nodeName == 'ph') {
                inlineStm = true;
            } else {
                if (length == 1 && $(innerNode)[0].nodeName == 'p') {
                    inlineStm = true;
                } else {
                    inlineStm = false;
                }
                break;
            }
        }
        return inlineStm;
    }

    //Convert the xml string to statement xml.
    convertStatement(content: any): string {
        this.html = '';
        let xmlDoc;
        let statementContent = content.content;
        try {
            xmlDoc = $.parseXML(statementContent);

        } catch {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
        }
        let outNode = $(xmlDoc).find('statement');
        let inlineStm = this.isInlineStm($(outNode)[0]);
        let length = outNode[0].childNodes.length;
        let id = outNode.attr('id');
        let label = this.htmlEncode(outNode.attr('label'));
        if (!inlineStm) {
            this.html = "<div contenteditable='false' statementdiv='true'>";
        }
        this.html += "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + content.lastUpdateTimeStamp + "' contenteditable='false' id='" +
            id + "' label='" + label + "'>";
        for (let i = 0; i < length; i++) {
            let innerNode = outNode[0].childNodes[i];
            if ($(innerNode)[0].nodeName == 'txt') {
                this.html += "<txt";
                let bgc = $(innerNode).attr("bgc");
                let fgc = $(innerNode).attr("fgc");
                if (bgc != null && bgc != "") {
                    this.html += " bgc='" + bgc + "' "
                }
                if (fgc != null && fgc != "") {
                    this.html += " fgc='" + fgc + "' "
                }
                this.html += ">";
                for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
                    let childTxtNode = $(innerNode)[0].childNodes[j];
                    if ($(childTxtNode)[0].nodeName == '#text') {
                        this.html += childTxtNode.textContent;
                    } else {
                        this.processStatement(childTxtNode);
                    }
                }
                this.html += "</txt>";
            } else if ($(innerNode)[0].nodeName == 'ph') {
                this.processStatement($(innerNode)[0]);
            } else if ($(innerNode)[0].nodeName == 'p') {
                if ($(innerNode)[0].childNodes.length != 0) {
                    this.html += '<p contenteditable="false" style="background-color:#d9d9d9;display:block;">';
                } else {
                    this.html += '<p contenteditable="false" style="background-color:#d9d9d9;display:block;">&nbsp;';
                }
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let childNode = $(innerNode)[0].childNodes[i];
                    if ($(childNode)[0].nodeName == 'txt') {
                        this.html += "<txt";
                        let bgc = $(innerNode).attr("bgc");
                        let fgc = $(innerNode).attr("fgc");
                        if (bgc != null && bgc != "") {
                            this.html += " bgc='" + bgc + "' "
                        }
                        if (fgc != null && fgc != "") {
                            this.html += " fgc='" + fgc + "' "
                        }
                        this.html += ">";
                        for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
                            let childTxtNode = $(childNode)[0].childNodes[j];
                            if ($(childTxtNode)[0].nodeName == '#text') {
                                this.html += this.htmlEncode(childTxtNode.textContent);
                            } else {
                                this.processStatement(childTxtNode);
                            }
                        }
                        this.html += "</txt>";
                    } else if ($(childNode)[0].nodeName == 'ph') {
                        this.processStatement($(childNode)[0]);
                    }
                }
                this.html += '</p> ';
            } else if ($(innerNode)[0].nodeName == 'ol') {
                let listStyle = $(innerNode).attr("liststyle");
                this.html += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                    '">';
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let liNode = $(innerNode)[0].childNodes[i];
                    if (liNode.nodeName == 'li') {
                        this.html += '<li>';
                        for (let i = 0; i < liNode.childNodes.length; i++) {
                            let childNode = liNode.childNodes[i];
                            if (childNode.nodeName == 'txt') {
                                $(childNode).removeAttr('style');
                                this.processStatement(childNode);
                            } else if (childNode.nodeName == 'ph') {
                                this.processStatement(childNode);
                            }
                        }
                        this.html += '</li>';
                    }
                }
                this.html += '</' + $(innerNode)[0].nodeName + '>';
            } else if ($(innerNode)[0].nodeName == 'ul') {
                let listStyle = $(innerNode).attr("liststyle");
                this.html += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                    '">';
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let liNode = $(innerNode)[0].childNodes[i];
                    if (liNode.nodeName == 'li') {
                        this.html += '<li>';
                        for (let i = 0; i < liNode.childNodes.length; i++) {
                            let childNode = liNode.childNodes[i];
                            if (childNode.nodeName == 'txt') {
                                $(childNode).removeAttr('style');
                                this.processStatement(childNode);
                            } else if (childNode.nodeName == 'ph') {
                                this.processStatement(childNode);
                            }
                        }
                        this.html += '</li>';
                    }
                }
                this.html += '</' + $(innerNode)[0].nodeName + '>';

            }
        }
        this.html += "</statement>";
        if (!inlineStm) {
            this.html += "</div>";
        }
        return this.html;
    }


    private processStatement(node: any) {
        if (node.nodeName == 'ph') {
            let arrLen = node.attributes.length;
            let attrPh, attrRestriction, value, attrCatId;
            for (let n = 0; n < arrLen; n++) {
                let attrName = node.attributes[n].name;
                let attrValue = node.attributes[n].value;
                if (attrName == 'tagPHId' || attrName == 'tagphid') {
                    attrPh = attrValue;
                } else if (attrName == 'restriction') {
                    attrRestriction = attrValue;
                } else if (attrName == 'catId') {
                    attrCatId = attrValue;
                }
            }
            if (attrPh != undefined && attrRestriction != undefined) {
                value = node.childNodes[0].textContent;
                if (attrCatId != undefined) {
                    if (value != undefined) {
                        this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='"
                            + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";

                    } else {
                        this.html += this.html + "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='"
                            + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
                    }
                } else {
                    if (value != undefined) {
                        this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='"
                            + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";

                    } else {
                        this.html += this.html + "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='"
                            + attrPh + "' restriction='" + attrRestriction + "'></ph>";
                    }
                }
            } else if (attrPh != undefined && attrRestriction == undefined) {
                this.html += this.html + "<ph insID='" + this.guid()
                    + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "'></ph>";
            }
        } else if (node.nodeName == 'txt') {
            this.html += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.html += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.html += " fgc='" + fgc + "' "
            }
            this.html += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.html += childTxtNode.textContent;
                } else {
                    this.processStatement(childTxtNode);
                }
            }
            this.html += "</txt>";

        } else if (node.nodeName == 'ol') {
            let listStyle = $(node).attr("liststyle");
            this.html += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                '">';
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                let liNode = $(node)[0].childNodes[i];
                if (liNode.nodeName == 'li') {
                    this.html += '<li>';
                    for (let i = 0; i < liNode.childNodes.length; i++) {
                        let childNode = liNode.childNodes[i];
                        if (childNode.nodeName == 'txt') {
                            $(childNode).removeAttr('style');
                            this.processStatement(childNode);
                        } else if (childNode.nodeName == 'ph') {
                            this.processStatement(childNode);
                        }
                    }
                    this.html += '</li>';
                }
            }
            this.html += '</' + $(node)[0].nodeName + '>';

        } else if (node.nodeName == 'ul') {
            let listStyle = $(node).attr("liststyle");
            this.html += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                '">';
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                let liNode = $(node)[0].childNodes[i];
                if (liNode.nodeName == 'li') {
                    this.html += '<li>';
                    for (let i = 0; i < liNode.childNodes.length; i++) {
                        let childNode = liNode.childNodes[i];
                        if (childNode.nodeName == 'txt') {
                            $(childNode).removeAttr('style');
                            this.processStatement(childNode);
                        } else if (childNode.nodeName == 'ph') {
                            this.processStatement(childNode);
                        }
                    }
                    this.html += '</li>';
                }
            }
            this.html += '</' + $(node)[0].nodeName + '>';

        } else if(node.nodeName == 'p'){
            let grangp = node.parentNode.parentNode;
            if(node.parentNode.parentNode.nodeName=='li'){
                for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                    let childNode = $(node)[0].childNodes[i];
                    this.processStatement(childNode);
                }
            }else{
                let str = node.outerHTML;
                str = str.replace(/<font.*?>/gi, '');
                str = str.replace(/<\/font>/gi, '');
                this.html += str;
            }
        } else if(node.nodeName == 'b'){
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                this.html += '<b>';
                let childNode = $(node)[0].childNodes[i];
                if (childNode.nodeName == '#text') {
                    this.html += childNode.textContent;
                } else {
                    this.processStatement(childNode);
                }
                this.html += '</b>';
            }
        } else if(node.nodeName == 'u'){
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                this.html += '<u>';
                let childNode = $(node)[0].childNodes[i];
                if (childNode.nodeName == '#text') {
                    this.html += childNode.textContent;
                } else {
                    this.processStatement(childNode);
                }
                this.html += '</u>';
            }
        } else if(node.nodeName == 'i'){
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                this.html += '<i>';
                let childNode = $(node)[0].childNodes[i];
                if (childNode.nodeName == '#text') {
                    this.html += childNode.textContent;
                } else {
                    this.processStatement(childNode);
                }
                this.html += '</i>';
            }
        }
        else {
            let str = node.outerHTML;
            str = str.replace(/<font.*?>/gi, '');
            str = str.replace(/<\/font>/gi, '');
            this.html += str;
        }
    }

    // saveTemporary() {
    //     let topicContent = "";
    //     let titleContent = "";
    //     let content = "";
    //     this.setTDWidth();
    //     topicContent = this.instance.getData();
    //     titleContent = this.titleEditor.getData();
    //     if (topicContent.trim() != "" || titleContent.trim() != "") {
    //         content = '<topic><header>' + titleContent + '</header><xml id="' + this.fileId + '">' + topicContent + '</xml></topic>'
    //         //var parser = new DOMParser();
    //         if (!this.isCheckedIn && content != "") {
    //             localStorage.setItem(this.tempSaveKey, content);
    //         }
    //     }
    // }

    //Save document from editor
    save() {
        if(!navigator.onLine){
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.SAVE_NO_INTERENET_CONNECTION'), true);
			return;
		}
        this.loadingService.showLoading(true, false, "", 0);
        this.setTDWidth();
        let topicContent = "";
        let titleContent = "";
        let content = "";
        topicContent = this.instance.getData();
        titleContent = this.titleEditor.getData();
        content = '<topic><header>' + titleContent + '</header><xml id="' + this.fileId + '">' + topicContent + '</xml></topic>'
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(content, "text/html");
        var headerElement = xmlDoc.getElementsByTagName("header")[0];
        var title = "";
        if (headerElement == null) {
            title = "";
        } else {
            title = this.replaceEntities(headerElement.textContent);
        }
        var topiContent = "";
        if (content != null) {
            topiContent = this.replaceEntities(content);
        }
        console.log("CONTENT TO SAVE : " + topiContent);
        console.log ("TITLE : " + title);
        this.webEditorService.setCallback(this);
        this.webEditorService.saveDocumentFromEditor(this.fileId, this.fileType, topiContent, title);
    }

    //checkin document from editor
    checkIn(comments) {
        if(!navigator.onLine){
			this.alertService.clear();
			this.alertService.error(this.translate.instant('WEB_EDITOR.CHECKIN_NO_INTERENET_CONNECTION'), true);
			return;
		}
        this.loadingService.showLoading(true, false, "", 0);
        this.setTDWidth();
        let topicContent = "";
        let titleContent = "";
        let content = "";
        topicContent = this.instance.getData();
        titleContent = this.titleEditor.getData();
        content = '<topic><header>' + titleContent + '</header><xml id="' + this.fileId + '">' + topicContent + '</xml></topic>';
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(content, "text/html");
        var headerElement = xmlDoc.getElementsByTagName("header")[0];
        var title = "";
        if (headerElement == null) {
            title = "";
        } else {
            title = this.replaceEntities(headerElement.textContent);;
        }
        this.bsModalRef.hide();
        var topContent = "";
        if (content != null) {
            topContent = this.replaceEntities(content);
        }
        console.log("CONTENT TO CHECKIN : " + topContent);
        console.log ("TITLE : " + title);
        
        this.webEditorService.ckeckinDocumentFromEditor(this.fileId, this.fileType, comments, topContent, title).subscribe(
            data => {
                if (data.payload == null) {
                    if (data.statusCode == "200") {
                        this.instance.setReadOnly(true);
                        this.titleEditor.setReadOnly(true);
                        this.instance.getCommand('checkout').enable();
                        this.titleEditor.getCommand('checkout').enable();
                        this.alertService.success("Topic" + " " + this.translate.instant('WEB_EDITOR.COMMITTED SUCCESSFULLY'), true);
                        this.sharedService.setViewOnlyStatus(this.fileId, true);
                        this.loadingService.hideLoading();
                    } else {
                        console.log('[checkoutDocumentFromEditor] (error) ' + JSON.stringify(status));
                        this.alertService.error(JSON.stringify(status));
                        this.loadingService.hideLoading();
                    }
                } else {
                    console.log('[checkoutDocumentFromEditor] (error) ' + JSON.stringify(status));
                    this.alertService.error(JSON.stringify(status));
                    this.loadingService.hideLoading();
                }
            },
            error => {
                console.log('[checkoutDocumentFromEditor] (error) ' + JSON.stringify(error.statusDescription));
                if (error.statusCode == "543") {
                    this.loadConflictData(error.payload)
                    this.openModalCC(this.conflict_checker);
                    this.loadingService.hideLoading();
                } else if (error.statusCode == "0") {
					this.alertService.clear();
					this.alertService.error(this.translate.instant('WEB_EDITOR.CONNECTION_LOST'), true);
                    this.loadingService.hideLoading();
				} else {
                    if(error.statusName == "TimeoutError"){
						if(!navigator.onLine){
							this.alertService.clear();
							this.alertService.error(this.translate.instant('WEB_EDITOR.CONNECTION_LOST'), true);
							this.loadingService.hideLoading();
							return;
						}
						else{
							this.alertService.clear();
							this.alertService.error(this.translate.instant('WEB_EDITOR.CHECKIN_TIMEOUT'), true);
							this.loadingService.hideLoading();
							return;
						}
                    }
                    if(error.statusDescription == "Topic not Checked Out"){
						this.instance.setReadOnly(true);
                        this.titleEditor.setReadOnly(true);
                        this.instance.getCommand('checkout').enable();
                        this.titleEditor.getCommand('checkout').enable();
					}
                    this.alertService.clear();
                    this.alertService.error(error.statusDescription);
                    this.loadingService.hideLoading();
                }
            }
        );
        
    }

    //undo checkout document
    undoCheckout() {
        this.loadingService.showLoading(true, false, "", 0);
        this.webEditorService.undoCheckoutDocumentById(this.fileId, this.fileType).subscribe(
            data => {
                if (data.payload != null) {
                    this.loadUndoCheckoutData(data.payload);
                    this.sharedService.setViewOnlyStatus(this.fileId, true);
                    localStorage.removeItem(this.tempSaveKey);
                    this.loadingService.hideLoading();

                } else {
                    this.loadingService.hideLoading();
                }
            },
            error => {
                console.log('[undoCheckoutDocumentFromEditor] (error) ' + JSON.stringify(error.statusDescription));
                this.alertService.error(error.statusDescription);
                this.loadingService.hideLoading();
            }
        );
    }

    /**
   * Load undo checkout topic to CKEditor.
   * @param payload  
   */
    loadUndoCheckoutData(payload: any) {
        this.loadingService.hideLoading();
        if (payload != null) {
            var parser = new DOMParser();
            var topic = parser.parseFromString(payload, "text/xml");
            var topicTitle = topic.getElementsByTagName("header")[0];
            var topicContent = topic.getElementsByTagName("xml")[0];

            var serializedTopicContent = new XMLSerializer().serializeToString(topicContent);
            var serializedTopictitle = new XMLSerializer().serializeToString(topicTitle);
            if (serializedTopicContent != null) {
                var str = '<xml id="' + this.fileId + '">';
                var removeXML = serializedTopicContent.substring(
                    serializedTopicContent.lastIndexOf(str) + str.length,
                    serializedTopicContent.lastIndexOf('</xml>')
                );
                let addEditAreaXML = removeXML.replace(/<editarea[\s]+([^>]+)\/>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                addEditAreaXML = addEditAreaXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                    "<wintitle contenteditable='true'$2</wintitle>");
                let removeComXML = addEditAreaXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                removeComXML = removeComXML.replace(/<\/(tags|xref|statement)><\/p>/g, "</$1>&#8203</p>");
                setTimeout(() => {
                    this.instance.setReadOnly(true);
                    this.instance.getCommand('checkout').enable();
                    this.instance.setData(removeComXML);
                }, 500);
            }
            if (serializedTopictitle != null) {
                var removeHeader = serializedTopictitle.substring(
                    serializedTopictitle.lastIndexOf("<header>") + 8,
                    serializedTopictitle.lastIndexOf('</header>')
                );
                let addEditAreaHeaderXML = removeHeader.replace(/<editarea[\s]+([^>]+)\/>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                addEditAreaHeaderXML = addEditAreaHeaderXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                    "<wintitle contenteditable='true'$2</wintitle>");
                let removeCommHeader = addEditAreaHeaderXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                setTimeout(() => {
                    this.titleEditor.setReadOnly(true);
                    this.titleEditor.getCommand('checkout').enable();
                    this.titleEditor.setData(removeCommHeader);
                }, 500);
            }
        } else {
            this.alertService.error(this.translate.instant('COULDNT_LOAD') + this.fileType, true);
        }
    }

    //Checkout a topic from ckEditor toolbar
    checkoutTopic() {
        this.loadingService.showLoading(true, false, "", 0);
        this.webEditorService.checkoutDocumentFromEditor(this.fileId, this.fileType).subscribe(
            data => {
                if (data.payload != null) {
                    localStorage.setItem(this.tempSaveKey, data.payload);
                    this.isLoadedEditor = false;
                    this.loadCheckoutData(data.payload);
                    this.sharedService.setViewOnlyStatus(this.fileId, true);
                    this.loadingService.hideLoading();
                } else {
                    console.log('[checkoutDocumentFromEditor] (error) ');
                    this.alertService.error("Error occurred");
                    this.loadingService.hideLoading();
                }
            },
            error => {
                console.log('[checkoutDocumentFromEditor] (error) ' + JSON.stringify(error.statusDescription));
                this.alertService.error(error.statusDescription);
                this.loadingService.hideLoading();
            }
        );
    }

    /**
   * Load checkout topic to CKEditor. 
   * @param payload  
   */
    loadCheckoutData(payload: any) {
        this.loadingService.hideLoading();
        if (payload != null) {
            var parser = new DOMParser();
            var topic = parser.parseFromString(payload, "text/xml");
            var topicTitle = topic.getElementsByTagName("header")[0];
            var topicContent = topic.getElementsByTagName("xml")[0];

            var serializedTopicContent = new XMLSerializer().serializeToString(topicContent);
            var serializedTopictitle = new XMLSerializer().serializeToString(topicTitle);
            if (serializedTopicContent != null) {
                var str = '<xml id="' + this.fileId + '">';
                var removeXML = serializedTopicContent.substring(
                    serializedTopicContent.lastIndexOf(str) + str.length,
                    serializedTopicContent.lastIndexOf('</xml>')
                );
                let addEditAreaXML = removeXML.replace(/<editarea[\s]+([^>]+)\/>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                addEditAreaXML = addEditAreaXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                    "<wintitle contenteditable='true'$2</wintitle>");
                var removeComXML = addEditAreaXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                removeComXML = removeComXML.replace(/<\/(tags|xref|statement)><\/p>/g, "</$1>&#8203</p>");
                setTimeout(() => {
                    this.instance.setReadOnly(false);
                    this.instance.getCommand('checkout').disable();
                    this.instance.setData(removeComXML);
                }, 500);
            }

            if (serializedTopictitle != null) {
                var removeHeader = serializedTopictitle.substring(
                    serializedTopictitle.lastIndexOf("<header>") + 8,
                    serializedTopictitle.lastIndexOf('</header>')
                );
                let addEditAreaHeaderXML = removeHeader.replace(/<editarea[\s]+([^>]+)\/>/g,
                    "<editarea style='background-color:#ea9999;'>&nbsp;&nbsp;&nbsp;</editarea>");
                addEditAreaHeaderXML = addEditAreaHeaderXML.replace(/(<wintitle)([\s]+([^>]+)\/>)/g,
                    "<wintitle contenteditable='true'$2</wintitle>");
                var removeCommHeader = addEditAreaHeaderXML.replace(/tags\>[\s]+\<tags/g, "tags>&nbsp;<tags");
                setTimeout(() => {
                    this.titleEditor.setReadOnly(false);
                    this.titleEditor.getCommand('checkout').disable();
                    this.titleEditor.setData(removeCommHeader);
                }, 500);
            }
            setTimeout(() => {
                this.isLoadedEditor = true;
            }, 1000);
            this.sharedService.setViewOnlyStatus(this.fileId, true);
        } else {
            this.alertService.error(this.translate.instant('COULDNT_LOAD') + this.fileType, true);
        }
    }

    private s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }

    openTableWarnModal(template: TemplateRef<any>) {
        this.tableWarnModalRef = this.modalService.show(template);
    }

    openListWarnModal(template: TemplateRef<any>) {
        this.listWarnModalRef = this.modalService.show(template);
    }

    openModal2(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }

    openModalCC(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
    }

    openModalUndoCheckoutConfirm(template: TemplateRef<any>) {
        this.undocheckoutWarningModalRef = this.modalService.show(this.undo_checkout_warn);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    mcc() {
        this.selectedComItem = 'mcc'
        this.commissioning();
        this.showCategory = false;
        this.showPunchlistStatus = false;
        this.showTypeChecksheets = false;
        this.showTypeChecksheetStatus = false;
        this.showTypeChecksheetSummary = false;
        this.showVendor = false
        this.name = "mcc";
        this.punchlistsSelectedCatogory = null;
        this.punchlistSelectedStatus = null;
        this.checksheetSelectedType = "A";
        this.vendorId = null;
        this.selectedRowsString = "";
    }

    stnptn() {
        this.selectedComItem = 'stnptn'
        this.vendorSelectedType = [];
        this.commissioning();
        this.getVendors();
        this.showCategory = false;
        this.showPunchlistStatus = false;
        this.showTypeChecksheets = false;
        this.showTypeChecksheetStatus = false;
        this.showTypeChecksheetSummary = false;
        this.showVendor = true;
        this.name = "stnptn";
        this.punchlistsSelectedCatogory = null;
        this.punchlistSelectedStatus = null;
        this.checksheetSelectedType = "A";
        this.selectedRowsString = "";
    }

    punchlist() {
        this.selectedComItem = 'punchlist'
        this.punchlistsSelectedCatogory = "ALL";
        this.punchlistSelectedStatus = "Open";
        this.commissioning();
        this.showCategory = true
        this.showTypeChecksheets = false;
        this.showTypeChecksheetStatus = false;
        this.showTypeChecksheetSummary = false;
        this.showVendor = false
        this.name = "punchlist";
        this.checksheetSelectedType = "A";
        this.vendorId = null;
        this.selectedRowsString = "";
        this.showPunchlistStatus = true;
    }

    scc() {
        this.selectedComItem = 'scc'
        this.commissioning();
        this.showCategory = false;
        this.showPunchlistStatus = false;
        this.showTypeChecksheets = false;
        this.showTypeChecksheetStatus = false;
        this.showTypeChecksheetSummary = false;
        this.showVendor = false
        this.name = "scc";
        this.punchlistsSelectedCatogory = null;
        this.punchlistSelectedStatus = null;
        this.checksheetSelectedType = "A";
        this.vendorId = null;
        this.selectedRowsString = "";
    }

    checksheet() {
        this.selectedComItem = 'checksheet'
        this.commissioning();
        this.showCategory = false;
        this.showPunchlistStatus = false;
        this.showTypeChecksheets = true;
        this.showTypeChecksheetStatus = false;
        this.showTypeChecksheetSummary = false;
        this.showVendor = false;
        this.name = "checksheet";
        this.punchlistsSelectedCatogory = null;
        this.punchlistSelectedStatus = null;
        this.vendorId = null;
        this.checksheetSelectedType = "A";
        this.selectedRowsString = "";
    }

    checksheetstatus() {
        this.selectedComItem = 'ccstatus'
        this.commissioning();
        this.showCategory = false;
        this.showPunchlistStatus = false;
        this.showTypeChecksheets = false;
        this.showTypeChecksheetStatus = true;
        this.showTypeChecksheetSummary = false;
        this.showVendor = false
        this.name = "checksheetstatus";
        this.punchlistsSelectedCatogory = null;
        this.punchlistSelectedStatus = null;
        this.vendorId = null;
        this.checksheetSelectedType = "A";
        this.selectedRowsString = "";
    }

    checksheetsummary() {
        this.selectedComItem = 'ccsummary'
        this.commissioning();
        this.showCategory = false;
        this.showPunchlistStatus = false;
        this.showTypeChecksheets = false;
        this.showTypeChecksheetStatus = false;
        this.showTypeChecksheetSummary = true;
        this.showVendor = false
        this.name = "checksheetsummary";
        this.punchlistsSelectedCatogory = null;
        this.punchlistSelectedStatus = null;
        this.vendorId = null;
        this.checksheetSelectedType = "A";
        this.selectedRowsString = "";
    }


    commissioning() {
        this.webEditorService.getAllSystems()
            .subscribe(
                data => {
                    this.load(data.payload);
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
        var self = this;
        this.columnDefs = [
            { headerName: 'Select', field: 'cbox', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
            { headerName: 'System Number', field: 'systemNo', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, comparator: this.caseInsensitiveComparator },
            { headerName: 'System Name', field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true, comparator: this.caseInsensitiveComparator },
            { headerName: 'Widget', field: 'widget', hide: true },
            { headerName: 'id', field: 'id', hide: true, autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
            { headerName: 'Category', field: 'punchlistcategory', hide: true },
            { headerName: 'Type', field: 'type', hide: true },
            { headerName: 'VendorId', field: 'vendorId', hide: true }
        ]
        this.rowSelection = "multiple";
    }

    caseInsensitiveComparator(valueA, valueB) {
        if (valueA == null) valueA = '';
        if (valueB == null) valueB = '';
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }

    load(payload: any) {
        this.rowData = [];
        for (let i = 0; i < payload.length; i++) {
            this.systemIds[i] = payload[i]["id"]
            this.rowData.push({
                systemNo: payload[i]["systemNo"],
                systemName: payload[i]["systemName"],
                id: payload[i]["id"],
                widget: this.name,
                punchlistcategory: this.punchlistsSelectedCatogory,
                type: this.checksheetSelectedType,
                vendorId: this.vendorId
            })
        }
        SharedService.setTable(this.rowData);
    }

    punchlists() {
        this.webEditorService.getPunchlists()

            .subscribe(
                data => {
                    this.load(data.payload)
                    SharedService.setSCCdata(data.payload);
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
        var self = this;
        this.columnDefs = [
            { headerName: 'System Number', field: 'systemNumber', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true },
            { headerName: 'System Name', field: 'systemName', autoHeight: true, cellClass: "cell-wrap-text", minWidth: 100, enableRowGroup: true }
        ]
    }

    mcchecksheet() {
        this.webEditorService.getMCC()
            .subscribe(
                data => {
                    this.load(data.payload)
                    SharedService.setSCCdata(data.payload);
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
    }

    precommissioningData(systemId: string) {
        this.webEditorService.getPrecommissioningData(systemId)
            .subscribe(
                data => {
                    this.load(data.payload)
                    SharedService.setSCCdata(data.payload);
                },
                error => {
                    this.alertService.clear()
                    this.alertService.error(error.statusDescription)
                });
    }

    /**
     * Creates and returns random GUID.
     */
    private guid(): string {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }

    /**
     * Check for topic tables and multi level lists.
     */
    checkForTables(action: any) {
        // let content = "";
        // content = '<out>' + this.instance.getData() + '</out>';
        // var xmlParser = new DOMParser();
        // var xmlTop = xmlParser.parseFromString(content, "text/xml");
        // var tableEle = xmlTop.getElementsByTagName("table")[0]
        // var olList = xmlTop.querySelectorAll('ol li ol');
        // var ulList = xmlTop.querySelectorAll('ul li ul');
        // if (tableEle !== undefined) {
        //     if (action == "save") {
        //         this.openTableWarnModal(this.save_warn_for_tables);
        //     } else if (action == "checkin") {
        //         this.openTableWarnModal(this.checkin_warn_for_tables);
        //     }
        // } else if (olList.length > 0 || ulList.length > 0) {
        //     if (action == "save") {
        //         this.openListWarnModal(this.save_warn_for_lists_save);
        //     } else if (action == "checkin") {
        //         this.openListWarnModal(this.save_warn_for_lists_checkin);
        //     }
        // } else {
            if (action == "checkin") {
                this.openModal(this.checkin_comments);
            } else if (action == "save") {
                this.save();
         //   }
            }
    }

    addSpeChar(value: string) {
        if (value != "") {
            let decVal = Number(value);
            this.speCharList.push({ data: { symbol: this.getSymbolFromDecimal(decVal), decVal: decVal, type: "speChar" } });
        }
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    addSpeCharfraction(num: string, den: string) {
        if (den != "" && num != "") {
            var nume = parseInt(num);
            var denominator = parseInt(den);
            if (denominator != 0) {
                const myObj = {
                    numerator: num,
                    denominator: den,
                    type: 'fraction'
                };
                const myObjStr = JSON.stringify(myObj);
                let event = JSON.parse(myObjStr);

                const myObj1 = {
                    data: event
                };
                const myObjStr1 = JSON.stringify(myObj1);
                let data1 = JSON.parse(myObjStr1);
                this.insertTagEditArea(data1);
            } else {
                this.alertService.error(this.translate.instant('WEB_EDITOR.DENOMINATOR_ZERO'));
            }
        } else {
            this.alertService.error(this.translate.instant('WEB_EDITOR.NUMERATOR_AND_DENOMINATOR_EMPTY'));
        }
    }

    public autoUpdateProc() {
        //this.saveTemporary();
        let topicContent = "";
        let titleContent = "";
        let content = "";
        topicContent = this.instance.getData();
        titleContent = this.titleEditor.getData();
        content = '<topic><header>' + this.replaceEntities(titleContent) + '</header><xml id="' + this.fileId + '">' + this.replaceEntities(topicContent) + '</xml></topic>';
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(content, "text/xml");

        let selectedNodes = this.gridApi.getSelectedRows();
        let length = this.gridApi.getSelectedRows().length;
        if (length == 0) {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('WEB_EDITOR.SELECT_REUSABLES_TO_UPDATE'));            
        } else {
            this.modalRef.hide();
            for (let i = 0; i < length; i++) {
                let id = selectedNodes[i].id;
                let type = selectedNodes[i].type;
                let flag = selectedNodes[i].isDeleted;
                if (type == 'TagNo') {
                    let nodes = $(xmlDoc).find("tag#" + id);
                    for (let j = 0; j < nodes.length; j++) {
                        let fldNode = nodes[j].childNodes[0];
                        let fldName = $(fldNode).attr("name");
                        if (fldName == 'tagNo') {
                            if (flag == 'false') {
                                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(fldNode).attr("value", selectedNodes[i].newVal);
                                fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
                            } else {
                                let node = nodes[j];
                                $(node).remove();
                            }
                        }
                    }
                } if (type == 'TagSystem') {
                    let nodes = $(xmlDoc).find("tag#" + id);
                    for (let j = 0; j < nodes.length; j++) {
                        let fldNode = nodes[j].childNodes[0];
                        let fldName = $(fldNode).attr("name");
                        if (fldName == 'sysName') {
                            if (flag == 'false') {
                                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(fldNode).attr("value", selectedNodes[i].newVal);
                                fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
                            } else {
                                let node = nodes[j];
                                $(node).remove();
                            }
                        }
                    }
                } if (type == 'TagDocument') {
                    let nodes = $(xmlDoc).find("tag#" + id);
                    for (let j = 0; j < nodes.length; j++) {
                        let fldNode = nodes[j].childNodes[0];
                        let fldName = $(fldNode).attr("name");
                        if (fldName == 'docName') {
                            if (flag == 'false') {
                                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(fldNode).attr("value", selectedNodes[i].newVal);
                                fldNode.childNodes[0].textContent = selectedNodes[i].newVal;
                            } else {
                                let node = nodes[j];
                                $(node).remove();
                            }
                        }
                    }
                } else if (type == 'TagDesc') {
                    let nodes = $(xmlDoc).find("tag#" + id)
                    for (let j = 0; j < nodes.length; j++) {
                        let fldNode = nodes[j].childNodes[0];
                        let fldName = $(fldNode).attr("name");
                        if (fldName == 'tagName') {
                            if (flag == 'false') {
                                let txtNode = fldNode.childNodes[0].childNodes[0]
                                $(fldNode).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                txtNode.textContent = selectedNodes[i].newVal;
                            } else {
                                let node = nodes[j];
                                $(node).remove();
                            }
                        }
                    }
                } else if (type == 'Component' || type == 'Document' || type == 'Facility') {
                    let nodes = $(xmlDoc).find("com#" + id)
                    for (let j = 0; j < nodes.length; j++) {
                        let node = nodes[j];
                        let compType = $(node).attr("comptype");
                        if (compType == 'doc' || compType == 'com' || compType == 'fac') {
                            if (flag == 'false') {
                                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(node).attr("compvalue", selectedNodes[i].newVal);
                                node.textContent = selectedNodes[i].newVal;
                            } else {
                                $(node).remove();
                            }
                        }
                    }
                } else if (type == 'System Number') {
                    let nodes = $(xmlDoc).find("com#" + id)
                    for (let j = 0; j < nodes.length; j++) {
                        let node = nodes[j];
                        let compType = $(node).attr("compname");
                        if (compType == 'SystemNo') {
                            if (flag == 'false') {
                                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(node).attr("compvalue", selectedNodes[i].newVal);
                                node.textContent = selectedNodes[i].newVal;
                            } else {
                                $(node).remove();
                            }
                        }
                    }
                } else if (type == 'System Name') {
                    let nodes = $(xmlDoc).find("com#" + id)
                    for (let j = 0; j < nodes.length; j++) {
                        let node = nodes[j];
                        let compType = $(node).attr("compname");
                        if (compType == 'SystemName') {
                            if (flag == 'false') {
                                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(node).attr("compvalue", selectedNodes[i].newVal);
                                node.textContent = selectedNodes[i].newVal;
                            } else {
                                $(node).remove();
                            }
                        }
                    }
                } else if (type == 'Statement') {
                    let oldStmNode: any;
                    let newStmHTML: string = '';
                    let nodes = $(xmlDoc).find("statement#" + id)
                    for (let j = 0; j < nodes.length; j++) {
                        oldStmNode = nodes[j];
                        if (flag == 'false') {
                            newStmHTML = this.createEditedStatement(selectedNodes[i].name, selectedNodes[i].lastUpdateTimeStamp, oldStmNode);
                            let stmtContent =  this.escapeXml(newStmHTML);
                            oldStmNode.outerHTML = stmtContent;
                        } else {
                            $(oldStmNode).remove();
                        }
                    }
                } else if (type == 'TagAttribute') {
                    let nodes = $(xmlDoc).find("attr");
                    for (let j = 0; j < nodes.length; j++) {
                        let node = nodes[j];
                        let specId = $(node).attr("specid");
                        if(specId == id) {
                            if (flag == 'false') {
                                $(node).attr("lastupdatetimestamp", selectedNodes[i].lastUpdateTimeStamp);
                                $(node).attr("value", selectedNodes[i].newVal);
                                node.textContent = selectedNodes[i].newVal;
                            } else {
                                $(node).remove();
                            }
                        }
                    }
                }
            }
            this.loadDataConflictChecker(new XMLSerializer().serializeToString(xmlDoc));
            this.alertService.warn(this.translate.instant('WEB_EDITOR.CONTENT_UPDATE_MSG'), true);

        }
        
    }

     escapeXml(str :any) {
        return str.replace(/[&]/g, function (c) {
            switch (c) {
                case '&': return '&amp;';
            }
        });
    }

    createEditedStatement(statementContent: string, lastUpdateTimeStamp: string, node: any): string {
        this.stmHtml = '';
        let xmlDoc;
        try {
            xmlDoc = $.parseXML(statementContent);
        } catch {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
        }
        let outNode = $(xmlDoc).find('statement');
        let length = outNode[0].childNodes.length;
        let id = outNode.attr('id');
        let label = this.htmlEncode(outNode.attr('label'));
        this.stmHtml = "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + lastUpdateTimeStamp + "' contenteditable='false' id='" +
            id + "' label='" + label + "'>";
        for (let i = 0; i < length; i++) {
            let innerNode = outNode[0].childNodes[i];
            if ($(innerNode)[0].nodeName == 'txt') {
                this.stmHtml += "<txt>";
                for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
                    let childTxtNode = $(innerNode)[0].childNodes[j];
                    if ($(childTxtNode)[0].nodeName == '#text') {
                        this.stmHtml += childTxtNode.textContent;
                    } else {
                        this.processNewStatement(childTxtNode, node);
                    }
                }
                this.stmHtml += "</txt>";
            } else if ($(innerNode)[0].nodeName == 'ph') {
                this.processNewStatement($(innerNode)[0], node);
            } else if ($(innerNode)[0].nodeName == 'p') {
                this.stmHtml += '<p contenteditable="false" style="background-color:#d9d9d9;">';
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let childNode = $(innerNode)[0].childNodes[i];
                    if ($(childNode)[0].nodeName == 'txt') {
                        this.stmHtml += "<txt>";
                        for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
                            let childTxtNode = $(childNode)[0].childNodes[j];
                            if ($(childTxtNode)[0].nodeName == '#text') {
                                this.stmHtml += this.htmlEncode(childTxtNode.textContent);
                            } else {
                                this.processNewStatement(childTxtNode, node);
                            }
                        }
                        this.stmHtml += "</txt>";
                    } else if ($(childNode)[0].nodeName == 'ph') {
                        this.processNewStatement($(childNode)[0], node);
                    }
                }
                this.stmHtml += '</p> ';
            } else if ($(innerNode)[0].nodeName == 'ol' || $(innerNode)[0].nodeName == 'ul') {
                this.stmHtml += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" style="background-color:#d9d9d9;">';
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let liNode = $(innerNode)[0].childNodes[i];
                    if (liNode.nodeName == 'li') {
                        this.stmHtml += '<li>';
                        for (let i = 0; i < liNode.childNodes.length; i++) {
                            let childNode = liNode.childNodes[i];
                            if (childNode.nodeName == 'txt') {
                                $(childNode).removeAttr('style');
                                this.processNewStatement(childNode, node);
                            } else if (childNode.nodeName == 'ph') {
                                this.processNewStatement(childNode, node);
                            }
                        }
                        this.stmHtml += '</li>';
                    }
                }
                this.stmHtml += '</' + $(innerNode)[0].nodeName + '>';
            }
        }
        this.stmHtml += "</statement>";
        return this.stmHtml;
    }

    private getOldTagsNode(tagPHId:String, node: any){
		if(node.nodeName == "tags" || node.nodeName == "TAGS" || node.nodeName == "ph"){
			if($(node).attr('tagphid') == tagPHId){
				return node;
			}else{
				return null;
			}
		}else{
			for(let i=0; i<node.childNodes.length;i++){
				let tagsNode = this.getOldTagsNode(tagPHId, node.childNodes[i])
				if(tagsNode != null){
					return tagsNode;
				}
			}
		}
		return null;
	}

	private processNewStatement(node: any, oldStmNode: any) {
        if (node.nodeName == 'ph') {
            let isTags: boolean = false;
            let arrLen = node.attributes.length;
            let attrPh, attrRestriction, value, attrCatId;
            for (let n = 0; n < arrLen; n++) {
                let attrName = node.attributes[n].name;
                let attrValue = node.attributes[n].value;
                if (attrName == 'tagPHId' || attrName == 'tagphid') {
                    attrPh = attrValue;
                } else if (attrName == 'restriction') {
                    attrRestriction = attrValue;
                } else if (attrName == 'catId') {
                    attrCatId = attrValue;
                }
            }
            let oldTagsNode = this.getOldTagsNode(attrPh, oldStmNode);
			if (oldTagsNode != null) {
				this.stmHtml += oldTagsNode.outerHTML;
				isTags = true;
			}
            if (!isTags) {
                if (attrPh != undefined && attrRestriction != undefined) {
                    value = node.childNodes[0].textContent;
                    if (attrCatId != undefined) {
                        if (value != undefined) {
                            this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='"
                                + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
                        } else {
                            this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='"
                                + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
                        }
                    } else {
                        if (value != undefined) {
                            this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='"
                                + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
                        } else {
                            this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='"
                                + attrPh + "' restriction='" + attrRestriction + "'></ph>";
                        }
                    }
                } else if (attrPh != undefined && attrRestriction == undefined) {
                    this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='"
                        + attrPh + "'></ph>";
                }
            }
        } else if (node.nodeName == 'txt') {
            this.stmHtml += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.stmHtml += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.stmHtml += " fgc='" + fgc + "' "
            }
            this.stmHtml += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.stmHtml += childTxtNode.textContent;
                } else {
                    this.processNewStatement(childTxtNode, oldStmNode);
                }
            }
			this.stmHtml += "</txt>";
		} else if (node.nodeName == 'ol') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';		
		
		} else if (node.nodeName == 'ul') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';
		}else if (node.nodeName == 'b' || node.nodeName == 'u' || node.nodeName == 'i'){
			this.stmHtml += '<'+node.nodeName+'>';
			for (let i = 0; i < node.childNodes.length; i++) {		
				let childNode = node.childNodes[i];		
				this.processNewStatement(childNode, oldStmNode);		
			}
			this.stmHtml += '</'+node.nodeName+'>';
		}else if (node.nodeName == '#text'){
			this.stmHtml += node.nodeValue;
		}else {
			let str = node.outerHTML;
			if(str != undefined){
				str = str.replace(/<font.*?>/gi, '');
				str = str.replace(/<\/font>/gi, '');
				this.stmHtml += str;
			}
		}
    }

    private fireEvent( ElementId, EventName ) {
    	if( document.getElementById(ElementId) != null ) {   
        // if( document.getElementById( ElementId ).dispatchEvent(EventName) ) 
        // {
        //     document.getElementById( ElementId ).EventName( 'on' + EventName );     
        // }
        // else 
        // {   
            var evObj = document.createEvent( 'Events' );
            evObj.initEvent( EventName, true, false );
            document.getElementById( ElementId ).dispatchEvent( evObj );
        // }
    	}
	}

    private findUpTag(el, tag) {
        while (el.parentNode) {
            el = el.parentNode;
            if (el.tagName === tag)
                return el;
        }
        return null;
    }
}
