import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { DependenciesModalService } from './dependenciesmodal.service';
import { WsResponse } from '../../util/ws-response.model';
import { WsType } from '../../util/ws-type';
import { SharedService } from '../shared.service';
import { Constants } from '../constants';
import { FilesDataService } from '../../home/files/filesdata/filesdata.service';
import { FilesService } from '../../home/files/files.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage } from '../../util/localstorage.service';
import { StatementsService } from '../../reusables/statements/statements.service'
import { FacilitiesService } from '../../reusables/facilities/facilities.service'
import { SystemsService } from '../../reusables/systems/systems.service'
import { DocumentsService } from '../../reusables/documents/documents.service'
import { ComponentsService } from '../../reusables/components/components.service'
import { ClientService } from '../../client/client.service';
import { UserService } from '../../user/user.service';
import { ProjectService } from '../../project/project.service';
import { LoadingService } from '../../util/loading/loading.service';
import { ICECourseService } from '../../ice-course/ice-course-service.service';
import { PunchlistService } from '../../commissioning/punchlist/punchlist.service'
import { FilesComponent } from '../../home/files/files.component';
import { AsSidebarComponent } from 'src/app/systemsetting/admin-settings/as-sidebar/as-sidebar.component';
import { AsLoaderService } from 'src/app/systemsetting/admin-settings/as-loader.service';
import { TagsService } from '../../reusables/tags/tags.service';
import { UserVariable } from 'src/app/util/common/user-variable';
import { IceCourseComponent} from 'src/app/ice-course/ice-course.component';
import {IrnService} from "../../commissioning/irn/irn.service";
import { DocumentTypesService } from 'src/app/systemsetting/admin-settings/document-types/document-types.service';
import { FolderService } from '../../foldertree/foldertree.service';
import { OptDataService } from 'src/app/opt-data/opt-data.service';
import { ManagechecksheetsService } from 'src/app/commissioning/checksheets/managechecksheets/managechecksheets.service';
import { DecodeHtmlString } from '../common/decode-html-string.pipe';
import { ManagecheckitemsService } from 'src/app/commissioning/checksheets/managecheckitems/managecheckitems.service';
import { ManageWorkflowTemplatesService } from 'src/app/systemsetting/admin-settings/manage-workflow-templates/manage-workflow-templates.service';
@Component({
    selector: 'app-dependenciesmodal',
    templateUrl: './dependenciesmodal.component.html',
    styleUrls: ['./dependenciesmodal.component.css'],
})
export class DependenciesmodalComponent implements OnInit {

    //private dependencyNameList: any [][];
    private dependencyNameList: any[][] = [];
    private multi: string[][] = []
    private contentHeader: any = [];
    private itemType: string;
    private itemId: string;
    private action: string;
    private courseId: string;
    private dependencyMessageType: string;
    public title: String;
    private status: String;
    private clientName: String = null;
    private projectName: String = null;
    public displayModel: boolean = false;
    public displayDeleteModel: boolean = false;
    public noDependecies: boolean = false;
    private systemDependency: any;
    private topicDependency: any;
    private procDependency: any;
    private iceTopicContentDependency: any;
    private iceTopicDependency: any;
    private iceCourseDependency: any;
    private assetDependency: any;
    private checkDependency: any;
    private tagDependency: any;
    private assetParentDependency: any;
    private assetAttrDependency: any;
    private mccDependency: any;
    private sccDependency: any;
    private shcDependency: any;
    private cccDependency: any;
    private stnDependency: any;
    private punchListDependency: any;
    private topicMapDependency: any;
    private imageDependency: any;
    private folderDependency: any;
    private contentDependency: any;
    private documentDependency: any;
    private projectDependency: any;
    private componentDependency: any;
    private statementDependency: any;
    private checksheetDependency: any;
    private userDependency: any;
    private iceTopicCountDependency: any;
    private iceTopicContentCountDependency: any;
    private iceLearningCheckDependency: any;
    private iceGameDependency: any;
    private iceCourseOutput: any;
    private topicDependencies: any;
    private topicCountDependencies: any;
    private tagAttributeDependency: any;
    private hasUserDependency: boolean;
    private irnDependency: any;
    private documentTypeDependency: any;
    private topicTypeDependency: any;

    public headerTitle: String;
    public delFlag: boolean;
    public cancelFlag: boolean;
    public checkboxVal: boolean;
    public deleteConfirm: boolean;
    public deleteItem: string;
    public enableStatement: string;
    public enableDocument: string;
    public enableComponent: string;
    public enableSystem: string;
    public enableFacility: string;
    public disableStatement: string;
    public disableDocument: string;
    public disableComponent: string;
    public disableSystem: string;
    public disableFacility: string;
    public deleteStatement: string;
    public deleteDocument: string;
    public deleteComponent: string;
    public deleteSystem: string;
    public deleteFacility: string;
    public deletePunchlist: string;
    public deleteIceTopic: string;
    public disablePunchlist: string;
    public enablePunchlist: string;
    public disableChecksheet: string;
    public enableChecksheet: string;
    public deleteChecksheet: string;
    public linkedCourse: string;
    public deleted: string;
    public deleteItems: string;
    public deleteIrn: string;
    public userClickCheckbox: boolean = false;
    public deleteDocumentTypeAttribute: string;
    private multipleSelectedItems: string;
    public disableCheck: string;
    public enableCheck: string;
    public deleteCheck: string;
    public deleteWorkflowTemplate: string;
    public enableWorkflowTemplate: string;
    public disableWorkflowTemplate: string;

    constructor(public bsModalRef: BsModalRef, private sharedService: SharedService,
        private dependenciesmodalService: DependenciesModalService, private alertService: AlertService,
        private filesDataService: FilesDataService, private filesService: FilesService,
        private translate: TranslateService, private statementsService: StatementsService,
        private facilitiesService: FacilitiesService, private systemsService: SystemsService, private tagsService: TagsService,
        private documentsService: DocumentsService, private componentsService: ComponentsService, private clientService: ClientService,
        private userService: UserService, private projectService: ProjectService, private iceCourseService: ICECourseService, 
        private router: Router, private loadingService: LoadingService, private fileComponent: FilesComponent, 
        private aslService: AsLoaderService, private punchlistService: PunchlistService, private iceCourseComponent: IceCourseComponent,private irnService: IrnService,
        private documentTypeService: DocumentTypesService, private folderService: FolderService, private optService: OptDataService, private checksheetService: ManagechecksheetsService, 
        private decodeHTMLString: DecodeHtmlString, private manageCheckItemsService: ManagecheckitemsService, private workflowTemplateService: ManageWorkflowTemplatesService) {

        translate.addLangs(["es", "en"]);
        translate.setDefaultLang("en");
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
		this.translate.get('DEPENDENCIES_MODAL.DELETE_ITEM').subscribe((res: string) => {
			this.deleteItem = res;
			this.translate.get('DEPENDENCIES_MODAL.ENABLE_COMPONENT').subscribe((res: string) => {
				this.enableComponent = res;
				this.translate.get('DEPENDENCIES_MODAL.ENABLE_DOCUMENT').subscribe((res: string) => {
					this.enableDocument = res;
					this.translate.get('DEPENDENCIES_MODAL.ENABLE_FACILITY').subscribe((res: string) => {
						this.enableFacility = res;
						this.translate.get('DEPENDENCIES_MODAL.ENABLE_STATEMENT').subscribe((res: string) => {
							this.enableStatement = res;
							this.translate.get('DEPENDENCIES_MODAL.ENABLE_SYSTEM').subscribe((res: string) => {
								this.enableSystem = res;
								this.translate.get('DEPENDENCIES_MODAL.DELETE_COMPONENT').subscribe((res: string) => {
									this.deleteComponent = res;
									this.translate.get('DEPENDENCIES_MODAL.DELETE_DOCUMENT').subscribe((res: string) => {
										this.deleteDocument = res;
										this.translate.get('DEPENDENCIES_MODAL.DELETE_FACILITY').subscribe((res: string) => {
											this.deleteFacility = res;
											this.translate.get('DEPENDENCIES_MODAL.DELETE_STATEMENT_SUCCESS').subscribe((res: string) => {
												this.deleteStatement = res;
												this.translate.get('DEPENDENCIES_MODAL.DELETE_SYSTEM').subscribe((res: string) => {
													this.deleteSystem = res;
													this.translate.get('DEPENDENCIES_MODAL.DISABLE_COMPONENT').subscribe((res: string) => {
														this.disableComponent = res;
														this.translate.get('DEPENDENCIES_MODAL.DISABLE_DOCUMENT').subscribe((res: string) => {
															this.disableDocument = res;
															this.translate.get('DEPENDENCIES_MODAL.DISABLE_FACILITY').subscribe((res: string) => {
																this.disableFacility = res;
																this.translate.get('DEPENDENCIES_MODAL.DISABLE_STATEMENT').subscribe((res: string) => {
																	this.disableStatement = res;
																	this.translate.get('DEPENDENCIES_MODAL.DISABLE_SYSTEM').subscribe((res: string) => {
																		this.disableSystem = res;
																		this.translate.get('DEPENDENCIES_MODAL.DELETE_PUNCHLIST').subscribe((res: string) => {
																			this.deletePunchlist = res;
																			this.translate.get('DEPENDENCIES_MODAL.DISABLE_PUNCHLIST').subscribe((res: string) => {
																				this.disablePunchlist = res;
																				this.translate.get('DEPENDENCIES_MODAL.ENABLE_PUNCHLIST').subscribe((res: string) => {
																					this.enablePunchlist = res;
																					this.translate.get('DEPENDENCIES_MODAL.LINKED_TO_A_COURSE').subscribe((res: string) => {
																						this.linkedCourse = res;
																						this.translate.get('DEPENDENCIES_MODAL.COURSE_DELETED').subscribe((res: string) => {
																							this.deleted = res;
																							this.translate.get('DEPENDENCIES_MODAL.DELETE_ITEMS').subscribe((res: string) => {
                                                                                                this.deleteItems = res;
                                                                                                this.translate.get('DEPENDENCIES_MODAL.DELETE_IRN').subscribe((res: string) => {
                                                                                                    this.deleteIrn = res;
                                                                                                    this.translate.get('DEPENDENCIES_MODAL.DELETE_CHECKSHEET').subscribe((res: string) => {
                                                                                                        this.deleteChecksheet = res;
                                                                                                        this.translate.get('DEPENDENCIES_MODAL.DISABLE_CHECKSHEET').subscribe((res: string) => {
                                                                                                            this.disableChecksheet = res;
                                                                                                            this.translate.get('DEPENDENCIES_MODAL.ENABLE_CHECKSHEET').subscribe((res: string) => {
                                                                                                                this.enableChecksheet = res;
                                                                                                                this.translate.get('DEPENDENCIES_MODAL.DISABLE_CHECK').subscribe((res: string) => {
                                                                                                                    this.disableCheck = res;
                                                                                                                    this.translate.get('DEPENDENCIES_MODAL.ENABLE_CHECK').subscribe((res: string) => {
                                                                                                                        this.enableCheck = res;
                                                                                                                        this.translate.get('DEPENDENCIES_MODAL.DELETE_CHECK').subscribe((res: string) => {
                                                                                                                            this.deleteCheck = res;
                                                                                                                            this.translate.get('DEPENDENCIES_MODAL.DELETE_WORKFLOW_TEMPLATE').subscribe((res: string) => {
                                                                                                                                this.deleteWorkflowTemplate = res;
                                                                                                                                this.translate.get('DEPENDENCIES_MODAL.ENABLE_WORKFLOW_TEMPLATE').subscribe((res: string) => {
                                                                                                                                    this.enableWorkflowTemplate = res;
                                                                                                                                    this.translate.get('DEPENDENCIES_MODAL.DISABLE_WORKFLOW_TEMPLATE').subscribe((res: string) => {
                                                                                                                                        this.disableWorkflowTemplate = res;
                                                                                                                                        });
                                                                                                                                    });
                                                                                                                                });
                                                                                                                            });
                                                                                                                        });
                                                                                                                    });
                                                                                                                });
                                                                                                            });
                                                                                                        });
                                                                                                    });
                                                                                                });
																							});
																						});
																					});
																				});
																			});
																		});
																	});
																});
															});
														});
													});
												});
											});
										});
									});
								});
							});
						});
					});
				});
			});
		console.log(this.sharedService.getFileType())
		this.itemType = this.sharedService.getFileType();
		this.itemId = this.sharedService.getFileId();
		this.dependencyMessageType = this.sharedService.getDependencyMessageType();
		dependenciesmodalService.setCallback(this);
        this.courseId = sharedService.getCourseId();
        dependenciesmodalService.getCourseId(this.courseId);

        this.translate.get('DEPENDENCIES_MODAL.DELETE_ITEM').subscribe((res: string) => {
            this.deleteItem = res;
            this.translate.get('DEPENDENCIES_MODAL.ENABLE_COMPONENT').subscribe((res: string) => {
                this.enableComponent = res;
                this.translate.get('DEPENDENCIES_MODAL.ENABLE_DOCUMENT').subscribe((res: string) => {
                    this.enableDocument = res;
                    this.translate.get('DEPENDENCIES_MODAL.ENABLE_FACILITY').subscribe((res: string) => {
                        this.enableFacility = res;
                        this.translate.get('DEPENDENCIES_MODAL.ENABLE_STATEMENT').subscribe((res: string) => {
                            this.enableStatement = res;
                            this.translate.get('DEPENDENCIES_MODAL.ENABLE_SYSTEM').subscribe((res: string) => {
                                this.enableSystem = res;
                                this.translate.get('DEPENDENCIES_MODAL.DELETE_COMPONENT').subscribe((res: string) => {
                                    this.deleteComponent = res;
                                    this.translate.get('DEPENDENCIES_MODAL.DELETE_DOCUMENT').subscribe((res: string) => {
                                        this.deleteDocument = res;
                                        this.translate.get('DEPENDENCIES_MODAL.DELETE_FACILITY').subscribe((res: string) => {
                                            this.deleteFacility = res;
                                            this.translate.get('DEPENDENCIES_MODAL.DELETE_STATEMENT_SUCCESS').subscribe((res: string) => {
                                                this.deleteStatement = res;
                                                this.translate.get('DEPENDENCIES_MODAL.DELETE_SYSTEM').subscribe((res: string) => {
                                                    this.deleteSystem = res;
                                                    this.translate.get('DEPENDENCIES_MODAL.DISABLE_COMPONENT').subscribe((res: string) => {
                                                        this.disableComponent = res;
                                                        this.translate.get('DEPENDENCIES_MODAL.DISABLE_DOCUMENT').subscribe((res: string) => {
                                                            this.disableDocument = res;
                                                            this.translate.get('DEPENDENCIES_MODAL.DISABLE_FACILITY').subscribe((res: string) => {
                                                                this.disableFacility = res;
                                                                this.translate.get('DEPENDENCIES_MODAL.DISABLE_STATEMENT').subscribe((res: string) => {
                                                                    this.disableStatement = res;
                                                                    this.translate.get('DEPENDENCIES_MODAL.DISABLE_SYSTEM').subscribe((res: string) => {
                                                                        this.disableSystem = res;
                                                                        this.translate.get('DEPENDENCIES_MODAL.DELETE_PUNCHLIST').subscribe((res: string) => {
                                                                            this.deletePunchlist = res;
                                                                            this.translate.get('DEPENDENCIES_MODAL.DISABLE_PUNCHLIST').subscribe((res: string) => {
                                                                                this.disablePunchlist = res;
                                                                                this.translate.get('DEPENDENCIES_MODAL.ENABLE_PUNCHLIST').subscribe((res: string) => {
                                                                                    this.enablePunchlist = res;
                                                                                    this.translate.get('DEPENDENCIES_MODAL.LINKED_TO_A_COURSE').subscribe((res: string) => {
                                                                                        this.linkedCourse = res;
                                                                                        this.translate.get('DEPENDENCIES_MODAL.COURSE_DELETED').subscribe((res: string) => {
                                                                                            this.deleted = res;
                                                                                            this.translate.get('DEPENDENCIES_MODAL.DELETE_DOCUMENT_TYPE_ATTRIBUTE').subscribe((res: string) => {
                                                                                                this.deleteDocumentTypeAttribute = res;
                                                                                            });
                                                                                        });
                                                                                    });
                                                                                });
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });

        this.itemType = this.sharedService.getFileType();
        this.itemId = this.sharedService.getFileId();
        this.action = this.sharedService.getAction();
        this.dependencyMessageType = this.sharedService.getDependencyMessageType();
        this.multipleSelectedItems = this.sharedService.getMultipleSelectedItems();
        this.sharedService.setMultipleSelectedItems(null);
        dependenciesmodalService.setCallback(this);
    }

    ngOnInit() {
        if(this.multipleSelectedItems != null){
            this.dependenciesmodalService.getDependeciesMultipleItems(this.multipleSelectedItems, this.action);
        } else{
            if(this.itemType==Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPEATTRIBUTE){
                let id = JSON.parse(localStorage.getItem("jstree")).state.core.selected[0];
                this.dependenciesmodalService.getDependecies(id, Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPE, this.action);
            }
            else{
                this.dependenciesmodalService.getDependecies(this.itemId, this.itemType, this.action);
            }
        }
        localStorage.setItem("Confirm", "cancel")
        console.log("this.itemId " + JSON.stringify(this.itemId))
        console.log("this.itemType " + this.itemType)
        if (this.checkboxVal) {
            this.sharedService.setStatus("Inactive");
            this.status = "Inactive";
            console.log("set status= inactive");
        } else {
            this.sharedService.setStatus("Active");
            this.status = "Active";
            console.log("set status= active");
        }
    }

    filterDependencies(dependencies: any): any{
        let dependencyTypeList = this.sharedService.getDependencyTypeList();
        this.sharedService.resetDependencyTypeList();
        if(dependencyTypeList == null){
            return dependencies;
        }
        else{
            let newDependencies:any = {};
            
            dependencyTypeList.forEach(dependencyType => {
                newDependencies[dependencyType] = dependencies[dependencyType];
                if(newDependencies[dependencyType] != undefined && newDependencies[dependencyType] != ""){
                    newDependencies["HasDependencies"] = 1;
                }
            });
            
            return newDependencies;
        }

    }
    

    onSuccess(data: WsResponse, serviceType: WsType): void {
        console.log("dependenciesmodal.component/onSuccess" + serviceType)
        if (serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.loadingService.hideLoading();
            let response = (this.itemType == "DocumentType" && this.sharedService.getLoadedComponent() == "manageattributes") ? JSON.parse(JSON.stringify(data.payload)) : JSON.parse(data.payload);
            console.log(JSON.stringify(response));
            response = this.filterDependencies(response);
            if (response != null) {
                if (response.HasDependencies == 1) {
                    this.noDependecies = false;
                } else {
                    this.noDependecies = true;
                }
                if (response.DocumentType != undefined) {
                    this.documentTypeDependency = response.DocumentType;
                    if(this.documentTypeDependency.length != undefined && this.documentTypeDependency.length > 0){
                        this.noDependecies = false;
                    }
                }
                if (response.TopicTypeDependency != undefined) {
                    this.topicTypeDependency = response.TopicTypeDependency.TopicTypes;
                    if(this.topicTypeDependency != ''&& this.topicTypeDependency!=null){
                        if(this.topicTypeDependency.TopicType.length != undefined){
                            this.noDependecies = false;
                        }
                    }
                }
                if (response.SystemDependency != undefined && response.SystemDependency.Systems != undefined) {
                    this.systemDependency = response.SystemDependency.Systems;
                    if (this.systemDependency.System.length != undefined) {
                        for (var dependency of this.systemDependency.System) {
                            var html = dependency.SystemName;
                            var div = document.createElement("div");
                            div.innerHTML = html;
                            dependency.SystemName = div.textContent || div.innerText || "";
                        }
                    } else {
                        var html = this.systemDependency.System.SystemName;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        this.systemDependency.System.SystemName = div.textContent || div.innerText || "";
                    }
                }
                if (response.TopicDependency != undefined && response.TopicDependency.Topics != undefined) {
                    if (response.TopicDependency.Topics.Topic !== undefined) {
                        if (response.TopicDependency.Topics.Topic.length == undefined) {
                            let Topic = [];
                            Topic.push(response.TopicDependency.Topics.Topic)
                            response.TopicDependency.Topics.Topic = Topic
                        }
                    } else {
                    }
                    this.topicDependency = response.TopicDependency.Topics;
                }
                if (response.ProcedureDependency != undefined && response.ProcedureDependency.Procedures != undefined) {
                    this.procDependency = response.ProcedureDependency.Procedures;
                }

                if (response.ICECourseDependency != undefined && response.ICECourseDependency.ICECourses != undefined) {
                    this.iceCourseDependency = response.ICECourseDependency.ICECourses;
                    if (this.iceCourseDependency.ICECourse.length != undefined) {
                        for (var dependency of this.iceCourseDependency.ICECourse) {
                            var html = dependency.iceCourseName;
                            var div = document.createElement("div");
                            div.innerHTML = html;
                            dependency.iceCourseName = div.textContent || div.innerText || "";
                        }
                    } else {
                        var html = this.iceCourseDependency.ICECourse.iceCourseName;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        this.iceCourseDependency.ICECourse.iceCourseName = div.textContent || div.innerText || "";
                    }
                }

                if (response.ICETopicDependency != undefined && response.ICETopicDependency.ICETopics != undefined) {
                    this.iceTopicDependency = response.ICETopicDependency.ICETopics;
                    if (this.iceTopicDependency.ICETopic.length != undefined) {
                        for (var dependency of this.iceTopicDependency.ICETopic) {
                            var html = dependency.iceTopicName;
                            var div = document.createElement("div");
                            div.innerHTML = html;
                            dependency.iceTopicName = div.textContent || div.innerText || "";
                        }
                    } else {
                        var html = this.iceTopicDependency.ICETopic.iceTopicName;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        this.iceTopicDependency.ICETopic.iceTopicName = div.textContent || div.innerText || "";
                    }
                }
                if (response.ICETopicContentDependency != undefined && response.ICETopicContentDependency.ICETopicContents != undefined) {
                    this.iceTopicContentDependency = response.ICETopicContentDependency.ICETopicContents;
                    if (this.iceTopicContentDependency.ICETopicContent.length != undefined) {
                        for (var dependency of this.iceTopicContentDependency.ICETopicContent) {
                            var html = dependency.descriptionHTML;
                            var div = document.createElement("div");
                            div.innerHTML = html;
                            dependency.descriptionHTML = div.textContent || div.innerText || "";
                        }
                    } else {
                        var html = this.iceTopicContentDependency.ICETopicContent.descriptionHTML;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        this.iceTopicContentDependency.ICETopicContent.descriptionHTML = div.textContent || div.innerText || "";
                    }
                }
                if (response.AssetDependency != undefined && response.AssetDependency.Assets != undefined) {
                    this.assetDependency = response.AssetDependency.Assets;
                }
                if (response.AssetParentDependency != undefined && response.AssetParentDependency.AssetParents != undefined) {
                    this.assetParentDependency = response.AssetParentDependency.AssetParents;
                }
                if (response.AssetAttrDependency != undefined && response.AssetAttrDependency.AssetAttributes != undefined) {
                    this.assetAttrDependency = response.AssetAttrDependency.AssetAttributes;
                }
                if (response.TagDependency != undefined && response.TagDependency.Tags != undefined) {
                    this.tagDependency = response.TagDependency.Tags;
                }
                if (response.MCCDependency != undefined && response.MCCDependency.MCCs != undefined) {
                    this.mccDependency = response.MCCDependency.MCCs;
                }
                if (response.SCCDependency != undefined && response.SCCDependency.SCCs != undefined) {
                    this.sccDependency = response.SCCDependency.SCCs;
                }
                if (response.SHCDependency != undefined && response.SHCDependency.SHCs != undefined) {
                    this.shcDependency = response.SHCDependency.SHCs;
                }
                if (response.CCCDependency != undefined && response.CCCDependency.CCCs != undefined) {
                    this.cccDependency = response.CCCDependency.CCCs;
                }
                if (response.STNDependency != undefined && response.STNDependency.STNs != undefined) {
                    this.stnDependency = response.STNDependency.STNs;
                }
                if (response.PunchlistDependency != undefined && response.PunchlistDependency.Punchlists != undefined) {
                    this.punchListDependency = response.PunchlistDependency.Punchlists;
                }
                if (response.TopicMapDependency != undefined && response.TopicMapDependency.TopicMaps != undefined) {
                    this.topicMapDependency = response.TopicMapDependency.TopicMaps;
                }
                if (response.ImageDependency != undefined && response.ImageDependency.Images != undefined) {
                    this.imageDependency = response.ImageDependency.Images;
                }
                if (response.FolderDependency != undefined && response.FolderDependency.Folders != undefined) {
                    this.folderDependency = response.FolderDependency.Folders;
                }
                if (response.ContentDependency != undefined && response.ContentDependency.ContentItems != undefined) {
                    this.contentDependency = response.ContentDependency.ContentItems;
                }
                if (response.DocumentDependency != undefined && response.DocumentDependency.Documents != undefined) {
                    this.documentDependency = response.DocumentDependency.Documents;
                }
                if (response.ProjectDependency != undefined && response.ProjectDependency.Projects != undefined) {
                    this.projectDependency = response.ProjectDependency.Projects;
                }
                if (response.ComponentDependency != undefined && response.ComponentDependency.Components != undefined) {
                    this.componentDependency = response.ComponentDependency.Components;
                }
                if (response.StatementDependency != undefined && response.StatementDependency.Statements != undefined) {
                    this.statementDependency = response.StatementDependency.Statements;
                }
                if (response.CheckSheetDependency != undefined && response.CheckSheetDependency.CheckSheets != undefined) {
                    this.checksheetDependency = response.CheckSheetDependency.CheckSheets;
                }
                if (response.UserDependency != undefined && response.UserDependency.Users != undefined) {
                    if (response.UserDependency !== "") {
                        this.hasUserDependency = false;
                    } else {
                        this.hasUserDependency = true;
                    } 
                    this.userDependency = response.UserDependency.Users;
                }
                if (response.LearningCheck != undefined) {
                    this.iceLearningCheckDependency = response.LearningCheck;
                    this.noDependecies = false;
                }
                if (response.ICELearningCheckDependency != undefined && response.ICELearningCheckDependency.ICELearningChecks != undefined) {
                    this.iceLearningCheckDependency = response.ICELearningCheckDependency.ICELearningChecks;
                    if (this.iceLearningCheckDependency.ICELearningCheck.length != undefined) {
                        for (var dependency of this.iceLearningCheckDependency.ICELearningCheck) {
                            var html = dependency.iceLearningCheckDescription;
                            var div = document.createElement("div");
                            div.innerHTML = html;
                            dependency.iceLearningCheckDescription = div.textContent || div.innerText || "";
                        }
                    } else {
                        var html = this.iceLearningCheckDependency.ICELearningCheck.iceLearningCheckDescription;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        this.iceLearningCheckDependency.ICELearningCheck.iceLearningCheckDescription = div.textContent || div.innerText || "";
                    }
                }
                if (response.ICEGameDependency != undefined && response.ICEGameDependency.ICEGames != undefined) {
                    this.iceGameDependency = response.ICEGameDependency.ICEGames;
                    if (this.iceGameDependency.ICEGame.length != undefined) {
                        for (var dependency of this.iceGameDependency.ICEGame) {
                            var html = dependency.iceGameDescription;
                            var div = document.createElement("div");
                            div.innerHTML = html;
                            dependency.iceGameDescription = div.textContent || div.innerText || "";
                        }
                    } else {
                        var html = this.iceGameDependency.ICEGame.iceGameDescription;
                        var div = document.createElement("div");
                        div.innerHTML = html;
                        this.iceGameDependency.ICEGame.iceGameDescription = div.textContent || div.innerText || "";
                    }
                }
                if(response.TagAttributeDependency != undefined && response.TagAttributeDependency.TagAttribute != undefined){
                    this.tagAttributeDependency = response.TagAttributeDependency.TagAttribute;
                }
                if (response.count != undefined) {
                    this.iceTopicCountDependency = response.count;
                    this.topicDependencies = response.HasDependencies;
                    if (response.count != 0 || response.topicContentCount != 0){
                        this.delFlag = true;
                        this.checkboxVal = false;
                    }
                }
                if (response.topcContentCount != undefined) {
                    this.iceTopicContentCountDependency = response.topcContentCount;
                    this.topicCountDependencies = response.HasDependencies;
                }
                //commented ice course output checking as a dependancy
                //later if we want to add it we need to check the value of response.outputCourse 
                //as it comes as "There are no dependancies" when no dependacies
                //it's != undefined so it will set this.noDependecies = false;
                /*if (response.outputCourse != undefined) {
                    this.iceCourseOutput = response.outputCourse;
                    this.noDependecies = false;
                }*/
                if (response.HasDependencies != 1 && localStorage.getItem("isDelete") === "true") {
                    if(this.multipleSelectedItems != null){
                        this.headerTitle = this.translate.instant('MULTIPLE_ACTION.DELETE')
                    } else{
                        this.headerTitle = this.translate.instant('DEPENDENCIES_MODAL.DELETE_ITEM')
                    }
                }
                //Achini Randeni
                //set IRN dependency
                if (response.IRNDependency != undefined && response.IRNDependency.IRNs != undefined) {
                    this.irnDependency = response.IRNDependency.IRNs;
                    this.noDependecies = false;
                }
                //Set check dependency
                if (response.CheckDependency != undefined && response.CheckDependency.Checks != undefined) {
                    let escapedDep = response.CheckDependency.Checks;
                    if(escapedDep.Check.length != undefined) {
                        for(var dep of escapedDep.Check) {
                            dep.dependencyName = this.decodeHTMLString.transform(dep.dependencyName);
                        }
                    }
                    this.checkDependency = escapedDep;
                }
            } else {
                this.noDependecies = true;
            }
            this.displayModel = true;
        }
        else if (serviceType == WsType.ITEM_DISABLE_ENABLE) {
            this.sharedService.setDependencyMessageType(null);
            if (this.itemType == "Statement") {
                this.statementsService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableStatement)
                }
                else {
                    this.alertService.success(this.disableStatement)
                }
            }
            else if (this.itemType == "Facility") {
                console.log("enable disable data" + this.title)
                this.facilitiesService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableFacility)
                }
                else {
                    this.alertService.success(this.disableFacility)
                }
            }
            else if (this.itemType == "System") {
                this.systemsService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableSystem)
                }
                else {
                    this.alertService.success(this.disableSystem)
                }
            }
            else if (this.itemType == "Document") {
                this.documentsService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableDocument)
                }
                else {
                    this.alertService.success(this.disableDocument)
                }
            }
            else if (this.itemType == "Component") {
                this.componentsService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableComponent)
                }
                else {
                    this.alertService.success(this.disableComponent)
                }
            }
            else if (this.itemType == "Client") {
                this.clientService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
            }
            else if (this.itemType == "User") {
                this.userService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
            }
            else if (this.itemType == "Project") {
                this.projectService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
            } else if (this.itemType == "PunchList") {
                this.punchlistService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enablePunchlist)
                }
                else {
                    this.alertService.success(this.disablePunchlist)
                }
            } else if (this.itemType == "DocumentType") {
                this.sharedService.documentTypeJstreeRefreshRequest();
            }
            else if (this.itemType == "Workflow") {
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableWorkflowTemplate)
                }
                else {
                    this.alertService.success(this.disableWorkflowTemplate)
                }
                this.sharedService.viewSideBarEventEmit("manageWfTemplates")
            }
            else if (this.itemType == "CheckSheet") {
                this.checksheetService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableChecksheet)
                }
                else {
                    this.alertService.success(this.disableChecksheet)
                }
            } else if (this.itemType == "Check") {
                this.manageCheckItemsService.disableEnableFileTableData(this.itemId, this.title, this.status);
                this.status = this.status == "Inactive" ? "Active" : "Inactive"
                this.alertService.clear();
                if (this.status == "Active") {
                    this.alertService.success(this.enableCheck)
                }
                else {
                    this.alertService.success(this.disableCheck)
                }
            }
            else {
                this.loadingService.showLoading(true, false, this.translate.instant("LOADING"), 0);
                let callback = this.sharedService.getParent();
                this.filesDataService.getBasicFileData(this.itemId, this.itemType, callback, "enableDisable");
				/*if (this.sharedService.getParent() != null) {
					this.sharedService.getParent().setButtonStatus();
				}*/
            }
        } else if (data != null && serviceType == WsType.BASIC_FILE_DATA) {
            // Recieving this response is set to files.component.ts
            this.filesService.disableEnableFileTableData(data.payload.lastUpdateTimeStamp, this.itemId, this.title, this.status);

        }
        else if (data != null && serviceType == WsType.DELETE_ITEM) {
            console.log("doc data DELETE_ITEM");
            console.log(this.itemType);
            this.sharedService.setDependencyMessageType(null);
            //this.fileComponent.resetenableDisableButtons();
            this.sharedService.resetEnableDisableButtonsEventEmit();

            if (this.itemType == "Statement") {
                this.statementsService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteStatement)
            }
            else if (this.itemType == "Facility") {
                console.log("DELETE_ITEM - fac ");
                this.facilitiesService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteFacility)
            }
            else if (this.itemType == "System") {
                this.systemsService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteSystem)
            }
            else if (this.itemType == "Document") {
                console.log("DELETE_ITEM - doc ");
                this.documentsService.deleteFileTableData(this.itemId)
                this.alertService.clear();
                this.alertService.success(this.deleteDocument);
            }
            else if (this.itemType == "Component") {
                console.log("DELETE_ITEM - comp ");
                this.componentsService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteComponent)
            }
            else if (this.itemType == "Client") {
                console.log("DELETE_ITEM - comp ");
                this.clientService.deleteFileTableData(this.itemId);
            }
            else if (this.itemType == "User") {
                console.log("DELETE_ITEM - comp ");
                this.userService.deleteFileTableData(this.itemId);
            }
            else if (this.itemType == "Project") {
                console.log("DELETE_ITEM - comp ");
                this.projectService.deleteFileTableData(this.itemId);
            }
            else if (this.itemType == "ICECourse") {
                console.log("DELETE_ITEM - ICECourse ");
                if (data.payload != false) {
                    this.filesService.deleteFileTableData(this.itemId);
                    this.sharedService.deletedTabCloseRequest(this.itemId);
                    this.alertService.success(this.deleted);
                } else {
                    this.alertService.warn(this.linkedCourse);
                }
            }
            else if (this.itemType == "PunchList") {
                console.log("DELETE_ITEM - comp ");
                this.punchlistService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deletePunchlist);
            }
            else if (this.itemType == "Punchlist") {
                console.log("DELETE_ITEM - comp ");
                this.punchlistService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deletePunchlist);
            } else if (this.itemType == "DocumentType") {
                this.documentTypeService.deleteFileTableData(this.itemId);
                if (this.sharedService.getLoadedComponent() == "documenttype") {
                  this.sharedService.documentTypeJstreeRefreshRequest();
                } else if(this.sharedService.getLoadedComponent() == "manageattributes") {
                    this.alertService.clear();
                    this.alertService.success(this.deleteDocumentTypeAttribute);
                }        
            } else if (this.itemType == "ICETopic"){
                this.sharedService.deleteICETopicDoneRequest(this.sharedService.getIsICETopic());
            } else if (this.itemType == "IRN") {
                this.irnService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteIrn);
            } else if (this.itemType == "CheckSheet") {
                this.checksheetService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteChecksheet);
            } else if (this.itemType == "Check") {
                this.manageCheckItemsService.deleteFileTableData(this.itemId);
                this.alertService.clear();
                this.alertService.success(this.deleteCheck);
            } else if(this.itemType == "Workflow"){
                this.alertService.clear();
                this.alertService.success(this.deleteWorkflowTemplate);
                this.sharedService.viewSideBarEventEmit("manageWfTemplates")
            }
            else {
                this.filesService.deleteFileTableData(this.itemId);
                if (this.itemType == "Procedure" || this.itemType == "Topic") {
                    this.sharedService.deletedTabCloseRequest(this.itemId);
                }
                else if (this.itemType == "TopicMap") {
                    this.sharedService.tabCloseBtnClickRequest();
                    this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);
                }
            }
            this.loadingService.hideLoading();
        }else if(data != null && serviceType == WsType.MULTIPLE_ACTIONS){
            //this.loadingService.showLoading(true, false, 'File Loading', 0);
            this.sharedService.getParent().onSuccess(data, WsType.MULTIPLE_ACTIONS);
            // this.bsModalRef.hide();
            // if (this.bsModalRef != undefined) {
            //     this.bsModalRef.hide();
            // }
            // let items = this.multipleSelectedItems.split("::");

            // items.forEach(item => {
            //     if(item != ''){
            //         this.itemType = item.substring(0,item.indexOf("|"));
            //         this.itemId = item.substring(item.indexOf("|")+1, item.length);

            //         //this.filesService.deleteFileTableData(this.itemId);

            //         if (this.itemType == "Procedure" || this.itemType == "Topic") {
            //             this.sharedService.deletedTabCloseRequest(this.itemId);
            //         }
            //         else if (this.itemType == "TopicMap") {
            //             this.sharedService.tabCloseBtnClickRequest();
            //             this.sharedService.changeLeftSideItemRequest('FOLDER_EXPLORER', false);
            //         }
            //         else if (this.itemType == "ICETopic"){
            //             this.sharedService.deleteICETopicDoneRequest(this.sharedService.getIsICETopic());
            //         }
            //     }
            // });
            // this.loadingService.hideLoading();
            // this.sharedService.folderRefreshRequest(this.folderService.getSelectedFoldeData().id);
            // this.alertService.clear();
            // this.alertService.success("successfully deleted the selected items");
        }
        else if (data != null && serviceType == WsType.DELETE_OPERATING_DATA){
            this.sharedService.getParent().onSuccess(data, WsType.DELETE_OPERATING_DATA);
        }
    }

    // 		} else if (this.itemType == "User") {
    // 			if ((data.payload != null)) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			} else {
    // 				this.displayModel = true;
    // 				if (localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// }
    // 			}	// if (this.headerTitle == this.translate.instant('STATEMENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('STATEMENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('STATEMENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 			// 	this.confirm(this.headerTitle, false, false)
    // 		} else if (this.itemType == "Client") {
    // 			//console.log("data.payload.length " + data.payload.length)
    // 			if ((data.payload != null )) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			}
    // 			else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// }
    // 			}	// if (this.headerTitle == this.translate.instant('STATEMENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('STATEMENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('STATEMENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 			// 	this.confirm(this.headerTitle, false, false)

    // 		}

    // 		else if (this.itemType == "Project") {
    // 			if ((data.payload != null)) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			}
    // 			else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// }
    // 			}	// if (this.headerTitle == this.translate.instant('STATEMENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('STATEMENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('STATEMENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 			// 	this.confirm(this.headerTitle, false, false)

    // 		}

    // if (this.itemType == "Facility") {
    // 	debugger;
    // 	if ((response != null)) {
    // 		this.displayModel = true;
    // 		this.loadDependencyList(response)
    // 	}
    // 	else {
    // 		//this.displayModel = false;
    // 		this.displayModel = true;
    // 		if(localStorage.getItem("isDelete") === "true")
    // 		{
    // 			this.headerTitle = this.deleteItem;
    // 		}
    // if (this.headerTitle == this.translate.instant('FACILITY_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('FACILITY_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('FACILITY_CELL_RENDER.ENABLE_CONFIRM')) {
    // 	this.confirm(this.headerTitle, false, false)
    // }
    //}
    //}

    // 		else if (this.itemType == "System") {
    // 			// console.log(data.payload)
    // 			if ((data.payload != null)) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			}
    // 			else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// if (this.headerTitle == this.translate.instant('SYSTEM_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('SYSTEM_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('SYSTEM_CELL_RENDER.ENABLE_CONFIRM')) {
    // 				// 	this.confirm(this.headerTitle, false, false)
    // 				// }
    // 			}
    // 		}

    // 		else if (this.itemType == "Document") {
    // 			console.log("======payload======")
    // 			//console.log(data.payload &&)
    // 			if ((data.payload != null)) {
    // 				console.log("doc- payload not null");
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			}
    // 			else {
    // 				console.log("delete document")
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// console.log("doc- payload null");
    // 				// this.displayModel = false;
    // 				// if (this.headerTitle == this.translate.instant('DOCUMENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('DOCUMENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('DOCUMENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 				// 	this.confirm(this.headerTitle, false, false)
    // 				// 	console.log("DOCUMENT_CELL_RENDER.DELETE_CONFLICTS");
    // 				// }
    // 			}
    // 		}

    // 		else if (this.itemType == "Component") {
    // 			// console.log("======payload======")
    // 			// console.log(data.payload)
    // 			if ((data.payload != null)) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			}
    // 			else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// if (this.headerTitle == this.translate.instant('COMPONENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('COMPONENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('COMPONENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 				// 	this.confirm(this.headerTitle, false, false)
    // 				// }
    // 			}
    // 		}

    // 		else if (this.itemType == "DocumentType") {
    // 			 console.log("======payload======")
    // 			 console.log(data.payload)
    // 			if ((data.payload != null)) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			}
    // 			else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				}
    // 				// if (this.headerTitle == this.translate.instant('COMPONENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('COMPONENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('COMPONENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 				// 	this.confirm(this.headerTitle, false, false)
    // 				// }
    // 			}
    // 		}


//NAD COMM
//				items++;
//			}
//			if (payload["ICECourseOutput"] != null && payload["ICECourseOutput"] != undefined) {
				//let items = count;
//				for (var variable in payload["ICECourseOutput"]) {
//					this.contentHeader[count] = variable;
//				}
//				count++;
				
				
				
				
				// let j = 0;
				// this.multi[count - 1] = [];
				// this.dependencyNameList[items] = [];
				// for (var variable in payload["ICECourseOutput"]) {
				// 	this.multi[count - 1][j] = variable;
				// 	// this.dependencyNameList[items][j] = [];
				// 	// for (var item in payload["ICECourseOutput"][variable]) {
				// 	// 	var html = payload["ICECourseOutput"][variable][item]["name"];
				// 	// 	var div = document.createElement("div");
				// 	// 	div.innerHTML = html;
				// 	// 	var text = div.textContent || div.innerText || div.style || "";
				// 	// 	let value = text + "(" + + payload["ICECourseOutput"][variable][item]["referenceCount"] + ")";
				// 	// 	if( payload["ICECourseOutput"][variable][item]["folderPath"] != null){
				// 	// 		value +=  "[" + payload["ICECourseOutput"][variable][item]["folderPath"] + "]";
				// 	// 	}
				// 	// 	if(payload["ICECourseOutput"][variable][item]["checkoutBy"] != null && payload["ICECourseOutput"][variable][item]["checkoutBy"] != ""){
				// 	// 		value += "-" + payload["ICECourseOutput"][variable][item]["checkoutBy"];
				// 	// 	}
				// 	// 	this.dependencyNameList[items][j].push({
				// 	// 		'displayName': value
				// 	// 	})
				// 	// }
				// 	j++;
				// }

				//items++;
				
//NAD COMM				
//			}
//			if (payload["ICETopic"] != null && payload["ICETopic"] != undefined) {
//				let items = count;
//				this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICETOPICS');
//				count++;
//				let j = 0;
//				this.multi[count - 1] = [];
//				this.dependencyNameList[items] = [];
//				for (var variable in payload["ICETopic"]) {
//					this.multi[count - 1][j] = variable;
//					this.dependencyNameList[items][j] = [];
//					for (var item in payload["ICETopic"][variable]) {
//						var html =  payload["ICETopic"][variable][item]["name"];
//						var div = document.createElement("div");
//						div.innerHTML = html;
//						var text = div.textContent || div.innerText || div.style || "";
//						let value = text+ "(" + + payload["ICETopic"][variable][item]["referenceCount"] + ")";
//						if( payload["ICETopic"][variable][item]["folderPath"] != null){
//							value +=  "[" + payload["ICETopic"][variable][item]["folderPath"] + "]";
//						}
//						if(payload["ICETopic"][variable][item]["checkoutBy"] != null && payload["ICETopic"][variable][item]["checkoutBy"] != ""){
//							value += "-" + payload["ICETopic"][variable][item]["checkoutBy"];
//						}
//						this.dependencyNameList[items][j].push({
//							'displayName': value
//						})
//					}
//					j++;
//				}



    // 		else {
    // 			console.log("Dependencies for " + this.itemType + ": " + JSON.stringify(data.payload));
    // 			if (data.payload != null) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			} else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				} else{
    // 					// nothing to do
    // 				}
    // 			}
    // 		}


//NAD COMM
//				items++;
//			}
//			if (payload["LinkedICETopic"] != null && payload["LinkedICETopic"] != undefined) {
//				let items = count;
//				this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.LINKED_ICETOPICS');
//				count++;
//				let j = 0;
//				this.multi[count - 1] = [];
//				this.dependencyNameList[items] = [];
//				for (var variable in payload["LinkedICETopic"]) {
//					this.multi[count - 1][j] = variable;
//					this.dependencyNameList[items][j] = [];
//					for (var item in payload["LinkedICETopic"][variable]) {
//						var html =  payload["LinkedICETopic"][variable][item]["name"];
//						var div = document.createElement("div");
//						div.innerHTML = html;
//						var text = div.textContent || div.innerText || div.style || "";
//						let value = text+ "(" + + payload["LinkedICETopic"][variable][item]["referenceCount"] + ")";
//						if( payload["LinkedICETopic"][variable][item]["folderPath"] != null){
//							value +=  "[" + payload["LinkedICETopic"][variable][item]["folderPath"] + "]";
//						}
//						if(payload["LinkedICETopic"][variable][item]["checkoutBy"] != null && payload["LinkedICETopic"][variable][item]["checkoutBy"] != ""){
//							value += "-" + payload["LinkedICETopic"][variable][item]["checkoutBy"];
//						}
//						this.dependencyNameList[items][j].push({
//							'displayName': value
//						})
//					}
//					j++;
//				}
//
//				items++;
//			}




			// if (payload["ICETopicContent"] != null && payload["ICETopicContent"] != undefined) {
			// 	let items = count;
			// 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICETOPICCONTENTS');
			// 	count++;
			// 	let j = 0;
			// 	this.multi[count - 1] = [];
			// 	this.dependencyNameList[items] = [];
			// 	for (var variable in payload["ICETopicContent"]) {
			// 		this.multi[count - 1][j] = variable;
			// 		this.dependencyNameList[items][j] = [];
			// 		for (var item in payload["ICETopicContent"][variable]) {
			// 			//let value = payload["ICETopicContent"][variable][item]["name"] + "(" + + payload["ICETopicContent"][variable][item]["referenceCount"] + ")";
			// 			var html =  payload["ICETopicContent"][variable][item]["descriptionHTML"];
			// 			var div = document.createElement("div");
			// 			div.innerHTML = html;
			// 			var text = div.textContent || div.innerText || div.style || "";
			// 			let value = text+ "(" + + payload["ICETopicContent"][variable][item]["referenceCount"] + ")";
			// 			if( payload["ICETopicContent"][variable][item]["folderPath"] != null){
			// 				value +=  "[" + payload["ICETopicContent"][variable][item]["folderPath"] + "]";
			// 			}
			// 			if(payload["ICETopicContent"][variable][item]["checkoutBy"] != null && payload["ICETopicContent"][variable][item]["checkoutBy"] != ""){
			// 				value += "-" + payload["ICETopicContent"][variable][item]["checkoutBy"];
			// 			}
			// 			this.dependencyNameList[items][j].push({
			// 				'displayName': value
			// 			})
			// 		}
			// 		j++;
            //     }
                


    // 		// else if (this.itemType == "Punchlist") {
    // 		// 	// console.log("======payload======")
    // 		// 	// console.log(data.payload)
    // 		// 	if ((data.payload != null)) {
    // 		// 		this.displayModel = true;
    // 		// 		this.loadDependencyList(data.payload)
    // 		// 	}
    // 			// else {
    // 			// 	this.displayModel = true;
    // 			// 	if(localStorage.getItem("isDelete") === "true")
    // 			// 	{
    // 			// 		this.headerTitle = this.deleteItem;
    // 			// 	}
    // 				// if (this.headerTitle == this.translate.instant('COMPONENT_CELL_RENDER.DELETE_CONFLICTS') || this.translate.instant('COMPONENT_CELL_RENDER.DISABLE_CONFIRM') || this.headerTitle == this.translate.instant('COMPONENT_CELL_RENDER.ENABLE_CONFIRM')) {
    // 				// 	this.confirm(this.headerTitle, false, false)
    // 		 		// }
    // 		// 	}
    // 		// }

			// 	items++;
			// }
			// if (payload["LinkedICETopicContent"] != null && payload["LinkedICETopicContent"] != undefined) {
			// 	let items = count;
			// 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.LINKED_ICETOPICCONTENTS');
			// 	count++;
			// 	let j = 0;
			// 	this.multi[count - 1] = [];
			// 	this.dependencyNameList[items] = [];
			// 	for (var variable in payload["LinkedICETopicContent"]) {
			// 		this.multi[count - 1][j] = variable;
			// 		this.dependencyNameList[items][j] = [];
			// 		for (var item in payload["LinkedICETopicContent"][variable]) {
			// 			//let value = payload["LinkedICETopicContent"][variable][item]["name"] + "(" + + payload["LinkedICETopicContent"][variable][item]["referenceCount"] + ")";
			// 			var html =  payload["LinkedICETopicContent"][variable][item]["descriptionHTML"];
			// 			var div = document.createElement("div");
			// 			div.innerHTML = html;
			// 			var text = div.textContent || div.innerText || div.style || "";
			// 			let value = text+ "(" + + payload["LinkedICETopicContent"][variable][item]["referenceCount"] + ")";
			// 			if( payload["LinkedICETopicContent"][variable][item]["folderPath"] != null){
			// 				value +=  "[" + payload["LinkedICETopicContent"][variable][item]["folderPath"] + "]";
			// 			}
			// 			if(payload["LinkedICETopicContent"][variable][item]["checkoutBy"] != null && payload["LinkedICETopicContent"][variable][item]["checkoutBy"] != ""){
			// 				value += "-" + payload["LinkedICETopicContent"][variable][item]["checkoutBy"];
			// 			}
			// 			this.dependencyNameList[items][j].push({
			// 				'displayName': value
			// 			})
			// 		}
			// 		j++;
			// 	}

			// 	items++;
			// }
			// if (payload["ICELearningCheck"] != null && payload["ICELearningCheck"] != undefined) {
			// 	let items = count;
			// 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICELEARNINGCHECKS');
			// 	count++;
			// 	let j = 0;
			// 	this.multi[count - 1] = [];
			// 	this.dependencyNameList[items] = [];
			// 	for (var variable in payload["ICELearningCheck"]) {
			// 		this.multi[count - 1][j] = variable;
			// 		this.dependencyNameList[items][j] = [];
			// 		for (var item in payload["ICELearningCheck"][variable]) {
			// 			var html =  payload["ICELearningCheck"][variable][item]["descriptionHTML"];
			// 			var div = document.createElement("div");
			// 			div.innerHTML = html;
			// 			var text = div.textContent || div.innerText || div.style || "";
			// 			let value = text+ "(" + + payload["ICELearningCheck"][variable][item]["referenceCount"] + ")";
			// 			if( payload["ICELearningCheck"][variable][item]["folderPath"] != null){
			// 				value +=  "[" + payload["ICELearningCheck"][variable][item]["folderPath"] + "]";
			// 			}
			// 			if(payload["ICELearningCheck"][variable][item]["checkoutBy"] != null && payload["ICELearningCheck"][variable][item]["checkoutBy"] != ""){
			// 				value += "-" + payload["ICELearningCheck"][variable][item]["checkoutBy"];
			// 			}
			// 			this.dependencyNameList[items][j].push({
			// 				'displayName': value
			// 			})
			// 		}
			// 		j++;
			// 	}
			// 	items++;			
			// }
			// if (payload["Topic"] != null && payload["Topic"] != undefined) {
			// 	let items = count;
			// 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.TOPICS');
			// 	count++;
			// 	let j = 0;
			// 	this.multi[count - 1] = [];
			// 	this.dependencyNameList[items] = [];
			// 	for (var variable in payload["Topic"]) {
			// 		this.multi[count - 1][j] = variable;
			// 		this.dependencyNameList[items][j] = [];
			// 		for (var item in payload["Topic"][variable]) {
			// 			let value = payload["Topic"][variable][item]["name"] + "(" + + payload["Topic"][variable][item]["referenceCount"] + ")";
			// 			if( payload["Topic"][variable][item]["folderPath"] != null){
			// 				value +=  "[" + payload["Topic"][variable][item]["folderPath"] + "]";
			// 			}
			// 			if(payload["Topic"][variable][item]["checkoutBy"] != null && payload["Topic"][variable][item]["checkoutBy"] != ""){
			// 				value += "-" + payload["Topic"][variable][item]["checkoutBy"];
			// 			}
			// 			/*this.dependencyNameList[items][j].push({
			// 				'displayName': payload["Topic"][variable][item]["name"] + "(" + + payload["Topic"][variable][item]["referenceCount"] + ")" + "[" + payload["Topic"][variable][item]["folderPath"] + "]" + "-" + payload["Topic"][variable][item]["checkoutBy"]
			// 			});*/
			// 			this.dependencyNameList[items][j].push({
			// 				'displayName': value
			// 			});
			// 			console.log(value);
			// 		}
			// 		j++;
            //     }
                



    // 		else {
    // 			console.log("Dependencies for " + this.itemType + ": " + JSON.stringify(data.payload));
    // 			if (data.payload != null) {
    // 				this.displayModel = true;
    // 				this.loadDependencyList(data.payload)
    // 			} else {
    // 				this.displayModel = true;
    // 				if(localStorage.getItem("isDelete") === "true")
    // 				{
    // 					this.headerTitle = this.deleteItem;
    // 				} else{
    // 					// nothing to do
    // 				}
    // 			}
    // 		}
    // 		if (this.itemType == "Statement") {
    // 			this.statementsService.deleteFileTableData(this.itemId);
    // 			this.alertService.clear();
    // 			this.alertService.success(this.deleteStatement)
    // 		}
    // 		else if (this.itemType == "Facility") {
    // 			console.log("DELETE_ITEM - fac ");
    // 			this.facilitiesService.deleteFileTableData(this.itemId);
    // 			this.alertService.clear();
    // 			this.alertService.success(this.deleteFacility)
    // 		}
    // 		else if (this.itemType == "System") {
    // 			this.systemsService.deleteFileTableData(this.itemId);
    // 			this.alertService.clear();
    // 			this.alertService.success(this.deleteSystem)
    // 		}
    // 		else if (this.itemType == "Document") {
    // 			console.log("DELETE_ITEM - doc ");
    // 			this.documentsService.deleteFileTableData(this.itemId)
    // 			this.alertService.clear();
    // 			this.alertService.success(this.deleteDocument);
    // 		}
    // 		else if (this.itemType == "Component") {
    // 			console.log("DELETE_ITEM - comp ");
    // 			this.componentsService.deleteFileTableData(this.itemId);
    // 			this.alertService.clear();
    // 			this.alertService.success(this.deleteComponent)
    // 		}
    // 		else if (this.itemType == "Client") {
    // 			console.log("DELETE_ITEM - comp ");
    // 			this.clientService.deleteFileTableData(this.itemId);
    // 		}
    // 		else if (this.itemType == "User") {
    // 			console.log("DELETE_ITEM - comp ");
    // 			this.userService.deleteFileTableData(this.itemId);
    // 		}
    // 		else if (this.itemType == "Project") {
    // 			console.log("DELETE_ITEM - comp ");
    // 			this.projectService.deleteFileTableData(this.itemId);
    // 		}
    // 		/*else if (this.itemType == "ICECourse"){
    // 			console.log("DELETE_ITEM - ICECourse ");
    // 			this.iceCourseService.deleteFileTableData(this.itemId);
    // 		}*/
    // 		else {
    // 			this.filesService.deleteFileTableData(this.itemId);
    // 		}
    // 		this.loadingService.hideLoading();
    //}



    onFail(data: WsResponse, serviceType: WsType): void {
        console.log("dependenciesmodal.component/onFail" + serviceType)

        this.loadingService.hideLoading();
        if (data != null && serviceType == WsType.ITEM_DEPENDENCY_LIST) {
            this.alertService.clear();
            this.alertService.error(data.statusDescription, true);
        }
        else if (data != null && serviceType == WsType.ITEM_DISABLE_ENABLE) {
            this.alertService.clear();
            this.alertService.error(data.statusDescription, true);
        }
        else if (data != null && serviceType == WsType.DELETE_ITEM){
            this.alertService.clear();
            this.alertService.error(data.statusDescription, true);
        }
        else {
            this.alertService.clear();
            this.alertService.error(this.translate.instant('DEPENDENCIES_MODAL.LOAD_ERROR'), true);
        }
    }

    // loadDependencyList(response: any) {
    // 	console.log("loadDependencyList");
    // 	console.log(JSON.stringify(response));
    // 	if (response != null) {
    // 		debugger;
    // 		let itemList: any = [];
    // 		//	this.dependencyNameList = [[], []]
    // this.contentHeader = [];
    // 		// if (this.itemType == "Topic") {
    // 		// 	this.contentHeader[0] = this.translate.instant('DEPENDENCIES_MODAL.TOPICMAPS');
    // 		// 	itemList = payload.TopicMap;
    // 		// 	if (itemList.length > 0) {
    // 		// 		for (let i = 0; i <= Constants.MAX_DEPENDENCY_DISPLAY_COUNT; i++) {
    // 		// 			if (itemList[i] != undefined) {
    // 		// 				this.dependencyNameList[0].push({
    // 		// 					'displayName': itemList[i]["topicMapName"]
    // 		// 				})
    // 		// 			}
    // 		// 		}
    // 		// 	} else {
    // 		// 		this.contentHeader[0] = this.translate.instant('DEPENDENCIES_MODAL.NO_DEPENDENCIES');
    // 		// 	}
    // 		// }
    // 		// else {
    // let count = 0;
    // 		if (payload.Tag != null && payload.Tag != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.TAG');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload.Tag) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload.Tag[variable]) {
    // 					let content = payload.Tag[variable][item]["description"].replace(/<tagdesc><txt>/gi, "");
    // 					let value = content.replace(/<\/txt><\/tagdesc>/gi, "");
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': payload.Tag[variable][item]["tagNo"] + " - " + value
    // 					})
    // 					//}
    // 				}
    // 				j++;
    // 			}
    // 			items++;
    // 		}
    // 		if (payload.Asset != null && payload.Asset != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ASSET');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload.Asset) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload.Asset[variable]) {
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': payload.Asset[variable][item]["assetName"]
    // 					})
    // 					//}
    // 				}
    // else {
    // let count = 0;
    // if (payload.Tag != null && payload.Tag != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.TAG');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.Tag) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.Tag[variable]) {
    // 			let content = payload.Tag[variable][item]["description"].replace(/<tagdesc><txt>/gi, "");
    // 			let value = content.replace(/<\/txt><\/tagdesc>/gi, "");
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.Tag[variable][item]["tagNo"] + " - " + value
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // if (payload.Asset != null && payload.Asset != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ASSET');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.Asset) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.Asset[variable]) {
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.Asset[variable][item]["assetName"]
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // if (payload.DocumentType != null && payload.DocumentType != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.DOCUMENT_TYPE');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.DocumentType) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.DocumentType[variable]) {
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.DocumentType[variable][item]["topicType"]
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // if (payload.PunchList != null && payload.PunchList != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.PUNCHLIST');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.PunchList) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.PunchList[variable]) {
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.PunchList[variable][item]["punchlistDescription"]
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // if (payload.Image != null && payload.Image != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.IMAGE');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.Image) {
    // 		console.log("variable " + variable)
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.Image[variable]) {
    // 			console.log("item " + item)
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.Image[variable][item]["name"]
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // if (payload.Content != null && payload.Content != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.CONTENTS');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.Content) {
    // 		console.log("variable " + variable)
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.Content[variable]) {
    // 			console.log("item " + item)
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.Content[variable][item]["name"]
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // if (payload.Folder != null && payload.Folder != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.FOLDER');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload.Folder) {
    // 		console.log("variable " + variable)
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload.Folder[variable]) {
    // 			console.log("item " + item)
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload.Folder[variable][item]["name"]
    // 			})
    // 			//}
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }

    // 		if (payload["Mechanical Completion"] != null && payload["Mechanical Completion"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.MECHANICAL_COMPLETION');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["Mechanical Completion"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["Mechanical Completion"][variable]) {
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': "MCC-" + payload["Mechanical Completion"][variable][item]["systemNo"] + "-" + payload["Mechanical Completion"][variable][item]["systemName"]
    // 					})
    // 				}
    // 				j++;
    // 			}
    // 			items++;
    // 		}
    // if (payload["Comm Completion"] != null && payload["Comm Completion"] != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.COMM_COMPLETION');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload["Comm Completion"]) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload["Comm Completion"][variable]) {
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': "CCC-" + payload["Comm Completion"][variable][item]["systemNo"] + "-" + payload["Comm Completion"][variable][item]["systemName"]
    // 			})
    // 		}
    // 		j++;
    // 	}
    // 	items++;
    // }
    // 		if (payload["System Turnover"] != null && payload["System Turnover"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.SYSTEM_TURNOVER');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["System Turnover"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["System Turnover"][variable]) {
    // 					// console.log(payload["System Turnover"][variable][item]["systemNo"])
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': "STN-" + payload["System Turnover"][variable][item]["systemNo"] + "-" + payload["System Turnover"][variable][item]["systemName"] + "-" + payload["System Turnover"][variable][item]["vendorName"]
    // 					})
    // 				}
    // 				j++;
    // 			}
    // 			items++;
    //  		}
    // 		if (response.SystemDependency.Systems.System != null && response.SystemDependency.Systems.System != undefined) {
    // 			debugger;
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.SYSTEM');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in response.SystemDependency) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in response["SystemDependency"][variable]) {
    // 					console.log(response["SystemDependency"]);
    // 					console.log(response["SystemDependency"][variable]);
    // 					console.log(response["SystemDependency"][variable][item]);
    // 					this.dependencyNameList[items][j].push({
    // 					'displayName': response["SystemDependency"][variable][item]
    // 					// response["SystemDependency"][variable][item] 
    // 				})


    // 				}
    // 				j++;
    // 			}
    // 			items++;
    // 		}

    // 		if (payload["Static Completion"] != null && payload["Static Completion"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.STATIC_COMPLETION');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["Static Completion"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["Static Completion"][variable]) {
    // 					this.dependencyNameList[items].push({
    // 						'displayName': "SCC-" + payload["Static Completion"][variable][item]["systemNo"] + "-" + + payload["Static Completion"][variable][item]["systemName"]
    // 					})
    // 				}
    // 				j++;
    // 			}
    // 			items++
    // 		}
    // 		if (payload["System Handover"] != null && payload["System Handover"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.STATIC_COMPLETION');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["System Handover"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["System Handover"][variable]) {
    // 					this.dependencyNameList[items].push({
    // 						'displayName': "SHC-" + payload["System Handover"][variable][item]["systemNo"] + "-" + + payload["System Handover  "][variable][item]["systemName"]
    // 					})
    // 				}
    // 				j++;
    // 			}

    // 			items++;
    // 		}
    // 		if (payload["ICECourse"] != null && payload["ICECourse"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICECOURSES');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["ICECourse"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["ICECourse"][variable]) {
    // 					var html = payload["ICECourse"][variable][item]["name"];
    // 					var div = document.createElement("div");
    // 					div.innerHTML = html;
    // 					var text = div.textContent || div.innerText || div.style || "";
    // 					let value = text + "(" + + payload["ICECourse"][variable][item]["referenceCount"] + ")";
    // 					if( payload["ICECourse"][variable][item]["folderPath"] != null){
    // 						value +=  "[" + payload["ICECourse"][variable][item]["folderPath"] + "]";
    // 					}
    // 					if(payload["ICECourse"][variable][item]["checkoutBy"] != null && payload["ICECourse"][variable][item]["checkoutBy"] != ""){
    // 						value += "-" + payload["ICECourse"][variable][item]["checkoutBy"];
    // 					}
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': value
    // 					})
    // 				}
    // 				j++;
    // 			}

    // 			items++;
    // 		}
    // 		if (payload["ICETopic"] != null && payload["ICETopic"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICETOPICS');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["ICETopic"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["ICETopic"][variable]) {
    // 					var html =  payload["ICETopic"][variable][item]["name"];
    // 					var div = document.createElement("div");
    // 					div.innerHTML = html;
    // 					var text = div.textContent || div.innerText || div.style || "";
    // 					let value = text+ "(" + + payload["ICETopic"][variable][item]["referenceCount"] + ")";
    // 					if( payload["ICETopic"][variable][item]["folderPath"] != null){
    // 						value +=  "[" + payload["ICETopic"][variable][item]["folderPath"] + "]";
    // 					}
    // 					if(payload["ICETopic"][variable][item]["checkoutBy"] != null && payload["ICETopic"][variable][item]["checkoutBy"] != ""){
    // 						value += "-" + payload["ICETopic"][variable][item]["checkoutBy"];
    // 					}
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': value
    // 					})
    // 				}
    // 				j++;
    // 			}

    // 			items++;
    // 		}
    // 		if (payload["ICETopicContent"] != null && payload["ICETopicContent"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICETOPICCONTENTS');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["ICETopicContent"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["ICETopicContent"][variable]) {
    // 					//let value = payload["ICETopicContent"][variable][item]["name"] + "(" + + payload["ICETopicContent"][variable][item]["referenceCount"] + ")";
    // 					var html =  payload["ICETopicContent"][variable][item]["descriptionHTML"];
    // 					var div = document.createElement("div");
    // 					div.innerHTML = html;
    // 					var text = div.textContent || div.innerText || div.style || "";
    // 					let value = text+ "(" + + payload["ICETopicContent"][variable][item]["referenceCount"] + ")";
    // 					if( payload["ICETopicContent"][variable][item]["folderPath"] != null){
    // 						value +=  "[" + payload["ICETopicContent"][variable][item]["folderPath"] + "]";
    // 					}
    // 					if(payload["ICETopicContent"][variable][item]["checkoutBy"] != null && payload["ICETopicContent"][variable][item]["checkoutBy"] != ""){
    // 						value += "-" + payload["ICETopicContent"][variable][item]["checkoutBy"];
    // 					}
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': value
    // 					})
    // 				}
    // 				j++;
    // 			}

    // 	items++;
    // }
    // if (payload["ICELearningCheck"] != null && payload["ICELearningCheck"] != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.ICELEARNINGCHECKS');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload["ICELearningCheck"]) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload["ICELearningCheck"][variable]) {
    // 			var html =  payload["ICELearningCheck"][variable][item]["descriptionHTML"];
    // 			var div = document.createElement("div");
    // 			div.innerHTML = html;
    // 			var text = div.textContent || div.innerText || div.style || "";
    // 			let value = text+ "(" + + payload["ICELearningCheck"][variable][item]["referenceCount"] + ")";
    // 			if( payload["ICELearningCheck"][variable][item]["folderPath"] != null){
    // 				value +=  "[" + payload["ICELearningCheck"][variable][item]["folderPath"] + "]";
    // 			}
    // 			if(payload["ICELearningCheck"][variable][item]["checkoutBy"] != null && payload["ICELearningCheck"][variable][item]["checkoutBy"] != ""){
    // 				value += "-" + payload["ICELearningCheck"][variable][item]["checkoutBy"];
    // 			}
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': value
    // 			})
    // 		}
    // 		j++;
    // 	}
    // 	items++;			
    // }
    // if (payload["Topic"] != null && payload["Topic"] != undefined) {
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.TOPICS');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload["Topic"]) {
    // 		this.multi[count - 1][j] = variable;
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload["Topic"][variable]) {
    // 			let value = payload["Topic"][variable][item]["name"] + "(" + + payload["Topic"][variable][item]["referenceCount"] + ")";
    // 			if( payload["Topic"][variable][item]["folderPath"] != null){
    // 				value +=  "[" + payload["Topic"][variable][item]["folderPath"] + "]";
    // 			}
    // 			if(payload["Topic"][variable][item]["checkoutBy"] != null && payload["Topic"][variable][item]["checkoutBy"] != ""){
    // 				value += "-" + payload["Topic"][variable][item]["checkoutBy"];
    // 			}
    // 			/*this.dependencyNameList[items][j].push({
    // 				'displayName': payload["Topic"][variable][item]["name"] + "(" + + payload["Topic"][variable][item]["referenceCount"] + ")" + "[" + payload["Topic"][variable][item]["folderPath"] + "]" + "-" + payload["Topic"][variable][item]["checkoutBy"]
    // 			});*/
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': value
    // 			});
    // 			console.log(value);
    // 		}
    // 		j++;
    // 	}

    // 			items++;
    // 		}
    // 		if (payload["Procedure"] != null && payload["Procedure"] != undefined) {
    // 			// console.log("procedure");
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.PROCEDURES');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["Procedure"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["Procedure"][variable]) {
    // 					let value = payload["Procedure"][variable][item]["name"] + "(" + + payload["Procedure"][variable][item]["referenceCount"] + ")";
    // 					if( payload["Procedure"][variable][item]["folderPath"] != null){
    // 						value +=  "[" + payload["Procedure"][variable][item]["folderPath"] + "]";
    // 					}
    // 					if(payload["Procedure"][variable][item]["checkoutBy"] != null && payload["Procedure"][variable][item]["checkoutBy"] != ""){
    // 						value += "-" + payload["Procedure"][variable][item]["checkoutBy"];
    // 					}
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': value
    // 					});
    // 					console.log(value);
    // 				}
    // 				j++;
    // 			}

    // 			items++;
    // 		}
    // 		if (payload["Project"] != null && payload["Project"] != undefined && payload["Project"]["1"] != "") {
    // 			console.log("procedure " + payload["Project"]["1"]);
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.PROJECTS');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["Project"]) {
    // 				this.multi[count - 1][j] = "";
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["Project"][variable]) {
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': payload["Project"][variable][item]["projectName"]
    // 					})
    // 				}
    // 				j++;

    // 			}

    // 			items++;
    // 		}
    // 		if (payload["TopicMap"] != null && payload["TopicMap"] != undefined) {
    // 			let items = count;
    // 			this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.TOPICMAPS');
    // 			count++;
    // 			let j = 0;
    // 			this.multi[count - 1] = [];
    // 			this.dependencyNameList[items] = [];
    // 			for (var variable in payload["TopicMap"]) {
    // 				this.multi[count - 1][j] = variable;
    // 				this.dependencyNameList[items][j] = [];
    // 				for (var item in payload["TopicMap"][variable]) {
    // 					this.dependencyNameList[items][j].push({
    // 						'displayName': payload["TopicMap"][variable][item]["name"]
    // 					})
    // 				}
    // 				j++;
    // 			}

    // 			items++;

    // if (payload["CheckSheet"] != null && payload["CheckSheet"] != undefined) {
    // 	debugger;
    // 	let items = count;
    // 	this.contentHeader[count] = this.translate.instant('DEPENDENCIES_MODAL.CHECK_SHEET');
    // 	count++;
    // 	let j = 0;
    // 	this.multi[count - 1] = [];
    // 	this.dependencyNameList[items] = [];
    // 	for (var variable in payload["CheckSheet"]) {
    // 		this.multi[count - 1][j] = "";
    // 		this.dependencyNameList[items][j] = [];
    // 		for (var item in payload["CheckSheet"][variable]) {
    // 			this.dependencyNameList[items][j].push({
    // 				'displayName': payload["CheckSheet"][variable][item]["checkSheetName"]
    // 			})
    // 		}
    // 		j++;

    // 	}

    // 	items++;
    // }
    // 		}
    // 		//	}
    // 	} else {
    // 		this.contentHeader[0] = "";
    // 		if (localStorage.getItem("isDelete") == "true") {
    // 			console.log("delete")
    // 			this.deleteConfirm = true;
    // 		}
    //  	}
    //  	console.log(JSON.stringify(this.multi));

    cancel() {
        localStorage.setItem("Confirm", "cancel");
    }

    confirm(title: String, checkboxValue: boolean, delFlag: boolean) {
        localStorage.setItem("Confirm", "ok");
        console.log("method confirm=" + title + "=");
        console.log("confirm this.itemType" + this.itemType);
        console.log("confirm dependencyMessageType" + this.dependencyMessageType);
        this.title = title;
        this.status = this.sharedService.getStatus();

        //for statement
        //if (title == this.translate.instant('FILES_DATA.DISABLE_CONFIRM') || title == this.translate.instant('STATEMENT_CELL_RENDER.DISABLE_CONFIRM')) {
        if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.DISABLE) {
            console.log("method confirm DISABLE_CONFIRM");
            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
            if (this.itemType == "Procedure") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Procedure");
            }
            else if (this.itemType == "Image") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Image");
            }
            else if (this.itemType == "Video") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Video");
            }
            else if (this.itemType == "Topic") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Topic");
            }
            else if (this.itemType == "TopicMap") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Topic Map");
            }
            else if (this.itemType == "ThreeDModel") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the 3D Model");
            }
            else if (this.itemType == "ContentItem") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the File");
            }
            else if (this.itemType == "ICECourse") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Course");
            }
            else if (this.itemType == "Check") {
                this.alertService.clear();
                this.alertService.success("Successfully Disabled the Check");
            }
			// this.alertService.success("Successfully Disabled");
            // added to  close the opened tab
            // if (this.sharedService.getFileId() == this.sharedService.getNewICETabId()) {
            //     this.sharedService.checkedInCheckedOutTabCloseRequest('ice_cource_1');
            // } else {
                this.sharedService.checkedInCheckedOutTabCloseRequest(this.sharedService.getFileId());
            //}
            //} else if (title == this.translate.instant('FILES_DATA.ENABLE_CONFIRM') || title == this.translate.instant('STATEMENT_CELL_RENDER.ENABLE_CONFIRM')) {
        } else if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.ENABLE) {
            console.log("method confirm ENABLE_CONFIRM" + title);
            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
            if (this.itemType == "Procedure") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the Procedure");
            }
            else if (this.itemType == "Image") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the Image");
            }
            else if (this.itemType == "Video") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the Video");
            }
            else if (this.itemType == "Topic") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the Topic");
            }
            else if (this.itemType == "TopicMap") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the Topic Map");
            }
            else if (this.itemType == "ThreeDModel") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the 3D Model");
            }
            else if (this.itemType == "ContentItem") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the File");
            }
            else if (this.itemType == "ICECourse") {
                this.alertService.clear();
                this.alertService.success("Successfully Enabled the Course");
            } else if (this.itemType == "Check") {
                this.alertService.clear();
                this.alertService.success("Successfully enabled the Check");
            }
            // added to  close the opened tab
            // if (this.sharedService.getFileId() == this.sharedService.getNewICETabId()) {
            //     this.sharedService.checkedInCheckedOutTabCloseRequest('ice_cource_1');
            // } else {
                this.sharedService.checkedInCheckedOutTabCloseRequest(this.sharedService.getFileId());
            //}
            //} else if (title == this.translate.instant('FILES_DATA.DELETE_CONFLICTS') || title == this.translate.instant('STATEMENT_CELL_RENDER.DELETE_CONFLICTS') ||title == this.translate.instant('DEPENDENCIES_MODAL.DELETE_ITEM')) {
        } else if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.DELETE) {
            console.log("method confirm DELETE_CONFLICTS" + title);
            if (delFlag && !this.noDependecies) {
                if(this.multipleSelectedItems != null){
                    if (checkboxValue && this.status == 'Active') {
                        this.filesDataService.disableAll(this.multipleSelectedItems, this);
                    } else if (!checkboxValue && this.status == 'Inactive') {
                        this.filesDataService.enableAll(this.multipleSelectedItems, this);
                    }
                }
                else{
                    if(this.itemType=="DocumentTypeAttribute"){
                        if (checkboxValue && this.status == 'Active') {
                            this.documentTypeService.toggleDocTypeStatus(this.itemId);
                        } else if (!checkboxValue && this.status == 'Inactive') {
                            this.documentTypeService.toggleDocTypeStatus(this.itemId);
                        } else{
                            //Nothing to do
                        }

                    } else {
                    if (checkboxValue && this.status == 'Active') {
                        this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                    } else if (!checkboxValue && this.status == 'Inactive') {
                        this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                    } else{
                        //Nothing to do
                    }
                }
                }
            } else {
                console.log("delete");
                if(this.multipleSelectedItems != null){
                    //this.filesDataService.deleteAll(this.multipleSelectedItems, this);
                    this.dependenciesmodalService.deleteAll(this.multipleSelectedItems, this);
                    this.loadingService.showLoading(true, false, this.translate.instant("DELETING"), 0);
                } else{
                    if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPEATTRIBUTE){
                        if(this.noDependecies){
                            this.documentTypeService.removeDocTypeAttribute(this.itemId);
                        }
                    }
                    if (this.itemType == "OperatingData"){
                        if(this.noDependecies){
                            this.action = 'delete'; //set the current action
                            let token = UserVariable.getUserToken();
                            var request: any = {
                                "itemId": this.itemId,
                                "itemType": 'OperatingData',
                                "action": 'delete',
                                "userToken": token
                            };
                            //this.optService.getOperatingdataDependancies(JSON.stringify(request), this);
                            const data_ = { userToken: token, id: this.itemId, name: '', type: '' };
                            this.optService.deleteOperatingDataType(data_, this);
                        }
                    }
                    else{
                        if ((this.itemType == "Folder")) {
                            this.dependenciesmodalService.setCallback(this.sharedService.getParent());
                        }
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                        this.loadingService.showLoading(true, false, this.translate.instant("DELETING"), 0);
                    }
                }
            }
            //} else if (title == this.translate.instant('STATEMENT_CELL_RENDER.EDIT_CONFIRM')) {
        } else if (this.dependencyMessageType == Constants.DEPENDENCY_MESSAGE_TYPE.EDIT) {
            console.log("method conf")
            if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.STATEMENT) {
                this.sharedService.openStatementsEditRequest();
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.FACILITY) {
                this.sharedService.openFacilitiesEditRequest();
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.SYSTEM) {
                this.sharedService.openSystemsEditRequest();
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.DOCUMENT) {
                this.sharedService.openDocumentsEditRequest(this.itemId);
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.COMPONENT) {
                this.sharedService.openComponentEditRequest();
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.CLIENT) {
                //this.router.navigateByUrl('addeditclient');
                this.sharedService.viewSideBarEventEmit("aeclients")
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.PROJECT) {
                //this.router.navigateByUrl('editProject');
                console.log("PROJECTS")
                this.sharedService.viewSideBarEventEmit("editprojects")
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.USER) {
                //this.router.navigateByUrl('edituser');
                this.sharedService.viewSideBarEventEmit("edituser");
            } else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.PUNCHLIST) {
                this.sharedService.editPunchlistRequest();
            }else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.IRN) {
                this.sharedService.editIrnRequest();
            }else if (this.itemType == Constants.DEPENDENCY_ITEM_TYPE.DOCUMENTTYPE) {
                this.sharedService.openDocumentAtributeEditRequest();
            }
            else if (this.itemType == "OperatingData"){
                this.sharedService.getParent().editOperatingData();
            } else if(this.itemType == Constants.DEPENDENCY_ITEM_TYPE.WORKFLOW){
                this.sharedService.viewSideBarEventEmit("addEditWfTemplate");
            } else if(this.itemType == Constants.DEPENDENCY_ITEM_TYPE.CHECK){
                this.sharedService.editCheckRequest();
            }
            // this.router.navigateByUrl('addeditstatemet');
            //console.log("method conf" )

            //this.sharedService.openStatementsEditRequest()
        }
        /*
                //for facility
                else if (title == this.translate.instant('FILES_DATA.DISABLE_CONFIRM') || title == this.translate.instant('FACILITY_CELL_RENDER.DISABLE_CONFIRM')) {
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.ENABLE_CONFIRM') || title == this.translate.instant('FACILITY_CELL_RENDER.ENABLE_CONFIRM')) {
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.DELETE_CONFLICTS') || title == this.translate.instant('FACILITY_CELL_RENDER.DELETE_CONFLICTS')) {
                    console.log("delete conflicts")
                    if (delFlag) {
                        if (checkboxValue && this.status == 'Active') {
                            console.log("delete conflicts " +  this.status)
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'Inactive') {
                            console.log("delete conflicts" +  this.status)
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('FACILITY_CELL_RENDER.EDIT_CONFIRM')) {
                    // this.router.navigateByUrl('addedfacilities');
                    console.log("method")
                    this.sharedService.openFacilitiesEditRequest()
                }
        
        
                //for system
                if (title == this.translate.instant('FILES_DATA.DISABLE_CONFIRM') || title == this.translate.instant('SYSTEM_CELL_RENDER.DISABLE_CONFIRM')) {
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.ENABLE_CONFIRM') || title == this.translate.instant('SYSTEM_CELL_RENDER.ENABLE_CONFIRM')) {
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.DELETE_CONFLICTS') || title == this.translate.instant('SYSTEM_CELL_RENDER.DELETE_CONFLICTS')) {
                    if (delFlag && this.dependencyNameList.length != 0) {
                        if (checkboxValue && this.status == 'Active') {
                            console.log("delete conflicts " +  this.status)
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'Inactive') {
                            console.log("delete conflicts " +  this.status)
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('SYSTEM_CELL_RENDER.EDIT_CONFIRM')) {
                    // this.router.navigateByUrl('addeditsystems');
                    this.sharedService.openSystemsEditRequest()
                }
        
                //for document
                else if (title == this.translate.instant('FILES_DATA.DISABLE_CONFIRM') || title == this.translate.instant('DOCUMENT_CELL_RENDER.DISABLE_CONFIRM')) {
                    console.log("doc1");
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.ENABLE_CONFIRM') || title == this.translate.instant('DOCUMENT_CELL_RENDER.ENABLE_CONFIRM')) {
                    console.log("doc2");
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.DELETE_CONFLICTS') || title == this.translate.instant('DOCUMENT_CELL_RENDER.DELETE_CONFLICTS')) {
                    console.log("doc3");
                    if (delFlag && this.dependencyNameList.length != 0) {
                        console.log("doc4");
                        console.log("this.status" + this.status);
                        console.log("checkboxValue" + checkboxValue);
        
                        if (checkboxValue && this.status == 'Inactive') {
                            console.log("doc5");
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'Active') {
                            console.log("doc6");
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        console.log("doc7");
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('DOCUMENT_CELL_RENDER.EDIT_CONFIRM')) {
                    console.log("doc8");
                    // this.router.navigateByUrl('addeditdocuments');
                    this.sharedService.openDocumentsEditRequest(this.itemId)
                    // this.router.navigate(['addeditdocuments'],{ queryParams: { itemId: this.itemId } });
                }
        
                //for component
                else if (title == this.translate.instant('FILES_DATA.DISABLE_CONFIRM') || title == this.translate.instant('COMPONENT_CELL_RENDER.DISABLE_CONFIRM')) {
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.ENABLE_CONFIRM') || title == this.translate.instant('COMPONENT_CELL_RENDER.ENABLE_CONFIRM')) {
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('FILES_DATA.DELETE_CONFLICTS') || title == this.translate.instant('COMPONENT_CELL_RENDER.DELETE_CONFLICTS')) {
                    console.log("delete")
                    if (delFlag && this.dependencyNameList.length != 0) {
                        if (checkboxValue && this.status == 'Active') {
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'Inactive') {
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('COMPONENT_CELL_RENDER.EDIT_CONFIRM')) {
                    // this.router.navigateByUrl('addeditcomponents');
                    this.sharedService.openComponentEditRequest()
                }
            	
                //for project
                if (title == this.translate.instant('PROJECT_CELL_RENDER.DISABLE_CONFIRM')) {
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('PROJECT_CELL_RENDER.ENABLE_CONFIRM')) {
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('PROJECT_CELL_RENDER.DELETE_CONFLICTS')) {
                    if (delFlag && this.dependencyNameList.length != 0) {
                        if (checkboxValue && this.status == 'Active') {
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'Inactive') {
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('PROJECT_CELL_RENDER.EDIT_CONFIRM')) {
                    console.log("editProject")
                    this.router.navigateByUrl('editProject');
                }
        
                //For Client
                else if (title == this.translate.instant('CLIENT_CELL_RENDER.DISABLE_CONFIRM')) {
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('CLIENT_CELL_RENDER.ENABLE_CONFIRM')) {
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('CLIENT_CELL_RENDER.DELETE_CONFLICTS')) {
                    if (delFlag && this.dependencyNameList.length != 0) {
                        if (checkboxValue && this.status == 'Active') {
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'Inactive') {
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('CLIENT_CELL_RENDER.EDIT_CONFIRM')) {
                    console.log("routing");
                    this.router.navigateByUrl('addeditclient');
                }
        
        
                //For User
                else if (title == this.translate.instant('USER_CELL_RENDERER.DISABLE_CONFIRM')) {
                    this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('USER_CELL_RENDERER.ENABLE_CONFIRM')) {
                    this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                } else if (title == this.translate.instant('USER_CELL_RENDERER.DELETE_CONFLICTS')) {
                    if (delFlag && this.dependencyNameList.length != 0) {
                        if (checkboxValue && this.status == 'Active') {
                            this.dependenciesmodalService.disableItem(this.itemId, this.itemType);
                        } else if (!checkboxValue && this.status == 'inactive') {
                            this.dependenciesmodalService.enableItem(this.itemId, this.itemType);
                        }
                    } else {
                        this.dependenciesmodalService.deleteItem(this.itemId, this.itemType);
                    }
                }
                else if (title == this.translate.instant('USER_CELL_RENDERER.EDIT_CONFIRM')) {
                    console.log("routing");
                    this.router.navigateByUrl('edituser');
                }
        */

    }

    onCheckboxClick(event: any){
        console.log("user clicks")
        this.userClickCheckbox = true;
    }
}
