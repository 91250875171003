import { Component, OnInit, Input, ElementRef, Injectable } from '@angular/core';
import { IceTopic, ProcImage, ProcThreeDModel, ProcVideo } from './ice-topic/ice-topic-model.model';
import { SharedService } from '../util/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ICECourseService } from './ice-course-service.service';
import { ICEGameImage } from './ice-game/ice-game-model.model';

@Injectable({ providedIn: "root" })
export class ICECKEditor {

	public toolbarId: string;
	private html: string;
	private tagContent: string = '';
	private selection: any;
	private ranges: any;
	private elementRef: ElementRef;
	private guidWinT: String;
	private isFirefox: boolean = false;

	private xmlContent: string = '';
	private partialStm: string = '';
	private stmHtml: string;
	public categoryNameList: Array<any> = [];
	public categoryName: string;
	private escapeAttrMap: Map<string, string> = new Map();
	private hasResuables: boolean;

	topic_translated_data: any = null;

	public modalConfig: any = {
		backdrop: true,
		// ignoreBackdropClick: true,
		class: 'modal-lg',
		// keyboard: false
	}

	constructor(private sharedService: SharedService,
		private alert: AlertService, private translate: TranslateService,
		private courseService: ICECourseService, private alertService: AlertService) {
		this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
	}

	private s4(): string {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	public getXMLContent(): string {
		return this.xmlContent

	}

	/**
     * Creates and returns random GUID.
     */
	private guid(): string {
		return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
	}

	/**
     * Encode html
     */
	private htmlEncode(content: string): string {
		if (content != null || content != "") {
			return content.replace(/(?!&amp;|&gt;|&lt;|&quot;|&nbsp;)\&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").
				replace(/"/g, "&quot;").replace(/'/g, "&#39;");
		} else {
			return "";
		}
	}

	/**
     * Insert reusables
     */
	public insertTagEditArea(option: any, elementRef: any, editor: any, CKEDITOR: any, Data: any, Type: any) {
		this.html = '';
		this.tagContent = '';
		if (option != null) {
			if (option.data.type == "Facility" || option.data.type == "FacilityName" || option.data.type == "documentFacility" ||
				option.data.type == "SystemFacility" || option.data.type == "System" || option.data.type == "TagNo" ||
				option.data.type == "TagNoDesc" || option.data.type == "TagDocument" || option.data.type == "Description" ||
				option.data.type == "Component" || option.data.type == "Document" || option.data.type == "systemName" ||
				option.data.type == "systemNo" || option.data.type == "TagFacilityName" || option.data.type == "TagAttributes") {
				if (option.data.reusableXML != undefined) {
					this.html = option.data.reusableXML.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    	});
				} else {
					if (option.data.type == "Component") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='com' id='" + option.data.id + "' compName='" + this.htmlEncode(option.data.componentName) + "' compValue='" +
                            this.htmlEncode(option.data.componentValue) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.componentValue) + "</com>";
                    } else if (option.data.type == "Document") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='doc' id='" + option.data.id + "' compName='document' compValue='" +
                            this.htmlEncode(option.data.documentName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.documentName) + "</com>";
                    } else if (option.data.type == "systemName") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='sys' id='" + option.data.systemId + "' compName='SystemName' compValue='" +
                            this.htmlEncode(option.data.systemName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.systemName) + "</com>";
                    } else if (option.data.type == "systemNo") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='sys' id='" + option.data.systemId + "' compName='SystemNo' compValue='" +
                            option.data.systemNo + "' lastUpdateTimeStamp='" + option.data.systemNoLastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + option.data.systemNo + "</com>";
                    } else if (option.data.type == "Facility") {
                        this.html = "<com style='background-color:#FFFF8D;' compType='fac' id='" + option.data.id + "' compName='facility' compValue='" +
                            this.htmlEncode(option.data.facilityName) + "' lastUpdateTimeStamp='" + option.data.lastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.facilityName) + "</com>";
                    } else if (option.data.type == "FacilityName") {
                        this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
                    } else if (option.data.type == "documentFacility") {
						if (option.data.facilityName != undefined) {
							this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.facilityLastUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
						}                        
                    } else if (option.data.type == "SystemFacility") {
                        if (option.data.facilityName != undefined) {
                            this.html = "<com style='background-color:#FFFF8D;' compType='fac' id='" + option.data.facilityId + "' compName='facility' compValue='" +
                            this.htmlEncode(option.data.facilityName) + "' lastUpdateTimeStamp='" + option.data.facilityLastUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.facilityName) + "</com>";
                        }                        
                    } else if (option.data.type == "System") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='sysName' value='" +
                            this.htmlEncode(option.data.systemName) + "' lastUpdateTimeStamp='" + option.data.tagSysUpdateTimeStamp +
                            "' insID='" + this.guid() + "'>" + this.htmlEncode(option.data.systemName) + "</fld></tag>";
                    } else if (option.data.type == "TagNo") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagNo' value='" + this.htmlEncode(option.data.tagNo) +
                            "' lastUpdateTimeStamp='" + option.data.tagNoUpdateTimeStamp + "'>" + this.htmlEncode(option.data.tagNo) + "</fld></tag>";
                    } else if (option.data.type == "Description") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagName' value='' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "'><tagdesc><txt>" + this.htmlEncode(option.data.description) + "</txt></tagdesc></fld></tag>";
                    } else if (option.data.type == "TagDocument") {
                        if (option.data.documentName != undefined) {
                            this.html = "<tag style='background-color:#FFFF8D;' compType='doc' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='docName' value='" +
                            this.htmlEncode(option.data.documentName) + "' lastUpdateTimeStamp='" + option.data.tagDocUpdateTimeStamp + "'>" +
                            this.htmlEncode(option.data.documentName) + "</fld></tag>";
                        }                        
                    } else if (option.data.type == "TagNoDesc") {
                        this.html = "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagName' value='' lastUpdateTimeStamp='" +
                            option.data.tagDescUpdateTimeStamp + "'><tagdesc><txt>" + this.htmlEncode(option.data.description) + "</txt></tagdesc></fld></tag>" +
                            "kkkk,kkkk" +
                            "<tag style='background-color:#FFFF8D;' id='" + option.data.tagId + "' insID='" + this.guid() + "'><fld name='tagNo' value='" + this.htmlEncode(option.data.tagNo) +
                            "' lastUpdateTimeStamp='" + option.data.tagNoUpdateTimeStamp + "'>" + this.htmlEncode(option.data.tagNo) + "</fld></tag>";
                    } else if (option.data.type == "TagFacilityName") {
                        if (option.data.facilityName != undefined) {
                            this.html = "<com style='background-color:#FFFF8D;' comptype = 'fac' compName='facility' compValue='" + this.htmlEncode(option.data.facilityName) +
                            "' id='" + option.data.facilityId + "' insID='" + this.guid() + "' lastUpdateTimeStamp='" +
                            option.data.tagFacilityLastUpdateTimeStamp + "' >" + this.htmlEncode(option.data.facilityName) + "</com>";
                        }                        
                    } else if (option.data.type == "TagAttributes") {
                        this.html = '<tag style="background-color:#FFFF8D;" contenteditable="false" id="' + option.data.id +'"' + ' insID="' + this.guid().toUpperCase() + '"'+ '>'+ 
                        '<attr specId="' + option.data.specId + '"'+ ' lastUpdateTimeStamp="' + option.data.lastUpdateTimeStamp + 
                        '"'+ ' name="' + this.htmlEncode(option.data.name) + '"'+ ' value="' + this.htmlEncode(option.data.value) + '"'+'>' + this.htmlEncode(option.data.value) +
                        "</attr></tag>";
                    }
				}
				if (editor != null) {
					this.selection = editor.getSelection();
					if (this.selection != null) {
						this.ranges = this.selection.getRanges();
					}
					let ancestor;
					if (this.ranges != null) {
						let range = this.ranges[0];
						if (range != null) {
							range = range.clone();
							let startNode = range.startContainer;
							ancestor = startNode.$.parentNode;
							if (startNode.$.nodeName == 'PH' || startNode.$.nodeName == 'EDITAREA') {
								ancestor = startNode.$;
							}
                            while(ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I'){
                                ancestor = ancestor.parentNode;
                            }
                            if(ancestor.nodeName == 'ATTR' && ancestor.parentNode.nodeName == 'TAG'){
                                ancestor = ancestor.parentNode;
                            }
						}
					}
					if (ancestor != null) {
						if (this.html == '') {
							if (ancestor.nodeName == 'TXT' && (ancestor.parentNode.nodeName == 'STATEMENT' || ancestor.parentNode.nodeName == 'PARA' || ancestor.parentNode.nodeName == 'LI')) {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_REUSABLE'), true);
							} else {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
							}
						} else {
							if (ancestor.nodeName == 'PH') {
								if (option.data.type == 'TagNoDesc') {
									this.guidWinT = this.guid().toUpperCase();
									this.html = this.processTagNoDesc(this.html);
								}
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (ancestor.nodeName == 'EDITAREA') {
								if (option.data.type == 'TagNoDesc') {
									this.html = this.processTagNoDesc(this.html);
								}
								this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (SharedService.nodeSelected() && SharedService.getselectedNode().nodeName == 'PH') {
								if (option.data.type == 'TagNoDesc') {
									this.guidWinT = this.guid().toUpperCase();
									this.html = this.processTagNoDesc(this.html);
								}
								let selectedNode = SharedService.getselectedNode();
								let restriction = selectedNode.attributes['restriction'].value;
								let tagPHId = selectedNode.attributes['tagPHId'].value;
								let catId = selectedNode.attributes['catId'] != undefined ? selectedNode.attributes['catId'].value : "";
								let text = selectedNode.textContent;
								this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(selectedNode, this.tagContent);
								SharedService.resetselectedNode();
							} else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT') {
								if(!this.isStatementParent(ancestor)){
									if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM') {
										if (option.data.type == 'TagNoDesc') {
                                            this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                            this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                            this.setOuterHTML(ancestor.parentNode, this.html);
                                        }
                                        else{
                                            this.setOuterHTML(ancestor, this.html);
                                        }
									} else if (ancestor.nodeName == 'FLD') {
										if (ancestor.parentNode != undefined) {
											var tagsNode = ancestor.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									} else if (ancestor.nodeName == 'TAGDESC') {
										if (ancestor.parentNode.parentNode != undefined) {
											var tagsNode = ancestor.parentNode.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									} else if (ancestor.nodeName == 'TXT') {
										if (ancestor.parentNode.nodeName == 'STATEMENT' || ancestor.parentNode.nodeName == 'PARA' || ancestor.parentNode.nodeName == 'P' || ancestor.parentNode.nodeName == 'LI') {
											this.alertService.clear();
											this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_REUSABLE'), true);
										} else if (ancestor.parentNode.parentNode.parentNode != undefined) {
											var tagsNode = ancestor.parentNode.parentNode.parentNode;
											if (option.data.type == 'TagNoDesc') {
                                                this.html = this.html.replace(/kkkk,kkkk/g, ('</tags>,&nbsp;<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">'));
                                                this.html = '<tags contenteditable="false" insID="'+this.guid().toUpperCase()+'">' + this.html + '</tags>';
                                                this.setOuterHTML(tagsNode.parentNode, this.html);
											}
                                            else{
                                                this.setOuterHTML(tagsNode, this.html);
                                            }
										}
									}
								}else{
									this.alertService.clear();
									this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
								}
							} else if (ancestor.nodeName != 'WINTITLE' && editor.getSelection() != null && ancestor.nodeName != 'P') {
								let findstatement  =this.findUpTag(ancestor,'STATEMENT' );
								if(!findstatement){
									if(option.data.type == 'TagNoDesc' && this.html.indexOf('</wintitle>')>-1){
										let strArray = this.html.split(/<editarea(?:(?!<tag.*?>).)*>/g);
										strArray.splice(1, 0, ", ");
										for (let i = 0; i < strArray.length; i++) {
											if (i == 0 || i == 2) {
												this.tagContent = "<tags contenteditable='false' insID='" + this.guid().toUpperCase() + "'>" + strArray[i] + "</tags>";
												let sel = editor.getSelection();
												let range;
												if (sel.getRanges()) {
													range = sel.getRanges()[0];
												}
												if (!range) {
													range = editor.createRange();
													range.selectNodeContents(editor.editable());
													sel.selectRanges([range]);
												}
												let fragment = range.extractContents();
												let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
												fragment.appendTo(container);
												editor.insertElement(container);
											} else {
												this.tagContent = strArray[i];
												editor.insertText(", ");
											}
										}
									}
									else if (option.data.type == 'TagNoDesc') {
										let strArray = this.html.split("kkkk,kkkk");
										strArray.splice(1, 0, ", ");
										for (let i = 0; i < strArray.length; i++) {
											if (i == 0 || i == 2) {
												this.tagContent = "<tags contenteditable='false' insID='" + this.guid().toUpperCase() + "'>" + strArray[i] + "</tags>";
												let sel = editor.getSelection();
												let range;
												if (sel.getRanges()) {
													range = sel.getRanges()[0];
												}
												if (!range) {
													range = editor.createRange();
													range.selectNodeContents(editor.editable());
													sel.selectRanges([range]);
												}
												let fragment = range.extractContents();
												let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
												fragment.appendTo(container);
												editor.insertElement(container);
											} else {
												this.tagContent = strArray[i];
												editor.insertText(", ");
											}
										}
									} else {
										this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '">' + this.html + '</tags>';
										let sel = editor.getSelection();
										let range;
										if (sel.getRanges()) {
											range = sel.getRanges()[0];
										}
										if (!range) {
											range = editor.createRange();
											range.selectNodeContents(editor.editable());
											sel.selectRanges([range]);
										}
										let fragment = range.extractContents();
										let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
										fragment.appendTo(container);
										editor.insertElement(container);
									}
								}
							}
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					this.alertService.clear();
					this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
				}
			} else if (option.data.type == "Statement" || option.data.type == "History_Statement" || option.data.type == "crossReference") {
				if (option.data.type == "Statement") {
					this.convertStatement(option.data);
				} else if (option.data.type == "crossReference") {
					this.html = "<XRef contenteditable='false' style='background-color:#f4b342;' topicId='" + option.data.refId + "' xrefType='"
						+ option.data.refType + "' refInsId='" + option.data.refInsId + "'>" + option.data.refType + ": " + option.data.refText + "</XRref>";
				} else if (option.data.type == "History_Statement") {
					this.html = option.data.reusableXML.replace(/insid=.{38}/gi, function insId() {
                        function s4() {
                            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        }
                        return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
                    	});
				}
				if (editor != null) {
					this.selection = editor.getSelection();
					if (this.selection != null) {
						this.ranges = this.selection.getRanges();
					}
					let ancestor;
					if (this.ranges != null) {
						let range = this.ranges[0];
						if (range != null) {
							range = range.clone();
							let startNode = range.startContainer;
							ancestor = startNode.$.parentNode;
						}
					} if (ancestor != null) {
						if (this.html != '') {
							if (ancestor.nodeName != 'WINTITLE' && editor.getSelection() != null) {
								let sel = editor.getSelection();
								let range;
								if (sel.getRanges()) {
									range = sel.getRanges()[0];
								}
								if (!range) {
									range = editor.createRange();
									range.selectNodeContents(editor.editable());
									sel.selectRanges([range]);
								}
								let fragment = range.extractContents();
								let container = CKEDITOR.dom.element.createFromHtml(this.html, editor.document);
								fragment.appendTo(container);
								editor.insertElement(container);
							} else {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
							}
						} else {
							this.alertService.clear();
							this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					this.alertService.clear();
					this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
				}
			} else if (option.data.type == "fraction" || option.data.type == "speChar") {
				if (option.data.type == "speChar") {
					this.html = "<spChar contenteditable='false' style='background-color:#48f442;' value='" + option.data.decVal + "' insID='" +
						this.guid().toUpperCase() + "'>" + option.data.symbol + "</spChar>";
				} else {
					this.html = "<fraction style='background-color:#f4b342;' numerator='" + option.data.numerator + "' denominator=" + option.data.denominator + ">" + option.data.numerator + "/" + option.data.denominator + "</fraction>";
				}
				if (editor != null) {
					this.selection = editor.getSelection();
					if (this.selection != null) {
						this.ranges = this.selection.getRanges();
					}
					let ancestor;
					if (this.ranges != null) {
						let range = this.ranges[0];
						if (range != null) {
							range = range.clone();
							let startNode = range.startContainer;
							ancestor = startNode.$.parentNode;
						}
					}
					if (ancestor != null) {
						if (this.html != '') {
							if (ancestor.nodeName == 'WINTITLE') {
								$(ancestor).removeAttr("contenteditable");
							} else if (ancestor.nodeName == 'PH') {
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '"><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (ancestor.nodeName == 'EDITAREA') {
								this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>' + this.html + '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>';
								this.setOuterHTML(ancestor, this.tagContent);
							} else if (ancestor.nodeName != 'TAG' || ancestor.nodeName != 'COM' || ancestor.nodeName != 'FLD' || ancestor.nodeName != 'TAGDESC' || ancestor.nodeName != 'TXT' || ancestor.nodeName != 'TAGS' || ancestor.nodeName != 'STATEMENT') {
								if (option.data.type == "speChar") {
									this.tagContent = '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '">' + this.html + '</tags>';
								} else {
									this.tagContent = "<fraction contenteditable='false' style='background-color:#f4b342;' numerator='" + option.data.numerator + "' denominator=" + option.data.denominator + ">" + option.data.numerator + "/" + option.data.denominator + "</fraction>";
								}
								let sel = editor.getSelection();
								let range;
								if (sel.getRanges()) {
									range = sel.getRanges()[0];
								}
								if (!range) {
									range = editor.createRange();
									range.selectNodeContents(editor.editable());
									sel.selectRanges([range]);
								}
								let fragment = range.extractContents();
								let container = CKEDITOR.dom.element.createFromHtml(this.tagContent, editor.document);
								fragment.appendTo(container);
								editor.insertElement(container);
							} else {
								this.alertService.clear();
								this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
							}
						} else {
							this.alertService.clear();
							this.alertService.info(this.translate.instant('TAG_EDIT_AREA.CANNOT_INSERT_EMPTY_VALUE'), true);
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					this.alertService.clear();
					this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_EDITOR'), true);
				}
			} else if (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure" || option.data.type == "modelAsFigure" || option.data.type == "videoAsFigure") {
				let currentTabId = SharedService.getTabId()
				if (Data.selectedType == "Image" && (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure")) {
					switch (Type) {
						case 'ICECourse':
							Data.courseImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.largeImage, option.data.title);
							this.sharedService.imageStatusRequest("ICECourse", currentTabId)
						case 'ICETopic':
							Data.topicImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.largeImage, option.data.title);
							this.sharedService.imageStatusRequest("ICETopic", currentTabId)
						case 'ICETopicContent':
							Data.topicContentImage = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.largeImage, option.data.title);
							this.sharedService.imageStatusRequest("ICETopicContent", currentTabId)
						default:
							break;
					}
				} else if (Data.selectedType == "Video" && option.data.type == "videoAsFigure") {
					switch (Type) {
						case 'ICECourse':
							Data.courseVideo = new ProcVideo(option.data.id, option.data.title, option.data.selectedURL, option.data.thumbnailVideo, option.data.posterframeURL);
							this.sharedService.imageStatusRequest("ICECourse", currentTabId)
						case 'ICETopic':
							Data.topicVideo = new ProcVideo(option.data.id, option.data.title, option.data.selectedURL, option.data.thumbnailVideo, option.data.posterframeURL);
							this.sharedService.imageStatusRequest("ICETopic", currentTabId)
						case 'ICETopicContent':
							Data.topicContentVideo = new ProcVideo(option.data.id, option.data.title, option.data.selectedURL, option.data.thumbnailVideo, option.data.posterframeURL);
							this.sharedService.imageStatusRequest("ICETopicContent", currentTabId)
						default:
							break;
					}
				} else if (Data.selectedType == "ThreeDModel" && option.data.type == "modelAsFigure") {
					switch (Type) {
						case 'ICECourse':
							Data.courseThreeDModel = new ProcThreeDModel(option.data.id, option.data.title, option.data.selectedURL,
								option.data.layerContent, option.data.viewContent, option.data.thumbnailThreeDModel);
							this.sharedService.imageStatusRequest("ICECourse", currentTabId)
						case 'ICETopic':
							Data.topicThreeDModel = new ProcThreeDModel(option.data.id, option.data.title, option.data.selectedURL,
								option.data.layerContent, option.data.viewContent, option.data.thumbnailThreeDModel);
							this.sharedService.imageStatusRequest("ICETopic", currentTabId)
						case 'ICETopicContent':
							Data.topicContentThreeDModel = new ProcThreeDModel(option.data.id, option.data.title, option.data.selectedURL,
								option.data.layerContent, option.data.viewContent, option.data.thumbnailThreeDModel);
							this.sharedService.imageStatusRequest("ICETopicContent", currentTabId)
						default:
							break;
					}
				} else if (Data.selectedType == "Icon" && (option.data.type == "imageAsImage" || option.data.type == "imageAsFigure")) {
					switch (Type) {
						case 'ICECourse':
							Data.courseIcon = new ProcImage(option.data.id, option.data.thumbnailImage, option.data.largeImage, option.data.title);
							this.sharedService.imageStatusRequest("ICECourse", currentTabId)
						default:
							break;
					}
				}
				if (Type == "ICEMemoryGame") {
					Data.gameImage = new ICEGameImage(option.data.id, option.data.thumbnailImage, option.data.largeImage, option.data.title);
					this.sharedService.imageStatusRequest("ICEMemoryGame", currentTabId)
				}
			}
			if(option.data.type != "fraction" && option.data.type != "speChar"){
				SharedService.setHtmlString(this.html);
			}
		} else {
			// not inserting to tag edit area
		}
	}

	private processTagNoDesc(html: string): string {
		let tagCombiStr = "";
		tagCombiStr = html.replace(/<\/tag>,/g, '</tag><editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '">&nbsp;,&nbsp;</wintitle>' +
			'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>').
			replace(/kkkk,kkkk/g,'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>'
            +'<wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guid() + '" id="' + this.guid() + '">,&nbsp;</wintitle>'
            +'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea>');
		return tagCombiStr;
	}

	private isStatementParent(tagNode: any): boolean {
		let inStmNode: boolean = false;
		while(tagNode.parentNode != undefined){
			if(tagNode.parentNode.nodeName == "STATEMENT"){
                inStmNode = true;
                break;
            }
            else{
                tagNode = tagNode.parentNode;
            }
		}
		// if (tagNode.parentNode != undefined) {
		// 	let tagsNode = tagNode.parentNode;
		// 	if (tagsNode.parentNode != undefined && tagsNode.parentNode.nodeName == 'STATEMENT') {
		// 		inStmNode = true;
		// 	} else if (tagsNode.parentNode.nodeName == 'PARA') {
		// 		if (tagsNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.nodeName == 'STATEMENT') {
		// 			inStmNode = true;
		// 		}
		// 	} else if (tagsNode.parentNode.nodeName == 'LI' && tagsNode.parentNode.parentNode != undefined) {
		// 		if (tagsNode.parentNode.parentNode.nodeName == 'OL' || tagsNode.parentNode.parentNode.nodeName == 'UL') {
		// 			if (tagsNode.parentNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT') {
		// 				inStmNode = true;
		// 			}
		// 		}
		// 	} else if(tagsNode.parentNode != undefined && tagsNode.parentNode.parentNode != undefined) {
        //         if (tagsNode.parentNode.parentNode.nodeName == 'LI' && tagsNode.parentNode.parentNode.parentNode != undefined) {
        //             if (tagsNode.parentNode.parentNode.parentNode.nodeName == 'OL' || tagsNode.parentNode.parentNode.parentNode.nodeName == 'UL') {
        //                 if (tagsNode.parentNode.parentNode.parentNode.parentNode != undefined && tagsNode.parentNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT') {
        //                     inStmNode = true;
        //                 }
        //                 else {
        //                     inStmNode = this.isStatementParent(tagsNode.parentNode.parentNode);
        //                 }
        //             }
        //         }else if (tagsNode.parentNode.nodeName == 'TXT' && tagsNode.parentNode.parentNode.parentNode != undefined){
        //             if(tagsNode.parentNode.parentNode.parentNode.nodeName == 'STATEMENT'){
        //                 inStmNode = true;
        //             }
        //         }
        //     }
		// }
		return inStmNode;
	}

	convertStatement(content: any): string {
		this.html = '';
		let xmlDoc;
		let statementContent = content.content;
		try {
			xmlDoc = $.parseXML(statementContent);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('statement');
		let length = outNode[0].childNodes.length;
		let id = outNode.attr('id');
		let label = this.htmlEncode(outNode.attr('label'));
		this.html = "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + content.lastUpdateTimeStamp + "' contenteditable='false' id='" +
			id + "' label='" + label + "'>";
		for (let i = 0; i < length; i++) {
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'txt') {
				this.html += "<txt";
                let bgc = $(innerNode).attr("bgc");
                let fgc = $(innerNode).attr("fgc");
                if (bgc != null && bgc != "") {
                    this.html += " bgc='"+bgc+ "' "
                }
                if (fgc != null && fgc != "") {
                    this.html += " fgc='"+fgc+ "' "
                }
                this.html += ">";
				for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
					let childTxtNode = $(innerNode)[0].childNodes[j];
					if ($(childTxtNode)[0].nodeName == '#text') {
						this.html += childTxtNode.textContent;
					} else {
						this.processStatement(childTxtNode);
					}
				}
				this.html += "</txt>";
			} else if ($(innerNode)[0].nodeName == 'ph') {
				this.processStatement($(innerNode)[0]);
			} else if ($(innerNode)[0].nodeName == 'p') {
				if ($(innerNode)[0].childNodes.length != 0) {
					this.html += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">';
				} else {
					this.html += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">&nbsp;';
				}
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let childNode = $(innerNode)[0].childNodes[i];
					if ($(childNode)[0].nodeName == 'txt') {
						this.html += "<txt";
                		let bgc = $(innerNode).attr("bgc");
                		let fgc = $(innerNode).attr("fgc");
                		if (bgc != null && bgc != "") {
                    		this.html += " bgc='"+bgc+ "' "
                		}
                		if (fgc != null && fgc != "") {
                    		this.html += " fgc='"+fgc+ "' "
                		}
                		this.html += ">";
						for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
							let childTxtNode = $(childNode)[0].childNodes[j];
							if ($(childTxtNode)[0].nodeName == '#text') {
								this.html += this.htmlEncode(childTxtNode.textContent);
							} else {
								this.processStatement(childTxtNode);
							}
						}
						this.html += "</txt>";
					} else if ($(childNode)[0].nodeName == 'ph') {
						this.processStatement($(childNode)[0]);
					}
				}
				this.html += '</para> ';
			} else if ($(innerNode)[0].nodeName == 'ol') {
				let listStyle = $(innerNode).attr("liststyle");
                this.html += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                    '">';
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let liNode = $(innerNode)[0].childNodes[i];
					if (liNode.nodeName == 'li') {
						this.html += '<li>';
						for (let i = 0; i < liNode.childNodes.length; i++) {
							let childNode = liNode.childNodes[i];
							if (childNode.nodeName == 'txt') {
								$(childNode).removeAttr('style');
								this.processStatement(childNode);
							} else if (childNode.nodeName == 'ph') {
								this.processStatement(childNode);
							}
						}
						this.html += '</li>';
					}
				}
				this.html += '</' + $(innerNode)[0].nodeName + '>';
			} else if ($(innerNode)[0].nodeName == 'ul') {
				let listStyle = $(innerNode).attr("liststyle");
                this.html += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                    '">'
				for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
					let liNode = $(innerNode)[0].childNodes[i];
					if (liNode.nodeName == 'li') {
						this.html += '<li>';
						for (let i = 0; i < liNode.childNodes.length; i++) {
							let childNode = liNode.childNodes[i];
							if (childNode.nodeName == 'txt') {
								$(childNode).removeAttr('style');
								this.processStatement(childNode);
							} else if (childNode.nodeName == 'ph') {
								this.processStatement(childNode);
							}
						}
						this.html += '</li>';
					}
				}
				this.html += '</' + $(innerNode)[0].nodeName + '>';
			}
		}
		this.html += "</statement>";
		return this.html;
	}

	private processStatement(node: any) {
		if (node.nodeName == 'ph') {
			let arrLen = node.attributes.length;
			let attrPh, attrRestriction, value, attrCatId;
			for (let n = 0; n < arrLen; n++) {
				let attrName = node.attributes[n].name;
				let attrValue = node.attributes[n].value;
				if (attrName == 'tagPHId' || attrName == 'tagphid') {
					attrPh = attrValue;
				} else if (attrName == 'restriction') {
					attrRestriction = attrValue;
				} else if (attrName == 'catId') {
					attrCatId = attrValue;
				}
			}
			if (attrPh != undefined && attrRestriction != undefined) {
				value = node.childNodes[0].textContent;
				if (attrCatId != undefined) {
					if (value != undefined) {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
					} else {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
					}
				} else {
					if (value != undefined) {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
					} else {
						this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
					}
				}
			} else if (attrPh != undefined && attrRestriction == undefined) {
				this.html += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "'></ph>";
			}
	    } else if (node.nodeName == 'txt') {
            this.html += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.html += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.html += " fgc='" + fgc + "' "
            }
            this.html += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.html += childTxtNode.textContent;
                } else {
                    this.processStatement(childTxtNode);
                }
            }
            this.html += "</txt>";

        } else if (node.nodeName == 'ol') {
            let listStyle = $(node).attr("liststyle");
            this.html += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                '">';
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                let liNode = $(node)[0].childNodes[i];
                if (liNode.nodeName == 'li') {
                    this.html += '<li>';
                    for (let i = 0; i < liNode.childNodes.length; i++) {
                        let childNode = liNode.childNodes[i];
                        if (childNode.nodeName == 'txt') {
                            $(childNode).removeAttr('style');
                            this.processStatement(childNode);
                        } else if (childNode.nodeName == 'ph') {
                            this.processStatement(childNode);
                        }
                    }
                    this.html += '</li>';
                }
            }
            this.html += '</' + $(node)[0].nodeName + '>';

        } else if (node.nodeName == 'ul') {
            let listStyle = $(node).attr("liststyle");
            this.html += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +
                '">';
            for (let i = 0; i < $(node)[0].childNodes.length; i++) {
                let liNode = $(node)[0].childNodes[i];
                if (liNode.nodeName == 'li') {
                    this.html += '<li>';
                    for (let i = 0; i < liNode.childNodes.length; i++) {
                        let childNode = liNode.childNodes[i];
                        if (childNode.nodeName == 'txt') {
                            $(childNode).removeAttr('style');
                            this.processStatement(childNode);
                        } else if (childNode.nodeName == 'ph') {
                            this.processStatement(childNode);
                        }
                    }
                    this.html += '</li>';
                }
            }
            this.html += '</' + $(node)[0].nodeName + '>';

        } else {
			let str = node.outerHTML;
			str = str.replace(/<font.*?>/gi, '');
			str = str.replace(/<\/font>/gi, '');
			this.html += str;
		}
	}
	
	private getEditorContent(editorName: string): string {
		let el = this.elementRef.nativeElement.querySelector(editorName);
		return el.innerHTML;
	}

	private escapeAttrValues(xml: any) {
		var self = this;
		$(xml).each(function() {
			var oldNode = this.outerHTML;
			var changedNode = false;
			$.each(this.attributes, function() {
				// this.attributes is not a plain object, but an array
				// of attribute nodes, which contain both the name and value
				if(this.specified) {
					if (this.value.includes("<")) {
						changedNode = true;
						this.value = this.value.replace(/</g, "&lt;");
					}
				}
			});
			var newNode = this.outerHTML;
			if (changedNode) {
				self.escapeAttrMap.set(oldNode, newNode);
			}
			$.each(this.childNodes, function() {
				self.escapeAttrValues(this);
			})
		});		
	}

	public processXML(xml: any, pFlag: boolean, xmlContent: any) {
		var self = this;
		this.escapeAttrMap = new Map();

		this.xmlContent = xmlContent;

		xml = xml.replace(/&nbsp;/g, " ");
		xml = xml.replace(/\u200B/g, "");
		xml = xml.replace(/\u000A/g, "");
		xml = xml.replace(/\u0009/g, "");
		xml = xml.replace(/<span.*?>/g, "");
		xml = xml.replace(/<\/span>/g, "");
		xml = xml.replace(/<font.*?>/g, "");
		xml = xml.replace(/<\/font>/g, "");
		xml = xml.replace(/(?!<ph|<para)\<p.*?>/g, "");
		xml = xml.replace(/<\/p>/g, "");
		xml = xml.replace(/<br><\/wintitle>/g, "</wintitle>");
		xml = xml.replace(/(<br>|(<br> )){2,}<ul>/g, "<br><ul>");
		xml = xml.replace(/(<br>|(<br> )){2,}<ol>/g, "<br><ol>");
		xml = xml.replace(/<editarea.*?>*<\/editarea>/g, "");
		xml = xml.replace(/<p><br><\/p>/g, "");
		xml = xml.replace(/<div.*?>*<\/div>/g, "");
		//xml = xml.replace(/<wintitle.(?:(?!<wintitle.*?>).)*?><\/wintitle>/g, "");
		xml = xml.replace(/<para.*?>/g, "<p>");
		xml = xml.replace(/para>/g, "p>");
		xml = xml.replace(/<br type="_moz">/g, "<br>");
		xml = xml.replace(/<br>/g, "<br></br>");
		xml = xml.replace(/ text=\"([^']*?)\"/g, "");
		xml = xml.replace(/‐/g, "-");
		
		if (xml == "") {
			xml = "<br></br>";
		}
		let xmlStr = '<out>' + xml + '</out>';
		$(xmlStr).each(function() {
			var oldNode = this.outerHTML;
			var changedNode = false;			
			$.each(this.attributes, function() {
				// this.attributes is not a plain object, but an array
				// of attribute nodes, which contain both the name and value
				if (this.value.includes("<")) {
					changedNode = true;
					this.value = this.value.replace(/</g, "&lt;");
				}
			});
			var newNode = this.outerHTML;
			if (changedNode) {
				self.escapeAttrMap.set(oldNode, newNode);
			}
			$.each(this.childNodes, function() {
				self.escapeAttrValues(this);
			});
		});
		this.escapeAttrMap.forEach((value: string, key: string) => {
			let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
			key = key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
			let regex = new RegExp(key, 'g');
			xmlStr = xmlStr.replace(regex, newVlaue);
		});
		
		let xmlDoc;
		try {
			xmlDoc = $.parseXML(xmlStr);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('out');
		for (let i = 0; i < outNode[0].childNodes.length; i++) {
			let length = outNode[0].childNodes.length;
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'br') {
				if (length == 1) {
					this.xmlContent += '<p><font backgroundcolor="" color="#000000"></font></p>'
				} else {
					if (i == 0) {
						this.xmlContent += '<p><font backgroundcolor="" color="#000000"></font></p>'
					} else if (i != length - 1) {
						this.xmlContent += '<p>';
						if ($(innerNode)[0].nextSibling.nodeName == 'br' || $(innerNode)[0].nextSibling.nodeName == 'ul' || $(innerNode)[0].nextSibling.nodeName == 'ol') {
							this.xmlContent += '<font backgroundcolor="" color="#000000"></font></p>'
						}
					}
				}
			} else if ($(innerNode)[0].nodeName == 'ul' || $(innerNode)[0].nodeName == 'ol') {
				if( pFlag== true){
					this.processOutXMLWithValue($(innerNode)[0], true);
				} else{
					this.processOutXML($(innerNode)[0]);
				}
			} else {
				if (i == 0 || $(innerNode)[0].previousSibling.nodeName == 'ol' || $(innerNode)[0].previousSibling.nodeName == 'ul' || (i == 1 && $(innerNode)[0].previousSibling.nodeName == 'br')) {
					this.xmlContent += '<p>';
				}
				if( pFlag== true){
					this.processOutXMLWithValue($(innerNode)[0], true);
				}else{
					this.processOutXML($(innerNode)[0]);
				}
				if (i == length - 1 || $(innerNode)[0].nextSibling.nodeName == 'br' || $(innerNode)[0].nextSibling.nodeName == 'ul' || $(innerNode)[0].nextSibling.nodeName == 'ol') {
					this.xmlContent += '</p>';
				}
			}
		}

		//replace following
		this.xmlContent = this.xmlContent.replace(/insid=/g, "insID=");
		this.xmlContent = this.xmlContent.replace(/compvalue=/g, "compValue=");
		this.xmlContent = this.xmlContent.replace(/comptype=/g, "compType=");
		this.xmlContent = this.xmlContent.replace(/compname=/g, "compName=");
		this.xmlContent = this.xmlContent.replace(/lastupdatetimestamp=/g, "lastUpdateTimeStamp=");
		this.xmlContent = this.xmlContent.replace(/tagphid=/g, "tagPHId=");
		this.xmlContent = this.xmlContent.replace(/specid=/g, "specId=");
		this.xmlContent = this.xmlContent.replace(/<spchar/g, "<spChar");
		this.xmlContent = this.xmlContent.replace(/<\/spchar>/g, "</spChar>");
		this.xmlContent = this.xmlContent.replace(/<p><\/p>/g, "");
		this.xmlContent = this.xmlContent.replace(/<p\/>/g, "");
		this.xmlContent = this.xmlContent.replace(/<tags insID=.{38}\/>/g, "");
		this.xmlContent = this.xmlContent.replace(/\\/g, "\\\\");
	}

	processOutXML(node: any) {
		if (node.nodeName == 'ol' || node.nodeName == 'ul') {
			if (node.nodeName == 'ol') {
				let liststyle = "decimal";
				if (node.attributes.length >= 1) {
					var values = node.getAttribute('style').split(":");
					if(values[1] != undefined){
						if(values[1].substring(values[1].length - 1) == ";"){
							liststyle = values[1].substring(0, values[1].length - 1).trim();
						}
						else{
							liststyle = values[1].trim();
						}
					}
				 }
				 if (liststyle == "lower-roman") {
					liststyle = "lowerRoman";
				} else if (liststyle == "lower-alpha") {
					liststyle = "lowerAlpha";
				} else if (liststyle == "upper-roman") {
					liststyle = "upperRoman";
				} else if (liststyle == "upper-alpha") {
					liststyle = "upperAlpha";
				}
				this.xmlContent += '<ol ckeditorlist="true" listStylePosition="outside" listStyleType="' + liststyle + '" paddingLeft="30">' +
					'<listformat fontWeight="normal" paragraphEndIndent="4" textDecoration="none"/>'
			} else if (node.nodeName == 'ul') {
				let liststyle = "disc";
				if (node.attributes.length >= 1) {
					var values = node.getAttribute('style').split(":");
					if(values[1] != undefined){
						if(values[1].substring(values[1].length - 1) == ";"){
							liststyle = values[1].substring(0, values[1].length - 1).trim();
						}
						else{
							liststyle = values[1].trim();
						}
					}
				 }
				this.xmlContent += '<ul ckeditorlist="true" listStylePosition="outside" listStyleType="' + liststyle + '" paddingLeft="30">' +
					'<listformat fontWeight="normal" paragraphEndIndent="4" textDecoration="none"/>'
			}
			this.processListItemXML(node, 1);
			if (node.nodeName == 'ol') {
				this.xmlContent += '</ol>';
			} else if (node.nodeName == 'ul') {
				this.xmlContent += '</ul>';
			}
		} else if (node.nodeName == 'statement' || node.nodeName == 'tags' || node.nodeName == 'fraction' || node.nodeName == '#text' || node.nodeName == 'b' || node.nodeName == 'i' || node.nodeName == 'u' || node.nodeName == 'sup' || node.nodeName == 'sub' || node.nodeName == 'a' || node.nodeName == 'xref') {
			this.processReusablesXML(node, false, false, false, false, false);
		}
	}

	processOutXMLWithValue(node: any, rFlag: boolean) {
		if (node.nodeName == 'ol' || node.nodeName == 'ul') {
			if (node.nodeName == 'ol') {
				this.xmlContent += '<ol listStylePosition="outside" listStyleType="decimal" paddingLeft="30">' +
					'<listformat fontWeight="normal" paragraphEndIndent="4" textDecoration="none"/>'
			} else if (node.nodeName == 'ul') {
				this.xmlContent += '<ul listStylePosition="outside" listStyleType="disc" paddingLeft="30">' +
					'<listformat fontWeight="normal" paragraphEndIndent="4" textDecoration="none"/>'
			}
			this.processListItemXML(node, 1);
			if (node.nodeName == 'ol') {
				this.xmlContent += '</ol>';
			} else if (node.nodeName == 'ul') {
				this.xmlContent += '</ul>';
			}
		} else if (node.nodeName == 'statement' || node.nodeName == 'tags' || node.nodeName == 'fraction' || node.nodeName == '#text' || node.nodeName == 'b' || node.nodeName == 'i' || node.nodeName == 'u' || node.nodeName == 'sup' || node.nodeName == 'sub' || node.nodeName == 'a' || node.nodeName == 'xref') {
			this.processReusablesXMLWithValue(node, false, false, false, false, false);
			
		}
	}

	processReusablesXMLWithValue(node: any, uFlag: boolean, bFlag: boolean, iFlag: boolean, subFlag: boolean, supFlag: boolean ) {
		if (node.nodeName == 'statement') {
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				if (node.childNodes[j].nodeName == 'tags' || node.childNodes[j].nodeName == 'ph' || node.childNodes[j].nodeName == 'txt') {
					this.processStmNode(node.childNodes[j]);
				} else if (node.childNodes[j].nodeName == 'ol' || node.childNodes[j].nodeName == 'ul') {
					let listNode = node.childNodes[j];
					$(listNode).removeAttr('contenteditable');
					for (let j = 0; j < listNode.childNodes.length; j++) {
						let itemNode = listNode.childNodes[j];
						for (let k = 0; k < itemNode.childNodes.length; k++) {
							if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph' || itemNode.childNodes[k].nodeName == 'txt') {
								this.processStmNode(itemNode.childNodes[k]);
							}
						}
					}
				} else if (node.childNodes[j].nodeName == 'p') {
					let pNode = node.childNodes[j];
					$(pNode).removeAttr('contenteditable');
					for (let j = 0; j < pNode.childNodes.length; j++) {
						if (pNode.childNodes[j].nodeName == 'tags' || pNode.childNodes[j].nodeName == 'ph' || pNode.childNodes[j].nodeName == 'txt') {
							this.processStmNode(pNode.childNodes[j]);
						}
					}
				}
			}
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == 'tags') {
			$(node).removeAttr('contenteditable');
			if (iFlag) {
				$(node).attr("italic", "true");
			}
			if (uFlag) {
				$(node).attr("underline", "true");
			}
			if (bFlag) {
				$(node).attr("bold", "true");
			}
			for (let k = 0; k < node.childNodes.length; k++) {
				let tagNode = node.childNodes[k];
				if (tagNode.nodeName == 'tag') {
					if (tagNode.childNodes[0].nodeName == 'fld') {
						let fldChild = tagNode.childNodes[0].childNodes[0];
						if (fldChild.nodeName != 'tagdesc') {
							this.checkBoldItaUnder(fldChild, node);
							$(fldChild).remove();
						} else {
							let tagdesChild = fldChild.childNodes[0];
							let textNode = tagdesChild.childNodes[0];
							this.checkTagDescBoldItaUnder(textNode, node, tagdesChild);
							$(textNode).remove();
						}
					}
				} else if (tagNode.nodeName == 'com') {
					let textNode = tagNode.childNodes[0];
					this.checkBoldItaUnder(textNode, node);
				} else if (tagNode.nodeName == 'spchar') {
					let textNode = tagNode.childNodes[0];
					$(textNode).remove();
				}
			}
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == '#text') {
			if (subFlag) {
				this.xmlContent += '<subscript text="' + node.textContent + '"';
				if (bFlag) {
					this.xmlContent += ' bold="true"';
				}
				if (iFlag) {
					this.xmlContent += ' italic="true"';
				}
				this.xmlContent += '/>';
			} else if (supFlag) {
				this.xmlContent += '<superscript text="' + node.textContent + '"';
				if (bFlag) {
					this.xmlContent += ' bold="true"';
				}
				if (iFlag) {
					this.xmlContent += ' italic="true"';
				}
				this.xmlContent += '/>';
			} else {
				let text = this.htmlEncode($(node)[0].textContent);
				if (bFlag) {
					this.xmlContent += '<B>';
				}
				if (iFlag) {
					this.xmlContent += '<I>';
				}
				if (!uFlag) {
					this.xmlContent += '<font backgroundcolor="" color="#000000">' + text + '</font>';
				} else {
					this.xmlContent += '<font color="#000000" backgroundcolor=""><U>' + text + '</U></font>';
				}
				if (iFlag) {
					this.xmlContent += '</I>';
				}
				if (bFlag) {
					this.xmlContent += '</B>';
				}
			}
		} else if (node.nodeName == 'b') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let bNode = node.childNodes[k];
				if (bNode.nodeName == 'tags' || bNode.nodeName == 'fraction' || bNode.nodeName == 'statement' || bNode.nodeName == '#text' || bNode.nodeName == 'font' || bNode.nodeName == 'i' || bNode.nodeName == 'u' || bNode.nodeName == 'sub' || bNode.nodeName == 'sup' || bNode.nodeName == 'a' || bNode.nodeName == 'xref' || bNode.nodeName == 'br') {
					this.processReusablesXML(bNode, uFlag, true, iFlag, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'u') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let uNode = node.childNodes[k];
				if (uNode.nodeName == 'tags' || uNode.nodeName == 'fraction' || uNode.nodeName == 'statement' || uNode.nodeName == '#text' || uNode.nodeName == 'font' || uNode.nodeName == 'i' || uNode.nodeName == 'b' || uNode.nodeName == 'sub' || uNode.nodeName == 'sup' || uNode.nodeName == 'a' || uNode.nodeName == 'xref' || uNode.nodeName == 'br') {
					this.processReusablesXML(uNode, true, bFlag, iFlag, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'i') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let iNode = node.childNodes[k];
				if (iNode.nodeName == 'tags' || iNode.nodeName == 'fraction' || iNode.nodeName == 'statement' || iNode.nodeName == '#text' || iNode.nodeName == 'font' || iNode.nodeName == 'b' || iNode.nodeName == 'u' || iNode.nodeName == 'sub' || iNode.nodeName == 'sup' || iNode.nodeName == 'a' || iNode.nodeName == 'xref' || iNode.nodeName == 'br') {
					this.processReusablesXML(iNode, uFlag, bFlag, true, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'fraction') {
			$(node).removeAttr('contenteditable');
			if (iFlag) {
				$(node).attr("italic", "true");
			}
			if (uFlag) {
				$(node).attr("underline", "true");
			}
			if (bFlag) {
				$(node).attr("bold", "true");
			}
			let textNode = node.childNodes[0];
			$(textNode).remove();
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == 'sub') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let childNode = node.childNodes[k];
				if (childNode.nodeName == '#text') {
					this.xmlContent += '<subscript text="' + childNode.textContent + '"';
					if (bFlag) {
						this.xmlContent += ' bold="true"';
					}
					if (iFlag) {
						this.xmlContent += ' italic="true"';
					}
					this.xmlContent += '/>';
				} else {
					this.processReusablesXML(childNode, false, false, false, true, false);
				}
			}
		} else if (node.nodeName == 'sup') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let childNode = node.childNodes[k];
				if (childNode.nodeName == '#text') {
					this.xmlContent += '<superscript text="' + childNode.textContent + '"';
					if (bFlag) {
						this.xmlContent += ' bold="true"';
					}
					if (iFlag) {
						this.xmlContent += ' italic="true"';
					}
					this.xmlContent += '/>';
				} else {
					this.processReusablesXML(childNode, false, false, false, false, true);
				}
			}
		} else if (node.nodeName == 'a') {
			let childNode = node.childNodes[0];
			let url = $(node).attr("href");
			this.xmlContent += '<XRef topicId="" xrefType="External" refInsId="" refurl="' + this.htmlEncode(url) + '">';
			if (childNode.nodeName == '#text') {
				this.xmlContent += this.htmlEncode(childNode.textContent);
			}
			this.xmlContent += '</XRef>';
		} else if (node.nodeName == 'xref') {
			let childNode = node.childNodes[0];
			let topicId = $(node).attr("topicid");
			let xrefType = $(node).attr("xreftype");
			let refInsId = $(node).attr("refinsid");
			this.xmlContent += '<XRef style="background-color:#f4b342;" topicId="' + topicId + '" xrefType="' + xrefType + '" refInsId="' + refInsId + '">';
			if (childNode.nodeName == '#text') {
				this.xmlContent += this.htmlEncode(childNode.textContent);
			}
			this.xmlContent += '</XRef>';
		} else if (node.nodeName == 'br') {
			this.xmlContent += '</p><p>';
		}
	}

	processListItemXML(node: any, level: number) {
		for (let i = 0; i < node.childNodes.length; i++) {
			let liNode = node.childNodes[i];
			if (liNode.nodeName == 'li') {
				this.xmlContent += '<li level="' + level + '">';
				for (let i = 0; i < liNode.childNodes.length; i++) {
					let childNode = liNode.childNodes[i];
					if (childNode.nodeName == 'ul') {
						let listStyle = "disc";
						if (childNode.attributes.length >= 1) {
							var values = childNode.getAttribute('style').split(":");
							if(values[1] != undefined){
								if(values[1].substring(values[1].length - 1) == ";"){
									listStyle = values[1].substring(0, values[1].length - 1).trim();
								}
								else{
									listStyle = values[1].trim();
								}
							}
						}
						if (level == 1) {
							listStyle = "circle";
							if (childNode.attributes.length >= 1) {
								var values = childNode.getAttribute('style').split(":");
								if(values[1] != undefined){
									if(values[1].substring(values[1].length - 1) == ";"){
										listStyle = values[1].substring(0, values[1].length - 1).trim();
									}
									else{
										listStyle = values[1].trim();
									}
								}
							}
						} else {
							listStyle = "square";
							if (childNode.attributes.length >= 1) {
								var values = childNode.getAttribute('style').split(":");
								if(values[1] != undefined){
									if(values[1].substring(values[1].length - 1) == ";"){
										listStyle = values[1].substring(0, values[1].length - 1).trim();
									}
									else{
										listStyle = values[1].trim();
									}
								}
							}
						} 
						this.xmlContent += '<' + childNode.nodeName + ' ckeditorlist="true" listStylePosition="outside" listStyleType="' + listStyle + '" paddingLeft="30"' + '>';
						this.processListItemXML($(childNode)[0], level + 1);
						this.xmlContent += '</' + childNode.nodeName + '>';
					} else if (childNode.nodeName == 'ol') {
						let listStyle = "decimal";
						if (childNode.attributes.length >= 1) {
							var values = childNode.getAttribute('style').split(":");
							if(values[1] != undefined){
								if(values[1].substring(values[1].length - 1) == ";"){
									listStyle = values[1].substring(0, values[1].length - 1).trim();
								}
								else{
									listStyle = values[1].trim();
								}
							}
						}
						if (listStyle == "lower-roman") {
							listStyle = "lowerRoman";
						} else if (listStyle == "lower-alpha") {
							listStyle = "lowerAlpha";
						} else if (listStyle == "upper-roman") {
							listStyle = "upperRoman";
						} else if (listStyle == "upper-alpha") {
							listStyle = "upperAlpha";
						}
						this.xmlContent += '<' + childNode.nodeName + ' ckeditorlist="true" listStylePosition="outside" listStyleType="' + listStyle + '" paddingLeft="30"' + '>';
						this.processListItemXML($(childNode)[0], level + 1);
						this.xmlContent += '</' + childNode.nodeName + '>';	
					} else {
						if (i == 0 || childNode.previousSibling.nodeName == 'ol' || childNode.previousSibling.nodeName == 'ul') {
							this.xmlContent += "<p>";
						}
						if (childNode.nodeName == '#text' || childNode.nodeName == 'statement' || childNode.nodeName == 'tags' || childNode.nodeName == 'fraction' || childNode.nodeName == 'b' || childNode.nodeName == 'i' || childNode.nodeName == 'u' || childNode.nodeName == 'sup' || childNode.nodeName == 'sub') {
							this.processReusablesXML(childNode, false, false, false, false, false);
						} else if (childNode.nodeName == 'br') {
							this.xmlContent += '<font backgroundcolor="" color="#000000"></font>';
						}
						if (i == liNode.childNodes.length - 1 || childNode.nextSibling.nodeName == 'ol' || childNode.nextSibling.nodeName == 'ul') {
							this.xmlContent += "</p>";
						}
					}
				}
				this.xmlContent += '</li>';
			}
		}
	}

	processStmNode(node: any) {
		if (node.nodeName == 'tags') {
			let tagsNode = node;
			for (let k = 0; k < tagsNode.childNodes.length; k++) {
				let name = tagsNode.childNodes[k].nodeName;
				if (name == 'tag') {
					let tagNode = tagsNode.childNodes[k];
					if (tagNode.childNodes[0].nodeName == 'fld') {
						let fldChild = tagNode.childNodes[0].childNodes[0];
						if (fldChild.nodeName != 'tagdesc') {
							this.checkBoldItaUnder(fldChild, tagNode.childNodes[0]);
							$(fldChild).remove();
						} else {
							let tagdesChild = fldChild.childNodes[0];
							let textNode = tagdesChild.childNodes[0];
							//this.appendTxtContent(textNode, tagdesChild, false, false, false, null, null);
							this.checkTagDescBoldItaUnder(textNode, node, tagdesChild);
							$(textNode).remove();
						}
					}
				} else if (name == 'com') {
					let node = tagsNode.childNodes[k];
					let textNode = node.childNodes[0];
					this.checkBoldItaUnder(node, node);
					$(textNode).remove();
				} else if (name == 'wintitle') {
					let node = tagsNode.childNodes[k];
					let textNode = node.childNodes[0];
					if (textNode != undefined) {
						$(node).removeAttr('contenteditable');
						$(node).removeAttr('id');
						$(node).attr("text", textNode.textContent);
						this.checkBoldItaUnder(node, node);
						$(textNode).remove();
					}
				} else if (name == 'spchar' || name == 'fraction') {
					let spNode = tagsNode.childNodes[k];
					let textNode = spNode.childNodes[0];
					$(textNode).remove();
				}
			}
		} else if (node.nodeName == 'ph' || node.nodeName == 'txt') {
			let bgc = "";
			let fgc = "";
			let stateNode = node;
			if(stateNode.childNodes.length ==1){
				if(stateNode.childNodes[0].nodeName=="txt"){
					let textNode = stateNode.childNodes[0];
					bgc = $(node).attr("bgc");
					fgc = $(node).attr("fgc")
					this.appendTxtContent(textNode, stateNode, false, false, false, bgc, fgc);
					$(textNode).remove();
				}
				else if(stateNode.childNodes[0].nodeName=="b" || stateNode.childNodes[0].nodeName=="u" || stateNode.childNodes[0].nodeName=="i"){
					let buiNode = stateNode.childNodes[0];
					for(let i = 0; i<buiNode.childNodes.length; i++){
						this.processStmNode(buiNode.childNodes[i]);
					}
				}
			}
			for(let i=0; i<stateNode.childNodes.length; i++){
				if (stateNode.childNodes[i].nodeName == 'ph' || stateNode.childNodes[i].nodeName == 'tags' || stateNode.childNodes[i].nodeName == 'txt') {	
					this.processStmNode(stateNode.childNodes[i]);								
				}
			}
		}
	}

	processReusablesXML(node: any, uFlag: boolean, bFlag: boolean, iFlag: boolean, subFlag: boolean, supFlag: boolean) {
		if (node.nodeName == 'statement') {
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				if (node.childNodes[j].nodeName == 'tags' || node.childNodes[j].nodeName == 'ph' || node.childNodes[j].nodeName == 'txt') {
					this.processStmNode(node.childNodes[j]);
				} else if ( node.childNodes[j].nodeName == 'ul') {
					let listNode = node.childNodes[j];
					$(listNode).attr("listStyleType", "disc");
					$(listNode).removeAttr('contenteditable');
					for (let j = 0; j < listNode.childNodes.length; j++) {
						let itemNode = listNode.childNodes[j];
						for (let k = 0; k < itemNode.childNodes.length; k++) {
							if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
								this.processStmNode(itemNode.childNodes[k]);
							}
							else if (itemNode.childNodes[k].nodeName == 'txt') {
								let txtNode = itemNode.childNodes[k];
								for (let l = 0; l < txtNode.childNodes.length; l++) {
									if (txtNode.childNodes[l].nodeName == 'ph'|| txtNode.childNodes[l].nodeName == 'tags') {
										this.processStmNode(txtNode.childNodes[l]);
									} else if (txtNode.childNodes[l].nodeName == 'txt') {										
										this.processStmNode(txtNode.childNodes[l]);	
									} else if (txtNode.childNodes[l].nodeName == 'ul' || txtNode.childNodes[l].nodeName == 'ol') {	
										this.processStatementMultiLevel(txtNode.childNodes[l]);								
									}
								}
							}
						}
					}
				} else if (node.childNodes[j].nodeName == 'ol') {
					let listNode = node.childNodes[j];
					$(listNode).attr("listStyleType", "decimal");
					$(listNode).removeAttr('contenteditable');
					for (let j = 0; j < listNode.childNodes.length; j++) {
						let itemNode = listNode.childNodes[j];
						for (let k = 0; k < itemNode.childNodes.length; k++) {
							if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
								this.processStmNode(itemNode.childNodes[k]);
							}
							else if (itemNode.childNodes[k].nodeName == 'txt') {
								let txtNode = itemNode.childNodes[k];
								for (let l = 0; l < txtNode.childNodes.length; l++) {
									if (txtNode.childNodes[l].nodeName == 'ph'|| txtNode.childNodes[l].nodeName == 'tags') {
										this.processStmNode(txtNode.childNodes[l]);
									} else if (txtNode.childNodes[l].nodeName == 'txt') {										
										this.processStmNode(txtNode.childNodes[l]);	 
									} else if (txtNode.childNodes[l].nodeName == 'ol' || txtNode.childNodes[l].nodeName == 'ul') {	
										this.processStatementMultiLevel(txtNode.childNodes[l]);								
									}
								}
							}
						}
					}
				} else if (node.childNodes[j].nodeName == 'p') {
					let pNode = node.childNodes[j];
					$(pNode).removeAttr('contenteditable');
					for (let j = 0; j < pNode.childNodes.length; j++) {
						if (pNode.childNodes[j].nodeName == 'tags' || pNode.childNodes[j].nodeName == 'ph') {
							this.processStmNode(pNode.childNodes[j]);
						}
						else if (pNode.childNodes[j].nodeName == 'txt') {
							let txtNode = pNode.childNodes[j];
							for (let k = 0; k < txtNode.childNodes.length; k++) {
								if (txtNode.childNodes[k].nodeName == 'ph' || txtNode.childNodes[k].nodeName == 'tags') {
									this.processStmNode(txtNode.childNodes[k]);
								} else if (txtNode.childNodes[k].nodeName == 'txt') {
									this.processStmNode(txtNode.childNodes[k]);
								}													
							}
						}
					}
				}
			}
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == 'tags') {
			$(node).removeAttr('contenteditable');
			if (iFlag) {
				$(node).attr("italic", "true");
			}
			if (uFlag) {
				$(node).attr("underline", "true");
			}
			if (bFlag) {
				$(node).attr("bold", "true");
			}
			for (let k = 0; k < node.childNodes.length; k++) {
				let tagNode = node.childNodes[k];
				if (tagNode.nodeName == 'tag') {
					if (tagNode.childNodes[0].nodeName == 'fld') {
						let fldChild = tagNode.childNodes[0].childNodes[0];
						if (fldChild.nodeName != 'tagdesc') {
							this.checkBoldItaUnder(fldChild, node);
							$(fldChild).remove();
						} else {
							let tagdesChild = fldChild.childNodes[0];
							let textNode = tagdesChild.childNodes[0];
							this.checkTagDescBoldItaUnder(textNode, node, tagdesChild);
							$(textNode).remove();
						}
					}
				} else if (tagNode.nodeName == 'com') {
					let textNode = tagNode.childNodes[0];
					this.checkBoldItaUnder(textNode, node);
					$(textNode).remove();
				} else if (tagNode.nodeName == 'spchar') {
					let textNode = tagNode.childNodes[0];
					$(textNode).remove();
				}
			}
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == '#text') {
			if (subFlag) {
				this.xmlContent += '<subscript text="' + node.textContent + '"';
				if (bFlag) {
					this.xmlContent += ' bold="true"';
				}
				if (iFlag) {
					this.xmlContent += ' italic="true"';
				}
				this.xmlContent += '/>';
			} else if (supFlag) {
				this.xmlContent += '<superscript text="' + node.textContent + '"';
				if (bFlag) {
					this.xmlContent += ' bold="true"';
				}
				if (iFlag) {
					this.xmlContent += ' italic="true"';
				}
				this.xmlContent += '/>';
			} else {
				let text = this.htmlEncode($(node)[0].textContent);
				if (bFlag) {
					this.xmlContent += '<B>';
				}
				if (iFlag) {
					this.xmlContent += '<I>';
				}
				if (!uFlag) {
					this.xmlContent += '<font backgroundcolor="" color="#000000">' + text + '</font>';
				} else {
					this.xmlContent += '<font color="#000000" backgroundcolor=""><U>' + text + '</U></font>';
				}
				if (iFlag) {
					this.xmlContent += '</I>';
				}
				if (bFlag) {
					this.xmlContent += '</B>';
				}
			}
		} else if (node.nodeName == 'b') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let bNode = node.childNodes[k];
				if (bNode.nodeName == 'tags' || bNode.nodeName == 'fraction' || bNode.nodeName == 'statement' || bNode.nodeName == '#text' || bNode.nodeName == 'font' || bNode.nodeName == 'i' || bNode.nodeName == 'u' || bNode.nodeName == 'sub' || bNode.nodeName == 'sup' || bNode.nodeName == 'a' || bNode.nodeName == 'xref' || bNode.nodeName == 'br') {
					this.processReusablesXML(bNode, uFlag, true, iFlag, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'u') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let uNode = node.childNodes[k];
				if (uNode.nodeName == 'tags' || uNode.nodeName == 'fraction' || uNode.nodeName == 'statement' || uNode.nodeName == '#text' || uNode.nodeName == 'font' || uNode.nodeName == 'i' || uNode.nodeName == 'b' || uNode.nodeName == 'sub' || uNode.nodeName == 'sup' || uNode.nodeName == 'a' || uNode.nodeName == 'xref' || uNode.nodeName == 'br') {
					this.processReusablesXML(uNode, true, bFlag, iFlag, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'i') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let iNode = node.childNodes[k];
				if (iNode.nodeName == 'tags' || iNode.nodeName == 'fraction' || iNode.nodeName == 'statement' || iNode.nodeName == '#text' || iNode.nodeName == 'font' || iNode.nodeName == 'b' || iNode.nodeName == 'u' || iNode.nodeName == 'sub' || iNode.nodeName == 'sup' || iNode.nodeName == 'a' || iNode.nodeName == 'xref' || iNode.nodeName == 'br') {
					this.processReusablesXML(iNode, uFlag, bFlag, true, subFlag, supFlag);
				}
			}
		} else if (node.nodeName == 'fraction') {
			$(node).removeAttr('contenteditable');
			if (iFlag) {
				$(node).attr("italic", "true");
			}
			if (uFlag) {
				$(node).attr("underline", "true");
			}
			if (bFlag) {
				$(node).attr("bold", "true");
			}
			let textNode = node.childNodes[0];
			$(textNode).remove();
			this.xmlContent += node.outerHTML;
		} else if (node.nodeName == 'sub') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let childNode = node.childNodes[k];
				if (childNode.nodeName == '#text') {
					this.xmlContent += '<subscript text="' + childNode.textContent + '"';
					if (bFlag) {
						this.xmlContent += ' bold="true"';
					}
					if (iFlag) {
						this.xmlContent += ' italic="true"';
					}
					this.xmlContent += '/>';
				} else {
					this.processReusablesXML(childNode, false, false, false, true, false);
				}
			}
		} else if (node.nodeName == 'sup') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let childNode = node.childNodes[k];
				if (childNode.nodeName == '#text') {
					this.xmlContent += '<superscript text="' + childNode.textContent + '"';
					if (bFlag) {
						this.xmlContent += ' bold="true"';
					}
					if (iFlag) {
						this.xmlContent += ' italic="true"';
					}
					this.xmlContent += '/>';
				} else {
					this.processReusablesXML(childNode, false, false, false, false, true);
				}
			}
		} else if (node.nodeName == 'a') {
			let childNode = node.childNodes[0];
			let url = $(node).attr("href");
			this.xmlContent += '<XRef topicId="" xrefType="External" refInsId="" refurl="' + this.htmlEncode(url) + '">';
			if (childNode.nodeName == '#text') {
				this.xmlContent += this.htmlEncode(childNode.textContent);
			}
			this.xmlContent += '</XRef>';
		} else if (node.nodeName == 'xref') {
			let childNode = node.childNodes[0];
			let topicId = $(node).attr("topicid");
			let xrefType = $(node).attr("xreftype");
			let refInsId = $(node).attr("refinsid");
			this.xmlContent += '<XRef style="background-color:#f4b342;" topicId="' + topicId + '" xrefType="' + xrefType + '" refInsId="' + refInsId + '">';
			if (childNode.nodeName == '#text') {
				this.xmlContent += this.htmlEncode(childNode.textContent);
			}
			this.xmlContent += '</XRef>';
		} else if (node.nodeName == 'br') {
			this.xmlContent += '</p><p>';
		}
	}

	checkBoldItaUnder(node: any, tagsNode: any) {
		if (node.nodeName == 'b') {
			$(tagsNode).attr("bold", "true");
		} else if (node.nodeName == 'i') {
			$(tagsNode).attr("italic", "true");
		} else if (node.nodeName == 'u') {
			$(tagsNode).attr("underline", "true");
		} if (node.nodeName == '#text') {
			return;
		} else {
			this.checkBoldItaUnder(node.childNodes[0], tagsNode);
		}
	}

	checkTagDescBoldItaUnder(node: any, tagsNode: any, tagDesChild: any) {
		if (node.nodeName == 'b') {
			$(tagsNode).attr("bold", "true");
		} else if (node.nodeName == 'i') {
			$(tagsNode).attr("italic", "true");
		} else if (node.nodeName == 'u') {
			$(tagsNode).attr("underline", "true");
		} if (node.nodeName == '#text') {
			$(tagDesChild).append(this.htmlEncode(node.textContent));
			return;
		} else {
			this.checkTagDescBoldItaUnder(node.childNodes[0], tagsNode, tagDesChild);
		}
	}

	appendTxtContent(node: any, parent: any, b: boolean, i: boolean, u: boolean, bgcVal: any, fgcVal: any) {
		let bFlag: boolean = b;
		let iFlag: boolean = i;
		let uFlag: boolean = u;
		let bgc = bgcVal;
		let fgc = fgcVal;
		let nodeText = "";
		if (node != undefined) {
			if (node.nodeName == '#text') {
				let text = this.htmlEncode(node.textContent);
				if (bFlag && !iFlag && !uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B>" + text + "</B>";
				} else if (!bFlag && iFlag && !uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<I>" + text + "</I>";
				} else if (!bFlag && !iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<U>" + text + "</U>";
				} else if (bFlag && iFlag && !uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B><I>" + text + "</I></B>";
				} else if (bFlag && !iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B><U>" + text + "</U></B>";
				} else if (!bFlag && iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<I><U>" + text + "</U></I>";
				} else if (bFlag && iFlag && uFlag) {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = "<B><I><U>" + text + "</U></I></B>";
				} else {
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
					}
					nodeText = text;	
				}
				$(parent).append(nodeText);
				return;
			} else {
				if (node.nodeName == 'b') {
					bFlag = true;
				} else if (node.nodeName == 'i') {
					iFlag = true;
				} else if (node.nodeName == 'u') {
					uFlag = true;
				} else if (node.nodeName == 'txt') {
					bgc = $(node).attr("bgc");
					fgc = $(node).attr("fgc");
				}
				if(node.childNodes.length==1 && node.nodeName !='b' && node.nodeName !='u' && node.nodeName !='i'){
					this.appendTxtContent(node.childNodes[0], parent, bFlag, iFlag, uFlag, bgc, fgc);
				}else{
					let newClone = node.cloneNode([true]);
					$(parent).append(newClone);
					for (let j = 0; j < node.childNodes.length; j++) {
						// let newClone = node.childNodes[j].cloneNode([true]);
						if(node.childNodes[j].nodeName == 'tags' || node.childNodes[j].nodeName == 'txt'){
							this.processStmNode(node.childNodes[j]);
						}
						//$(parent).append(newClone);
					}
					if ((fgc != null && fgc != "") || (bgc != null && bgc != "")) {
						$(parent).attr("bgc", bgc);
						$(parent).attr("fgc", fgc);
						$(newClone).removeAttr("bgc");
						$(newClone).removeAttr("fgc");
					}
					return
				}
			}
		}
	}

	public editorKeyDown(e: any, editor: any) {
		this.tagContent = '';
		this.selection = editor.getSelection();
		if (this.selection != null) {
			this.ranges = this.selection.getRanges();
		}
		if (this.ranges != null) {
			let range = this.ranges[0];
			if (range != null) {
				range = range.clone();
				var startNode = range.startContainer.$;
				var ancestor = startNode.parentNode;
				if(startNode.nodeName == 'EDITAREA'){
					ancestor=startNode;
				}
				if (this.isFirefox) {
					if (ancestor != null) {
						if (ancestor.nodeName == 'PH') {
							if (this.checkPrintableKeys(e)) {
								let attr = ancestor.attributes;
								if (attr.length != 0) {
									//this.getRestrictionValue(ancestor);
								}
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.guidWinT = this.guid().toUpperCase();
								this.tagContent = '<tags insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '">' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								ancestor.outerHTML = this.tagContent;
								editor.document.getById(this.guidWinT).focus();;
							}
						} else if (ancestor.nodeName == 'EDITAREA') {
							if (this.checkPrintableKeys(e)) {
								let prevNode = $(ancestor).prev()[0];
								let nextNode = $(ancestor).next()[0];
								if (prevNode != undefined && prevNode.nodeName == 'WINTITLE') {
									let text = prevNode.textContent;
									//prevNode.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								} else if (nextNode != undefined && nextNode.nodeName == 'WINTITLE') {
									let text = nextNode.textContent;
									//nextNode.textContent = ' ' + e.data.domEvent.$.key + text.slice(1, text.length);
								} else {
									this.guidWinT = this.guid().toUpperCase();
									this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
										'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
									ancestor.outerHTML = this.tagContent;
									editor.document.getById(this.guidWinT).focus();
								}
							}
						} else if (ancestor.nodeName == 'WINTITLE') {
							ancestor.setAttribute("contenteditable", "true");
						} else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'
							|| ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I' ) {
								if (e.data.keyCode == 8 || e.data.keyCode == 46) {
									while(ancestor.parentNode != undefined){
										if(ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'){
											
											if(this.isStatementParent(ancestor)){
												this.setOuterHTML(ancestor, "");
											}
											break;
											
										}else{
											ancestor = ancestor.parentNode;
										}
									}
								// if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION') {
								// 	if (this.isStatementParent(ancestor)) {
								// 		this.setOuterHTML(ancestor, "");
								// 	}
								// } else if (ancestor.nodeName == 'FLD') {
								// 	if (ancestor.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TAGDESC') {
								// 	if (ancestor.parentNode.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TXT') {
								// 	if (ancestor.parentNode.nodeName != 'STATEMENT' || ancestor.parentNode.nodeName != 'PARA' || ancestor.parentNode.nodeName != 'LI') {
								// 		if (ancestor.parentNode.parentNode.parentNode != undefined) {
								// 			var tagsNode = ancestor.parentNode.parentNode.parentNode;
								// 			if (tagsNode.nodeName == "TAG" && this.isStatementParent(tagsNode)) {
								// 				this.setOuterHTML(tagsNode, "");
								// 			}
								// 		}
								// 	}
								// }
							}
						} else {
							if (startNode != null && (startNode.nodeName == 'STATEMENT' || startNode.nodeName == 'TAGS' || startNode.nodeName == 'PARA' || startNode.nodeName == 'LI')) {
								if (this.checkPrintableKeys(e)) {
									let wintile = editor.document.getById(this.guidWinT).$;
									let text = wintile.textContent;
									wintile.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								}
							}
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				} else {
					if (ancestor != null) {
						if (ancestor.nodeName == 'PH') {
							if (this.checkPrintableKeys(e)) {
								let attr = ancestor.attributes;
								if (attr.length != 0) {
									//this.getRestrictionValue(ancestor);
								}
								let restriction = ancestor.attributes['restriction'].value;
								let tagPHId = ancestor.attributes['tagPHId'].value;
								let catId = ancestor.attributes['catId'] != undefined ? ancestor.attributes['catId'].value : "";
								let text = ancestor.textContent;
								this.guidWinT = this.guid().toUpperCase();
								this.tagContent = '<tags insID="' + this.guid().toUpperCase() +
									'" tagPHId="' + tagPHId + '" restriction="' + restriction + '" catId="' + catId + '" phText="' + text + '">' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
									'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
								this.setOuterHTML(ancestor, this.tagContent);
								editor.document.getById(this.guidWinT).focus();
							}
						} else if (ancestor.nodeName == 'EDITAREA') {
							if (this.checkPrintableKeys(e)) {
								let prevNode = $(ancestor).prev()[0];
								let nextNode = $(ancestor).next()[0];
								if (prevNode != undefined && prevNode.nodeName == 'WINTITLE') {
									let text = prevNode.textContent;
									//prevNode.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								} else if (nextNode != undefined && nextNode.nodeName == 'WINTITLE') {
									let text = nextNode.textContent;
									//nextNode.textContent = ' ' + e.data.domEvent.$.key + text.slice(1, text.length);
								} else {
									this.guidWinT = this.guid().toUpperCase();
									this.tagContent = '<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea><wintitle contenteditable="true" style="background-color:#cfe2f3;" insID="' + this.guidWinT + '" id="' + this.guidWinT + '"></wintitle>' +
										'<editarea style="background-color:#ea9999;">&nbsp;&nbsp;&nbsp;</editarea></tags>';
									this.setOuterHTML(ancestor, this.tagContent);
									editor.document.getById(this.guidWinT).focus();
								}
							}
						} else if (ancestor.nodeName == 'WINTITLE') {
							ancestor.setAttribute("contenteditable", "true");
						} else if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'FLD' || ancestor.nodeName == 'TAGDESC' || ancestor.nodeName == 'TXT' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'
							|| ancestor.nodeName == 'B' || ancestor.nodeName == 'U' || ancestor.nodeName == 'I' ) {
								if (e.data.keyCode == 8 || e.data.keyCode == 46) {
									while(ancestor.parentNode != undefined){
										if(ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION'){
											
											if(this.isStatementParent(ancestor)){
												this.setOuterHTML(ancestor, "");
											}
											break;
											
										}else{
											ancestor = ancestor.parentNode;
										}
									}
								// if (ancestor.nodeName == 'TAG' || ancestor.nodeName == 'COM' || ancestor.nodeName == 'SPCHAR' || ancestor.nodeName == 'FRACTION') {
								// 	if (this.isStatementParent(ancestor)) {
								// 		this.setOuterHTML(ancestor, "");
								// 	}
								// } else if (ancestor.nodeName == 'FLD') {
								// 	if (ancestor.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TAGDESC') {
								// 	if (ancestor.parentNode.parentNode != undefined) {
								// 		var tagsNode = ancestor.parentNode.parentNode;
								// 		if (this.isStatementParent(tagsNode)) {
								// 			this.setOuterHTML(tagsNode, "");
								// 		}
								// 	}
								// } else if (ancestor.nodeName == 'TXT') {
								// 	if (ancestor.parentNode.nodeName != 'STATEMENT' || ancestor.parentNode.nodeName != 'PARA' || ancestor.parentNode.nodeName != 'LI') {
								// 		if (ancestor.parentNode.parentNode.parentNode != undefined) {
								// 			var tagsNode = ancestor.parentNode.parentNode.parentNode;
								// 			if (tagsNode.nodeName == "TAG" && this.isStatementParent(tagsNode)) {
								// 				this.setOuterHTML(tagsNode, "");
								// 			}
								// 		}
								// 	}
								// }
							}
						} else {
							if (startNode != null && (startNode.nodeName == 'STATEMENT' || startNode.nodeName == 'TAGS' || startNode.nodeName == 'PARA' || startNode.nodeName == 'LI')) {
								if (this.checkPrintableKeys(e)) {
									let wintile = editor.document.getById(this.guidWinT).$;
									let text = wintile.textContent;
									wintile.textContent = text.slice(0, text.length - 1) + e.data.domEvent.$.key + ' ';
								}
							}
						}
					} else {
						this.alertService.clear();
						this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
					}
				}
			}
		}
	}

	setOuterHTML(node: any, content: string) {
		if (node.parentNode != undefined) {
			node.outerHTML = content;
		} else {
			this.alertService.clear();
			this.alertService.info(this.translate.instant('TAG_EDIT_AREA.NOT_VALID_SELECTION'), true);
		}
	}

	private checkPrintableKeys(e: any): boolean {
		let printable: boolean = false;
		let keycode = e.data.domEvent.$.keyCode;
		printable = keycode == 32 ||
			(keycode > 47 && keycode < 58) ||
			(keycode > 64 && keycode < 91) ||
			(keycode > 95 && keycode < 112) ||
			(keycode > 185 && keycode < 193) ||
			(keycode > 218 && keycode < 223);
		return printable;
	}

	private getRestrictionValue(item: any) {
        var res = item.attributes['restriction']
        if (res != undefined) {
            var restriction = item.attributes['restriction'].value;
            if (restriction == "doc") {
                restriction = "documents";
            } else if (restriction == "com") { 
                restriction = "components";
                let compCategoryId = "";
                if (item.attributes['catid'] != undefined) {
                    compCategoryId = item.attributes['catid'].value;
                }
				if (compCategoryId != null && compCategoryId != "") {   
					this.courseService.getCategoryList("Component")
				.subscribe(
					data => {
						this.categoryNameList = []
						if (data.payload) {
							console.log("category payload");
							console.log(data.payload);
							data.payload.forEach(element => {
								this.categoryNameList.push({ "categoryId": element.categoryId, 
								"categoryName": element.categoryName,
								"categoryType": element.categoryType
							})
							});
							for (let i = 0; i < this.categoryNameList.length; i++) {                        
								if (this.categoryNameList[i].categoryId == compCategoryId){
									this.categoryName = this.categoryNameList[i].categoryName;
									break;
								}                         
							}
						} else {
						}
						if (compCategoryId != null) {
							SharedService.searchComponentEventEmit(this.categoryName);
						}   						
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				}          
				else {
					SharedService.searchComponentEventEmit("");
				}
            } else if (restriction == "tag") {
                restriction = "tags";
            } else if (restriction == "all") {
                restriction = "tags";
            } else if (restriction == "sys") {
                restriction = "systems";
            }
            SharedService.setStatementHtmlString(restriction);
			SharedService.openReusablepanel(restriction);
			setTimeout(() => {
				$('input[name=symbiosis_search]').focus();
			}, 500);                 
        }
	}
	
	public getRestrictionValueOndblClick(item: any, catId:any) {
        if (item != undefined) {
            var restriction = item;
            if (item == "doc") {
                restriction = "documents";
            } else if (item == "com") { 
				restriction = "components";
                let compCategoryId = "";
                if (catId != undefined && catId != null) {
                    compCategoryId = catId;
                }   
				if (compCategoryId != null && compCategoryId != "") {   
					this.courseService.getCategoryList("Component")
				.subscribe(
					data => {
						this.categoryNameList = []
						if (data.payload) {
							console.log("category payload");
							console.log(data.payload);
							data.payload.forEach(element => {
								this.categoryNameList.push({ "categoryId": element.categoryId, 
								"categoryName": element.categoryName,
								"categoryType": element.categoryType
							})
							});
							for (let i = 0; i < this.categoryNameList.length; i++) {                        
								if (this.categoryNameList[i].categoryId == compCategoryId){
									this.categoryName = this.categoryNameList[i].categoryName;
									break;
								}                         
							}
						} else {
						}
						if (compCategoryId != null) {
							SharedService.searchComponentEventEmit(this.categoryName);
						}   						
					},
					error => {
						this.alertService.clear()
						this.alertService.error(error.statusDescription)
					});
				}          
				else {
					SharedService.searchComponentEventEmit("");
				}
            } else if (item == "tag") {
                restriction = "tags";
            } else if (item == "all") {
                restriction = "tags";
            } else if (item == "sys") {
                restriction = "systems";
            }
            SharedService.setStatementHtmlString(restriction);
			SharedService.openReusablepanel(restriction);
			setTimeout(() => {
				$('input[name=symbiosis_search]').focus();
			}, 500);                 
        }
	}
	
	createEditedStatement(statementContent: string, lastUpdateTimeStamp: string, node: any): string {
		this.stmHtml = '';
		let xmlDoc;
		try {
			xmlDoc = $.parseXML(statementContent);
		} catch {
			this.alertService.clear();
			this.alertService.error(this.translate.instant('INVALID_XML_MSG'), true);
		}
		let outNode = $(xmlDoc).find('statement');
		let length = outNode[0].childNodes.length;
		let id = outNode.attr('id');
		let label = this.htmlEncode(outNode.attr('label'));
		this.stmHtml = "<statement style='background-color:#d9d9d9;' insID='" + this.guid() + "' lastUpdateTimeStamp='" + lastUpdateTimeStamp + "' contenteditable='false' id='" +
			id + "' label='" + label + "'>";
		for (let i = 0; i < length; i++) {
			let innerNode = outNode[0].childNodes[i];
			if ($(innerNode)[0].nodeName == 'txt') {
                this.stmHtml += "<txt";
                let bgc = $(innerNode).attr("bgc");     
                    let fgc = $(innerNode).attr("fgc");     
                    if (bgc != null && bgc != "") {     
                        this.stmHtml += " bgc='"+bgc+ "' "      
                    }       
                    if (fgc != null && fgc != "") {     
                        this.stmHtml += " fgc='"+fgc+ "' "      
                    }       
                    this.stmHtml += ">";
                for (let j = 0; j < $(innerNode)[0].childNodes.length; j++) {
                    let childTxtNode = $(innerNode)[0].childNodes[j];
                    if ($(childTxtNode)[0].nodeName == '#text') {
                        this.stmHtml += childTxtNode.textContent;
                    } else {
                        this.processNewStatement(childTxtNode, node);
                    }
                }
                this.stmHtml += "</txt>";
            } else if ($(innerNode)[0].nodeName == 'ph') {
                this.processNewStatement($(innerNode)[0], node);
            } else if ($(innerNode)[0].nodeName == 'p') {
                if ($(innerNode)[0].childNodes.length != 0) {
                    this.stmHtml += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">';
                } else {
                    this.stmHtml += '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">&nbsp;';
                }
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let childNode = $(innerNode)[0].childNodes[i];
                    if ($(childNode)[0].nodeName == 'txt') {
                        this.stmHtml += "<txt";
                        let bgc = $(innerNode).attr("bgc");     
                            let fgc = $(innerNode).attr("fgc");     
                            if (bgc != null && bgc != "") {     
                                this.stmHtml += " bgc='"+bgc+ "' "      
                            }       
                            if (fgc != null && fgc != "") {     
                                this.stmHtml += " fgc='"+fgc+ "' "      
                            }       
                            this.stmHtml += ">";
                        for (let j = 0; j < $(childNode)[0].childNodes.length; j++) {
                            let childTxtNode = $(childNode)[0].childNodes[j];
                            if ($(childTxtNode)[0].nodeName == '#text') {
                                this.stmHtml += this.htmlEncode(childTxtNode.textContent);
                            } else {
                                this.processNewStatement(childTxtNode, node);
                            }
                        }
                        this.stmHtml += "</txt>";
                    } else if ($(childNode)[0].nodeName == 'ph') {
                        this.processNewStatement($(childNode)[0], node);
                    }
                }
                this.stmHtml += '</para> ';
            } else if ($(innerNode)[0].nodeName == 'ol' || $(innerNode)[0].nodeName == 'ul') {
                this.stmHtml += '<' + $(innerNode)[0].nodeName + ' contenteditable="false" style="background-color:#d9d9d9;">';
                for (let i = 0; i < $(innerNode)[0].childNodes.length; i++) {
                    let liNode = $(innerNode)[0].childNodes[i];
                    if (liNode.nodeName == 'li') {
                        this.stmHtml += '<li>';
                        for (let i = 0; i < liNode.childNodes.length; i++) {
                            let childNode = liNode.childNodes[i];
                            if (childNode.nodeName == 'txt') {
                                $(childNode).removeAttr('style');
                                this.processNewStatement(childNode, node);
                            } else if (childNode.nodeName == 'ph') {
                                this.processNewStatement(childNode, node);
                            }
                        }
                        this.stmHtml += '</li>';
                    }
                }
                this.stmHtml += '</' + $(innerNode)[0].nodeName + '>';
            }
        }
        this.stmHtml += "</statement>";
		return this.stmHtml;
	}

	private getOldTagsNode(tagPHId:String, node: any){
		if(node.nodeName == "tags" || node.nodeName == "TAGS" || node.nodeName == "ph"){
			if($(node).attr('tagphid') == tagPHId){
				return node;
			}else{
				return null;
			}
		}else{
			for(let i=0; i<node.childNodes.length;i++){
				let tagsNode = this.getOldTagsNode(tagPHId, node.childNodes[i])
				if(tagsNode != null){
					return tagsNode;
				}
			}
		}
		return null;
	}

	private processNewStatement(node: any, oldStmNode: any) {
		if (node.nodeName == 'ph') {
			let isTags: boolean = false;
			let arrLen = node.attributes.length;
			let attrPh, attrRestriction, value, attrCatId;
			for (let n = 0; n < arrLen; n++) {
				let attrName = node.attributes[n].name;
				let attrValue = node.attributes[n].value;
				if (attrName == 'tagPHId' || attrName == 'tagphid') {
					attrPh = attrValue;
				} else if (attrName == 'restriction') {
					attrRestriction = attrValue;
				} else if (attrName == 'catId') {
					attrCatId = attrValue;
				}
			}
			let oldTagsNode = this.getOldTagsNode(attrPh, oldStmNode);
			if (oldTagsNode != null) {
				this.stmHtml += oldTagsNode.outerHTML;
				isTags = true;
			}
			if (!isTags) {
				if (attrPh != undefined && attrRestriction != undefined) {
					value = node.childNodes[0].textContent;
					if (attrCatId != undefined) {
						if (value != undefined) {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
						} else {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' catId='" + attrCatId + "' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
						}
					} else {
						if (value != undefined) {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'>" + value + "</ph>";
						} else {
							this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "' restriction='" + attrRestriction + "'></ph>";
						}
					}
				} else if (attrPh != undefined && attrRestriction == undefined) {
					this.stmHtml += "<ph insID='" + this.guid() + "' style='background-color:#ffffcc ;' tagPHId='" + attrPh + "'></ph>";
				}
			}
		} else if (node.nodeName == 'txt') {
            this.stmHtml += "<txt";
            let bgc = $(node).attr("bgc");
            let fgc = $(node).attr("fgc");
            if (bgc != null && bgc != "") {
                this.stmHtml += " bgc='" + bgc + "' "
            }
            if (fgc != null && fgc != "") {
                this.stmHtml += " fgc='" + fgc + "' "
            }
            this.stmHtml += ">";
            for (let j = 0; j < $(node)[0].childNodes.length; j++) {
                let childTxtNode = $(node)[0].childNodes[j];
                if ($(childTxtNode)[0].nodeName == '#text') {
                    this.stmHtml += childTxtNode.textContent;
                } else {
                    this.processNewStatement(childTxtNode, oldStmNode);
                }
            }
			this.stmHtml += "</txt>";
		} else if (node.nodeName == 'ol') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';		
		
		} else if (node.nodeName == 'ul') {		
			let listStyle = $(node).attr("liststyle");		
			this.stmHtml += '<' + $(node)[0].nodeName + ' contenteditable="false" ckeditorlist="true" style="background-color:#d9d9d9; list-style-type:' + listStyle +		
				'">';		
			for (let i = 0; i < $(node)[0].childNodes.length; i++) {		
				let liNode = $(node)[0].childNodes[i];		
				if (liNode.nodeName == 'li') {		
					this.stmHtml += '<li>';		
					for (let i = 0; i < liNode.childNodes.length; i++) {		
						let childNode = liNode.childNodes[i];		
						if (childNode.nodeName == 'txt') {		
							$(childNode).removeAttr('style');		
							this.processNewStatement(childNode, oldStmNode);		
						} else if (childNode.nodeName == 'ph') {		
							this.processNewStatement(childNode, oldStmNode);		
						}		
					}		
					this.stmHtml += '</li>';		
				}		
			}		
			this.stmHtml += '</' + $(node)[0].nodeName + '>';
		}else if (node.nodeName == 'b' || node.nodeName == 'u' || node.nodeName == 'i'){
			this.stmHtml += '<'+node.nodeName+'>';
			for (let i = 0; i < node.childNodes.length; i++) {		
				let childNode = node.childNodes[i];		
				this.processNewStatement(childNode, oldStmNode);		
			}
			this.stmHtml += '</'+node.nodeName+'>';
		}else if (node.nodeName == '#text'){
			this.stmHtml += node.nodeValue;
		}else {
			let str = node.outerHTML;
			if(str != undefined){
				str = str.replace(/<font.*?>/gi, '');
				str = str.replace(/<\/font>/gi, '');
				this.stmHtml += str;
			}
		}
	}

	private processStatementMultiLevel(node: any) {
		if (node.nodeName == 'ol') {
			for (let x = 0; x < node.attributes.length; x++) {
				if (node.attributes[x].name == "style") {
					var values = node.attributes[x].nodeValue.split(" ");
					var listst = values[1] ? node.attributes[x].nodeValue.substr(node.attributes[x].nodeValue.indexOf(' ') + 1) : '';
					var values1 = node.attributes[x].nodeValue.split(":");
					let listsyle1 = values1[1] ? listst.substr(listst.indexOf(':') + 1) : '';
					let liststyle = listsyle1.trim();
					if (liststyle == "lower-roman") {
						liststyle = "lowerRoman";
					} else if (liststyle == "lower-alpha") {
						liststyle = "lowerAlpha";
					} else if (liststyle == "upper-roman") {
						liststyle = "upperRoman";
					} else if (liststyle == "upper-alpha") {
						liststyle = "upperAlpha";
					}
					$(node).attr("listStyleType", liststyle);
				}
			}
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				let itemNode = node.childNodes[j];
				for (let k = 0; k < itemNode.childNodes.length; k++) {
					if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
						this.processStmNode(itemNode.childNodes[k]);
					}
					else if (itemNode.childNodes[k].nodeName == 'txt') {
						let txtNode = itemNode.childNodes[k];
						for (let l = 0; l < txtNode.childNodes.length; l++) {
							if (txtNode.childNodes[l].nodeName == 'ph' || txtNode.childNodes[l].nodeName == 'tags') {
								this.processStmNode(txtNode.childNodes[l]);
							} else if (txtNode.childNodes[l].nodeName == 'txt') {										
								this.processStmNode(txtNode.childNodes[l]);											
							} else if (txtNode.childNodes[l].nodeName == 'ol' || txtNode.childNodes[l].nodeName == 'ul') {										
								this.processStatementMultiLevel(txtNode.childNodes[l]);											
							}	 	
						}
					}
				}
			}
		} else if (node.nodeName == 'ul') {
			for (let x = 0; x < node.attributes.length; x++) {
				if (node.attributes[x].name == "style") {			
					var values = node.attributes[x].nodeValue.split(" ");
					var listst = values[1] ? node.attributes[x].nodeValue.substr(node.attributes[x].nodeValue.indexOf(' ') + 1) : '';
					var values1 = node.attributes[x].nodeValue.split(":");
					let listsyle1 = values1[1] ? listst.substr(listst.indexOf(':') + 1) : '';
					let liststyle = listsyle1.trim();
					$(node).attr("listStyleType", liststyle);
				}
			}
			$(node).removeAttr('contenteditable');
			for (let j = 0; j < node.childNodes.length; j++) {
				let itemNode = node.childNodes[j];
				for (let k = 0; k < itemNode.childNodes.length; k++) {
					if (itemNode.childNodes[k].nodeName == 'tags' || itemNode.childNodes[k].nodeName == 'ph') {
						this.processStmNode(itemNode.childNodes[k]);
					}
					else if (itemNode.childNodes[k].nodeName == 'txt') {
						let txtNode = itemNode.childNodes[k];
						for (let l = 0; l < txtNode.childNodes.length; l++) {
							if (txtNode.childNodes[l].nodeName == 'ph' || txtNode.childNodes[l].nodeName == 'tags') {
								this.processStmNode(txtNode.childNodes[l]);
							} else if (txtNode.childNodes[l].nodeName == 'txt') {										
								this.processStmNode(txtNode.childNodes[l]);											
							} else if (txtNode.childNodes[l].nodeName == 'ul'|| txtNode.childNodes[l].nodeName == 'ol') {										
								this.processStatementMultiLevel(txtNode.childNodes[l]);											
							}	 	
						}
					}
				}
			}
		}
	}

	private findUpTag(el, tag) {
		while (el.parentNode) {
			el = el.parentNode;
			if (el.tagName === tag)
				return el;
		}
		return null;
	}

	processPartialStmFF(outNode: any, idList: string[]) {
		this.partialStm = "";
		for (let j = 0; j < outNode.childNodes.length; j++) {
			let node = outNode.childNodes[j];
			if (node.nodeName == 'statement') {
				if (idList.includes(node.attributes["insid"].value, 1)) {
					for (let j = 0; j < node.childNodes.length; j++) {
						let childNode = node.childNodes[j];
						if (childNode.nodeName == 'txt') {
							for(let i = 0; i < childNode.childNodes.length; i++){
								if (childNode.childNodes[i].nodeName == 'tags' || childNode.childNodes[i].nodeName == 'txt' || childNode.childNodes[i].nodeName == '#text' || childNode.childNodes[i].nodeName == 'ph') {	
									this.processPartialStmNode(childNode.childNodes[i]);								
								} else if(childNode.childNodes[i].nodeName == "b" || childNode.childNodes[i].nodeName == "u" || childNode.childNodes[i].nodeName == "i"){
									let buiNode = childNode.childNodes[i];
									this.partialStm += '<' + buiNode.nodeName + '>';
									for(let i = 0; i < buiNode.childNodes.length; i++){
										this.processPartialStmNode(buiNode.childNodes[i]);
									}
									this.partialStm += '</' + buiNode.nodeName + '>';
								}					 	
							}										
						} else if (childNode.nodeName == 'ph') {
							this.partialStm  += childNode.textContent;
						} else if (childNode.nodeName == 'tags' || childNode.nodeName == 'p' || childNode.nodeName == 'para') {
							this.processPartialStmNode(childNode);
						} else if (childNode.nodeName == 'ul' || childNode.nodeName == 'ol') {
							this.partialStm  += childNode.textContent;
						} else if (childNode.nodeName == "b" || childNode.nodeName == "u" || childNode.nodeName == "i"){
							this.partialStm += '<' + childNode.nodeName + '>';
							for(let i = 0; i < childNode.childNodes.length; i++){
								this.processPartialBUINode(childNode.childNodes[i]);
							}
							this.partialStm += '</' + childNode.nodeName + '>';
						} 				
					}
				} else {
					this.partialStm += node.outerHTML;
				}
			} else {
				this.processPartialStmNode(node);
			}
		}
	}
	
	processPartialStm(outNode: any) {
		this.partialStm = "";
		for (let j = 0; j < outNode.childNodes.length; j++) {
			let node = outNode.childNodes[j];
			if (node.nodeName == 'statement') {
				for (let j = 0; j < node.childNodes.length; j++) {
					let childNode = node.childNodes[j];
					if (childNode.nodeName == 'txt') {
						for(let i = 0; i < childNode.childNodes.length; i++){
							if (childNode.childNodes[i].nodeName == 'tags' || childNode.childNodes[i].nodeName == 'txt' || childNode.childNodes[i].nodeName == '#text' || childNode.childNodes[i].nodeName == 'ph') {	
								this.processPartialStmNode(childNode.childNodes[i]);								
							} else if(childNode.childNodes[i].nodeName == "b" || childNode.childNodes[i].nodeName == "u" || childNode.childNodes[i].nodeName == "i"){
								let buiNode = childNode.childNodes[i];
								this.partialStm += '<' + buiNode.nodeName + '>';
								for(let i = 0; i < buiNode.childNodes.length; i++){
									this.processPartialStmNode(buiNode.childNodes[i]);
								}
								this.partialStm += '</' + buiNode.nodeName + '>';
							}					 	
						}										
					} else if (childNode.nodeName == 'ph') {
						this.partialStm  += childNode.textContent;
					} else if (childNode.nodeName == 'tags' || childNode.nodeName == 'p' || childNode.nodeName == 'para') {
						this.processPartialStmNode(childNode);
					} else if (childNode.nodeName == 'ul' || childNode.nodeName == 'ol') {
						this.partialStm  += childNode.textContent;
					} else if (childNode.nodeName == "b" || childNode.nodeName == "u" || childNode.nodeName == "i"){
						this.partialStm += '<' + childNode.nodeName + '>';
						for(let i = 0; i < childNode.childNodes.length; i++){
							this.processPartialBUINode(childNode.childNodes[i]);
						}
						this.partialStm += '</' + childNode.nodeName + '>';
					} 				
				}
			} else {
				this.processPartialStmNode(node);
			}
		}
	}

	processPartialStmNode(node: any) {
		if (node.nodeName == 'tags') {
			for (let k = 0; k < node.childNodes.length; k++) {
				let tagNode = node.childNodes[k];
				if (tagNode.nodeName == '#text' || tagNode.nodeName == 'wintitle') {
					this.partialStm += this.htmlEncode(tagNode.textContent);	
				} else if (tagNode.nodeName == 'tag' || tagNode.nodeName == 'com' || tagNode.nodeName == 'spchar') {										
					this.partialStm += '<tags contenteditable="false" insID="' + this.guid().toUpperCase() + '" style="background-color: #FFFF8D;">' + tagNode.outerHTML + '</tags>';
				} else if (tagNode.nodeName == 'fraction') {
					this.partialStm += tagNode.outerHTML;
				}
			}
		} else if (node.nodeName == 'txt') {
			for(let i = 0; i < node.childNodes.length; i++){
				if (node.childNodes[i].nodeName == 'tags' || node.childNodes[i].nodeName == 'txt') {	
					this.processPartialStmNode(node.childNodes[i]);								
				} else if (node.childNodes[i].nodeName == '#text') {
					this.partialStm  += this.htmlEncode(node.childNodes[i].textContent);
				} else if (node.childNodes[i].nodeName == 'ph') {
					this.partialStm  += node.childNodes[i].textContent;
				} else if(node.childNodes[i].nodeName == "b" || node.childNodes[i].nodeName == "u" || node.childNodes[i].nodeName == "i"){
					let buiNode = node.childNodes[i];
					this.partialStm += '<' + buiNode.nodeName + '>';
					for(let i = 0; i < buiNode.childNodes.length; i++){
						this.processPartialStmNode(buiNode.childNodes[i]);
					}
					this.partialStm += '</' + buiNode.nodeName + '>';
				}
			}
		} else if (node.nodeName == 'p' || node.nodeName == 'para') {
			for (let j = 0; j < node.childNodes.length; j++) {
				if (node.childNodes[j].nodeName == 'txt' || node.childNodes[j].nodeName == 'tags' || node.childNodes[j].nodeName == 'ph') {
					this.processPartialStmNode(node.childNodes[j]);
				} else if (node.childNodes[j].nodeName == 'br') {
					this.partialStm += '<br><br>';
				} else if (node.nodeName == '#text') {
					this.partialStm += this.htmlEncode(node.textContent);	
				} 
			}
		} else if (node.nodeName == 'ul' || node.nodeName == 'ol') {
			this.partialStm  += node.outerHTML;
		} else if(node.nodeName == "b" || node.nodeName == "u" || node.nodeName == "i"){
			this.partialStm += '<' + node.nodeName + '>';
			for(let i = 0; i < node.childNodes.length; i++){
				this.processPartialStmNode(node.childNodes[i]);
			}
			this.partialStm += '</' + node.nodeName + '>';
		} else if (node.nodeName == '#text' || node.nodeName == 'span') {
			this.partialStm += this.htmlEncode(node.textContent);	
		} else if (node.nodeName == 'ph') {
			this.partialStm  += node.textContent
		} else if (node.nodeName == 'br') {
			this.partialStm += '<br>';
		}
	}

	processPartialBUINode(node: any) {
		if (node.nodeName == "b" || node.nodeName == "u" || node.nodeName == "i"){
			this.partialStm += '<' + node.nodeName + '>';
			for(let i = 0; i < node.childNodes.length; i++){
				this.processPartialBUINode(node.childNodes[i]);
			}
			this.partialStm += '</' + node.nodeName + '>';
		} else {
			this.partialStm  += node.textContent;
		}
	}

	replaceInsIdOnPaste(data: string) : string {
		if (data.match(/insid=""/g) != null) {
			data = data.replace(/insid=""/gi, function insId() {
					function s4() {
						return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
					}
					return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
			});
		}
		data = data.replace(/(insid)=.{38}/gi, function insId() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
			}
			return 'insId="' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4() + '"';
		});
		return data;
	}

	processPasteData(data: string) : string {
		var self = this;
		this.escapeAttrMap = new Map();
		this.hasResuables = false;
		data = data.replace(/<div.*?>/g, "");
		data = data.replace(/<\/div>/g, "");
		data = data.replace(/<br.*?>/g, "<br/>");
		data = data.replace(/&nbsp;/g, "&#160;");
		if(this.isFirefox) {
			if (localStorage.getItem('partialStmTxt') != null && localStorage.getItem('partialStmTxt').includes("Statement")) {	
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);			
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {																
					let copyArray = data.match(/<statement.*?>*<\/statement>/g);
					if(copyArray != null) {
						for(let i in copyArray) {
							let copyData = copyArray[i];
							let originalData = copyData;
							copyData = copyData.replace(/(?!<ph)\<p.*?>/g, '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">').replace(/p>/g, "para>").replace(/<br\/>/g, "&#160;");	
							data = data.replace(originalData, copyData);
						}
					}
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "");
					let idStr = localStorage.getItem('partialStmTxt');
					let idList = idStr.split(':')
					for(let j = 1; j < idList.length; j++) {
						let expression = 'insid="' +idList[j] + '".*?>*<\/statement>';
						let regex = new RegExp(expression, 'g');
						let resultArray = data.match(regex);
						if(resultArray != null) {
							let copyData = resultArray[0];
							let originalData = copyData;
							copyData = copyData.replace(/<editarea.*?>*<\/editarea>/g, " ");
							copyData = copyData.replace(/<wintitle.*?>/g, "");
							copyData = copyData.replace(/<\/wintitle>/g, "");
							data = data.replace(originalData, copyData);
						}
					}
					var xmlStr: string = '<xml>' + data + '</xml>'
					$(xmlStr).each(function() {
						var oldNode = this.outerHTML;
						var changedNode = false;			
						$.each(this.attributes, function() {
							if (this.value.includes("<")) {
								changedNode = true;
								this.value = this.value.replace(/</g, "&lt;");
							}
						});
						var newNode = this.outerHTML;
						if (changedNode) {
							self.escapeAttrMap.set(oldNode, newNode);
						}
						$.each(this.childNodes, function() {
							self.escapeAttrValues(this);
						});
					});
					this.escapeAttrMap.forEach((value: string, key: string) => {
						let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
						key = key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
						let regex = new RegExp(key, 'g');
						xmlStr = xmlStr.replace(regex, newVlaue);
					});
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.processPartialStmFF(outNode[0], idList);
					this.partialStm = this.replaceInsIdOnPaste(this.partialStm);
					return this.partialStm;
				}
			} else {
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);			
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {																						
					var xmlStr: string = '<xml>' + data + '</xml>'
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
					let copyArray = data.match(/<statement.*?>*<\/statement>/g);
					if (copyArray != null) {
						for(let i in copyArray) {
							let copyData = copyArray[i];
							let originalData = copyData;
							copyData = copyData.replace(/(?!<ph)\<p.*?>/g, '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">').replace(/p>/g, "para>").replace(/<br\/>/g, "&nbsp;");	
							data = data.replace(originalData, copyData);
						}
					}
					var xrefEle = xmlDoc.getElementsByTagName("xref")[0];
					if (xrefEle !== undefined) {
						data = data.replace(/refinsid/gi, "ref_ins");
					}
					data = this.replaceInsIdOnPaste(data);
					if (xrefEle !== undefined) {		
						data = data.replace(/ref_ins/gi, "refinsid");		
					}
					data = data.replace(/<p><br\/><\/p>/g, '<br>');
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "<br>");
					return data;
				}
			} 
		} else {
			if (localStorage.getItem('partialStmTxt') != null && localStorage.getItem('partialStmTxt').includes("Statement")) {	
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);			
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {																			
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "");
					data = data.replace(/<editarea.*?>*<\/editarea>/g, " ");
					data = data.replace(/<wintitle.*?>/g, "");
					data = data.replace(/<\/wintitle>/g, "");
					var xmlStr: string = '<xml>' + data + '</xml>'
					$(xmlStr).each(function() {
						var oldNode = this.outerHTML;
						var changedNode = false;			
						$.each(this.attributes, function() {
							if (this.value.includes("<")) {
								changedNode = true;
								this.value = this.value.replace(/</g, "&lt;");
							}
						});
						var newNode = this.outerHTML;
						if (changedNode) {
							self.escapeAttrMap.set(oldNode, newNode);
						}
						$.each(this.childNodes, function() {
							self.escapeAttrValues(this);
						});
					});
					this.escapeAttrMap.forEach((value: string, key: string) => {
						let newVlaue = value.replace(/&amp;lt;/g,'&lt;');
						key = key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
						let regex = new RegExp(key, 'g');
						xmlStr = xmlStr.replace(regex, newVlaue);
					});
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.processPartialStm(outNode[0]);
					this.partialStm = this.replaceInsIdOnPaste(this.partialStm);
					return this.partialStm;
				}
			} else {
				if (localStorage.getItem('disabledEditor') != null && localStorage.getItem('disabledEditor') == "true") {
					var xmlStr: string = '<xml>' + data + '</xml>';
					var xmlDoc = $.parseXML(xmlStr);
					let outNode = $(xmlDoc).find('xml');
					this.checkForReusables(outNode[0].outerHTML);			
					if(this.hasResuables) {
						return outNode[0].textContent;
					} else {
						data = data.replace(/<p><br\/><\/p>/g, '<br>');
						data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
						data = data.replace(/<\/p>/g, "<br>");
						data = data.replace(/<br\/>/g, "<br>");
						data = data.replace(/<span.*?>/g, "");
						data = data.replace(/<\/span>/g, "");
						data = data.replace(/<font.*?>/g, "");
						data = data.replace(/<\/font>/g, "");
						return data;
					}
				}	
				else if (localStorage.getItem('disabledEditor') == null || localStorage.getItem('disabledEditor') == "false") {																						
					var xmlStr: string = '<xml>' + data + '</xml>'
					var parser = new DOMParser();
					var xmlDoc = parser.parseFromString(xmlStr, "text/xml");
					let copyArray = data.match(/<statement.*?>*<\/statement>/g);
					if (copyArray != null) {
						for(let i in copyArray) {
							let copyData = copyArray[i];
							let originalData = copyData;
							copyData = copyData.replace(/(?!<ph)\<p.*?>/g, '<para contenteditable="false" style="background-color:#d9d9d9;display:block;">').replace(/p>/g, "para>").replace(/<br\/>/g, "&nbsp;");	
							data = data.replace(originalData, copyData);
						}
					}
					var xrefEle = xmlDoc.getElementsByTagName("xref")[0];
					if (xrefEle !== undefined) {
						data = data.replace(/refinsid/gi, "ref_ins");
					}
					data = this.replaceInsIdOnPaste(data);
					if (xrefEle !== undefined) {		
						data = data.replace(/ref_ins/gi, "refinsid");		
					}
					data = data.replace(/<p><br\/><\/p>/g, '<br>');
					data = data.replace(/(?!<ph|<para)\<p.*?>/g, "");
					data = data.replace(/<\/p>/g, "<br>");
					return data;
				}
			}
		}
	}

	checkForReusables(data: string) {		 
		if (data.match(/<statement.*?>*<\/statement>/g)) {
			this.hasResuables = true;
		}
		else if(data.match(/<tags.*?>*<\/tags>/g)) {
			this.hasResuables = true;
		}
		else if(data.match(/<xref.*?>*<\/xref>/gi)) {
			this.hasResuables = true;
		}
		else if(data.match(/<fraction.*?>*<\/fraction>/g)) {
			this.hasResuables = true;
		}
		else {
			this.hasResuables = false;
		}
	}

	public gethasResuables(): boolean {
		return this.hasResuables;

	}
}
